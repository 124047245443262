
import { IBarcode, IBarcodeDefinitionType } from 'smartbarcode-web-core/src/utils/types/index'
import { Options, Vue } from 'vue-class-component'

import { BARCODE_TYPE_ICONS } from '@/utils/constants'

@Options({
  components: {},
  name: 'BarcodeTypesMixin',
})
export default class BarcodeTypesMixin extends Vue {
  barcodeIconComponents = BARCODE_TYPE_ICONS

  get project() {
    return this.$store.state.project.details
  }

  get userType(): string {
    return this.$store.state.profile?.user?.userType
  }

  // Filter valid barcode type by client/carrier
  get availableBarcodeTypes(): Record<string, IBarcodeDefinitionType> {
    const barcodeTypes: { [key: string]: IBarcodeDefinitionType } = this.project?.barcodeTypes || {}
    const availableBarcodeTypes = Object.keys(barcodeTypes)
      .filter((nodeType) => barcodeTypes[nodeType]?.userTypes.some((userType) => userType === this.userType))
      .sort((a, b) => {
        const orderA = barcodeTypes[a].order ?? 999
        const orderB = barcodeTypes[b].order ?? 999
        return orderA - orderB
      })
      .reduce(
        (obj, key) => ({
          ...obj,
          [key]: barcodeTypes[key],
        }),
        {}
      )
    return availableBarcodeTypes
  }

  currentBarcodeTypeInfo(barcode: IBarcode): IBarcodeDefinitionType | undefined {
    return this.getBarcodeTypeInfoByType(barcode?.barcodeType)
  }

  getBarcodeTypeInfoByType(type: string): IBarcodeDefinitionType | undefined {
    return this.project?.barcodeTypes?.[type]
  }

  barcodeButtonStyles = (barcodeTypeInfo: IBarcodeDefinitionType) => {
    return barcodeTypeInfo
      ? {
        color: `${barcodeTypeInfo.foregroundColor} !important`,
        background: `${barcodeTypeInfo.backgroundColor} !important`,
        'border-color': `${barcodeTypeInfo.backgroundColor} !important`,
      }
      : {}
  }

  barcodeIconStyles = (barcodeTypeInfo: IBarcodeDefinitionType) => {
    // Icon color always black or white
    return { color: !barcodeTypeInfo?.invertIconColor ? '#fff' : '#000' }
  }

  barcodeButtonTextStyles = (barcodeTypeInfo: IBarcodeDefinitionType) => {
    return { color: `${barcodeTypeInfo.foregroundColor} !important` }
  }
}
