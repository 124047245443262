import en from '@/i18n/en.json'
import ja from '@/i18n/ja.json'
import zhcn from '@/i18n/zh-cn.json'
import zhtw from '@/i18n/zh-tw.json'
import { createI18n } from 'vue-i18n'
const i18n = createI18n({
  locale: navigator.language.toLowerCase(),
  fallbackLocale: 'en',
  messages: {
    ja,
    en,
    'en-us': en,
    'zh-tw': zhtw,
    'zh-cn': zhcn,
    'zh-hant-tw': zhtw,
    'zh-hans-cn': zhcn,
  },
})

export default i18n
