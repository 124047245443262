<template>
  <SvgBase iconName="truck" width="42" height="27">
    <path
      d="M19.9729 21.3424L17.5762 18.9457L23.0544 13.3533L17.5762 7.76091L19.9729 5.36417L27.962 13.3533L19.9729 21.3424Z"
      fill="white"
    />
    <path
      d="M13.9239 26.7065H0V0H13.9239V26.7065ZM4.56522 22.1413H9.3587V4.56522H4.56522V22.1413Z"
      fill="white"
    />
    <path
      d="M42 26.7065H27.9619V0H41.8858V26.7065H42ZM32.5271 22.1413H37.3206V4.56522H32.5271V22.1413Z"
      fill="white"
    />
    <path d="M23.3967 11.0707H11.2988V15.6359H23.3967V11.0707Z" fill="white" />
  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: { SvgBase },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconTimeTable extends Vue {}
</script>
