<template>
  <div class="signature">
    <el-button type="primary" class="full-button eSign-btn" @click="dialogVisible = true">
      <div class="action-button">
        <IconEdit />
        {{ $t('Sign') }}
      </div>
    </el-button>
    <p v-show="error" class="error-msg">{{ $t('Please sign') }}</p>
    <el-dialog
      :lock-scroll="true"
      v-model="dialogVisible"
      width="90%"
      top="0"
      :destroy-on-close="false"
      :title="$t('Sign')"
      @close="onCloseDialog"
      @opened="initSignaturePad"
    >
      <div class="signature-pad--body">
        <canvas ref="canvas" width="300" height="240"></canvas>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="clearSignPad"> Undo </el-button>
          <el-button type="primary" @click="dialogVisible = false"> {{ $t('OK') }} </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script lang="ts">
import mitt from '@/utils/mitt'
import SignaturePad from 'signature_pad'
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

import IconEdit from '@/components/svg/IconEdit.vue'

@Options({
  components: {
    IconEdit,
  },
  inheritAttrs: false,
  emits: ['change'],
  name: 'ESignature',
})
export default class ESignature extends Vue {
  @Prop({ type: String }) readonly fieldName!: string
  @Prop({ type: String }) readonly error!: string
  @Prop({ type: [String] })
  readonly modelValue!: string

  dialogVisible = false
  dataUrl = ''
  signPad: SignaturePad | null = null

  initSignaturePad() {
    if (!this.signPad) {
      this.signPad = new SignaturePad(this.$refs.canvas)
      if (this.dataUrl) {
        this.signPad.fromDataURL(this.dataUrl, {
          width: 300,
          height: 240,
        })
      }
      this.signPad.on()
    }
  }

  clearSignPad() {
    if (this.signPad) {
      this.signPad.clear()
      this.dataUrl = ''
      mitt.emit('update:trackInfo', {
        [this.fieldName]: '',
      })
    }
  }

  onCloseDialog() {
    if (this.signPad && !this.signPad.isEmpty()) {
      this.dataUrl = this.signPad.toDataURL()
      this.$emit('change')
      this.dialogVisible = false
      this.$nextTick(() => {
        mitt.emit('update:trackInfo', {
          [this.fieldName]: this.dataUrl,
        })
      })
    }
  }

  mounted() {
    this.dialogVisible = false
    this.dataUrl = this.modelValue || ''
  }

  unmounted() {
    if (this.signPad) {
      this.signPad.off()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/mixins.scss';
.signature {
  text-align: center;
}

.eSign-btn {
  max-width: 240px;
  width: 100%;
}

canvas {
  touch-action: none;
  border: 1px solid $border-color;
}

.dialog-footer {
  display: flex;
  justify-content: space-between;
}
</style>
