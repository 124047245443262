
import OCRComponent from '@/components/OCRComponent.vue'
import IconBarcodeReader from '@/components/svg/IconBarcodeReader.vue'
import IconOCR from '@/components/svg/IconOCR.vue'
import IconSave from '@/components/svg/IconSave.vue'
import { EBCRType, EOCRType } from 'smartbarcode-web-core/src/utils/enums/index'
import { maska } from 'maska'
import { Options, Vue } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import InputReadersSuffix from './InputReadersSuffix.vue'
import { RepeatClick } from 'element-plus/lib/directives'
@Options({
  directives: { maska, RepeatClick },
  emits: ['update:modelValue', 'change'],
  components: {
    OCRComponent,
    IconOCR,
    IconBarcodeReader,
    IconSave,
    InputReadersSuffix,
  },
  name: 'InputNumber',
})
export default class InputNumber extends Vue {
  @Prop({ type: [Number, String] }) modelValue?: number
  @Prop({ type: Number }) readonly minValue?: number
  @Prop({ type: Number }) readonly maxValue?: number
  @Prop({ type: Number }) readonly precision?: number
  @Prop({ type: String }) readonly fieldName?: string = ''
  @Prop({ type: String }) readonly name?: string = ''
  @Prop({ type: String }) readonly placeholder?: string = ''
  @Prop({ type: Boolean }) readonly disabled?: boolean = false
  @Prop({ type: Boolean, default: true }) readonly isShowControlButton?: boolean
  @Prop({ default: EBCRType.UNKNOWN }) barcodeReaderType!: EBCRType
  @Prop({ default: EOCRType.UNKNOWN }) ocrReaderType!: EOCRType
  @Prop({ default: '' }) ocrImageAutoSaveField!: ''

  isShowDialog = false

  displayValue = ''

  created() {
    const initValue = this.modelValue || this.minValue
    if (initValue) {
      this.displayValue = initValue.toString()
      this.formatValue()
    }
  }

  get isDisableMinButton() {
    return this.minValue === this.modelValue
  }

  get isDisableMaxButton() {
    return this.maxValue === this.modelValue
  }

  get fieldInputClass() {
    return this.barcodeReaderType === EBCRType.UNKNOWN && this.ocrReaderType === EOCRType.UNKNOWN
      ? 'field-input'
      : 'field-input-reader'
  }

  numberToString(num: number) {
    return Number(num.toFixed(this.precisionNum)).toLocaleString()
  }

  stringToNumber(str: string): number {
    return Number(Number(str.replace(/,/g, '')).toFixed(this.precisionNum))
  }

  increaseVal() {
    const formattedVal = this.stringToNumber(this.displayValue || '0')
    this.displayValue = (formattedVal + 1).toString()
    this.formatValue()
  }

  decreaseVal() {
    const formattedVal = this.stringToNumber(this.displayValue || '0')
    this.displayValue = (formattedVal - 1).toString()
    this.formatValue()
  }

  onBlur(event: FocusEvent) {
    this.formatValue()
  }

  onInputReaderChanged() {
    this.formatValue()
  }

  formatValue() {
    if (!this.displayValue) {
      this.updateModelVal()
      return
    }
    const minVal = this.minValue || 0
    const maxVal = this.maxValue || 0
    let formattedVal = this.stringToNumber(this.displayValue)
    if (formattedVal < minVal) {
      formattedVal = minVal
    }
    if (maxVal && formattedVal > maxVal) {
      formattedVal = maxVal
    }

    this.displayValue = this.numberToString(formattedVal)
    this.updateModelVal(formattedVal)
  }

  get precisionNum() {
    return this.precision ?? 1
  }

  updateModelVal(val?: number) {
    this.$emit('update:modelValue', val)
  }

  @Watch('modelValue')
  onModelChanged() {
    // Update the value When modelValue change from the out side.
    this.displayValue = this.modelValue?.toString() || '0'
    this.formatValue()
  }
}
