<template>
  <div :class="isAnimationContainerType ? 'animation' : ''">
    <div
      :class="`interaction relative ${
        containerStatus === 0
          ? 'normal_smaller'
          : containerStatus === 2
          ? 'full'
          : containerStatus === 3
          ? 'full_bigger'
          : ''
      }`"
    >
      <div class="container" v-if="containerStatus === 1" @click="selectContainer()"></div>
      <div
        v-for="(barcode, index) in displayBarcodeList"
        :key="barcode?.label"
        @click="onSymbologiesChange(barcode?.label)"
      >
        <div
          :class="`text-center qrcode-icon qrcode-icon-${index} ${isHideElement(barcode.label) ? 'hide' : ''} ${
            isShowElement(barcode.label) ? 'show' : ''
          }`"
        >
          <component :is="barcode.component" class="w-6 h-6 self-center" />
        </div>
        <div
          :class="`self-center text-black qrcode-text qrcode-text-${index} ${
            isHideElement(barcode.label) ? 'hide' : ''
          } ${isShowElement(barcode.label) ? 'show' : ''}`"
        >
          {{ barcode?.label }}
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import IconScan1D from '@/components/svg/IconScan1D.vue'
import IconScan2D from '@/components/svg/IconScan2D.vue'
import IconScanDefaultMini from '@/components/svg/IconScanDefaultMini.vue'
import { EBCReaderCategory } from 'smartbarcode-web-core/src/utils/enums/index'
import { Options, Vue } from 'vue-class-component'
import { PropSync } from 'vue-property-decorator'
// Mixins
@Options({
  components: {
    IconScan1D,
    IconScan2D,
    IconScanDefaultMini,
  },
  emits: ['update:modelValue', 'update:onSymbologiesChange', 'onUpdate:symbologies', 'update:symbologies'],
  name: ' BarcodeSymbologiesSelection',
})
export default class BarcodeSymbologiesSelection extends Vue {
  @PropSync('symbologies', { type: String, default: EBCReaderCategory.DEFAULT }) _symbologies!: string
  selecting = false
  containerStatus = 1 // 0: normal_smaller - 1: normal - 2: full - 3: full_bigger
  get containerStatusData() {
    return {
      normalSmaller: 0,
      normal: 1,
      full: 2,
      fullBigger: 3,
    }
  }

  get isAnimationContainerType() {
    return this._symbologies === EBCReaderCategory.DEFAULT
  }

  isShowElement(value: string) {
    return (
      this._symbologies === value &&
      (this.containerStatus === this.containerStatusData.normal ||
        this.containerStatus === this.containerStatusData.normalSmaller)
    )
  }

  isHideElement(value: string) {
    return !(
      this.containerStatus === this.containerStatusData.full ||
      this.containerStatus === this.containerStatusData.fullBigger ||
      this._symbologies === value
    )
  }

  onSymbologiesChange(newVal: string) {
    if (this.containerStatus === 2) {
      this._symbologies = newVal
      if (this._symbologies !== newVal) {
        this.$emit('update:onSymbologiesChange', newVal)
      }
      setTimeout(() => {
        if (this.isAnimationContainerType) {
          this.containerStatus = 0
          setTimeout(() => (this.containerStatus = 1), 500)
        } else {
          this.containerStatus = 1
        }
      }, 0)
    }
  }

  selectContainer() {
    if (this.isAnimationContainerType) {
      this.containerStatus = 3
      setTimeout(() => (this.containerStatus = 2), 500)
    } else {
      this.containerStatus = 2
    }
  }

  get displayBarcodeList() {
    const displayList = []
    displayList.push({ label: EBCReaderCategory.DEFAULT, component: IconScanDefaultMini })
    displayList.push({ label: EBCReaderCategory.QR, component: IconScan2D })
    displayList.push({ label: EBCReaderCategory.ONE_DIMENSION, component: IconScan1D })
    return displayList
  }

  get component() {
    switch (this._symbologies) {
      case EBCReaderCategory.QR:
        return IconScan2D
      case EBCReaderCategory.ONE_DIMENSION:
        return IconScan1D
      default:
        return IconScanDefaultMini
    }
  }
}
</script>
<style lang="scss" scoped>
@import './src/assets/css/mixins.scss';
.animation {
  .interaction {
    transition: width 0.07s;
    transition-timing-function: cubic-bezier(0.15, 0.1, 0.1, 0.15);
    will-change: width;
    &.normal_smaller {
      transition: width 0.5s;
      width: 36px;
    }

    &.full_bigger {
      transition: width 0.5s;
      width: 212px;
    }

    &.full {
      transition: width 0.07s;
      width: 208px;
    }
  }
}
.interaction {
  transition: width 0s;
  background: #d9d9d9;
  border-radius: 10px;
  position: relative;
  width: 40px;
  height: 40px;
  .container {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    z-index: 100;
  }

  &.full {
    transition: width 0s;
    width: 208px;
  }
}

.qrcode-icon {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  transition: opacity 0.3s, left 0.25s;
  will-change: left, opacity;
  opacity: 1;

  &.qrcode-icon-0 {
    left: 8px;
  }
  &.qrcode-icon-1 {
    left: 82px;
  }
  &.qrcode-icon-2 {
    left: 142px;
  }
  &.show {
    left: 8px;
  }
  &.hide {
    opacity: 0;
  }
}
.qrcode-text {
  position: absolute;
  top: 50%;
  transform: translate(0, -56%);
  transition: opacity 0.3s, left 0.5s, font-size 0.3s, color 0.3s;
  will-change: left, font-size, color, opacity;
  opacity: 1;
  font-weight: 500;
  font-size: 9px;
  line-height: 13px;
  text-align: left;
  color: #000000;
  width: 42px;

  &.qrcode-text-0 {
    left: 40px;
  }
  &.qrcode-text-1 {
    left: 114px;
  }
  &.qrcode-text-2 {
    left: 174px;
  }
  &.hide {
    opacity: 0;
  }
  &.show {
    left: 45px;
    color: #fff;
    font-size: 12px;
    line-height: 17px;
  }
}
</style>
