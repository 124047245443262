
import { mixins, Options } from 'vue-class-component'
import { promptVideoPermission } from '@/utils/helpers'
import BarcodeSymbologiesSelection from '@/components/BarcodeSymbologiesSelection.vue'
import 'cropperjs/dist/cropper.css'
import { BrowserMultiFormatReader, Result } from '@zxing/library'
import { Prop } from 'vue-property-decorator'
import { BARCODE_SCAN } from '@/utils/constants'
import errorHandler from '@/utils/errorHandler'
import { TError } from 'smartbarcode-web-core/src/utils/types/index'
import BarcodeReaderMixin from '@/components/mixins/BarcodeReaderMixin.vue'
import { EBCRType } from 'smartbarcode-web-core/src/utils/enums/index'
import { BarcodeCapture, Barcode } from 'scandit-web-datacapture-barcode'

// Mixins
@Options({
  components: {
    BarcodeSymbologiesSelection,
  },
  emits: ['update:modelValue', 'update:confirm'],
  name: 'ScanComponent',
})
export default class OCRComponent extends mixins(BarcodeReaderMixin) {
  @Prop({ type: String }) readonly fieldName!: string
  @Prop({ default: EBCRType.DEFAULT }) customBarcodECameraReaderType!: EBCRType // This will override customBarcodECameraReaderType from mixins
  codeReader: BrowserMultiFormatReader | null = new BrowserMultiFormatReader(
    undefined,
    BARCODE_SCAN.timeBetweenScansMillis as number
  )

  _barcodeDataCapture: BarcodeCapture | undefined
  loading = true
  video: HTMLVideoElement | null = null
  pictureWidth = 320
  pictureHeight = 320
  isCaptured = false
  cameraId = ''
  detectedText = ''

  confirm() {
    this.vidOff()
    this.$emit('update:confirm', this.detectedText.trim())
  }

  defaultScanReader(result: Result, codeReader: BrowserMultiFormatReader) {
    this.isReadyToScan = true
    this.codeReader = codeReader
    if (!result) {
      return
    }
    this.handleScanResult(result.getText() || '')
  }

  scanditDataResult(symbology: Barcode) {
    if (!symbology) return
    this.handleScanResult((symbology?.data as string) || '')
  }

  handleScanResult(plainText: string) {
    this.detectedText = plainText
  }

  async mounted() {
    if (this.isCustomVideoReader) {
      await promptVideoPermission()
    }

    await this.initBarcodeReader()
  }

  async beforeUnmount() {
    this.vidOff()
  }

  get isCustomVideoReader() {
    return !(this.customBarcodECameraReaderType === EBCRType.SCANDIT)
  }

  async initBarcodeReader() {
    switch (this.customBarcodECameraReaderType) {
      case EBCRType.SCANDIT:
        try {
          await this.initScanditReader()
        } catch (error) {
          errorHandler(error as TError)
        }
        break
      default:
        try {
          await this.initZxingReader()
        } catch (error) {
          errorHandler(error as TError)
        }
        break
    }
  }

  stopBothVideoAndAudio(stream: MediaStream) {
    stream.getTracks().forEach((track) => {
      if (track.readyState === 'live') {
        track.enabled = false
        track.stop()
      }
    })
  }

  async vidOff() {
    if (this.video) {
      if (this.video.srcObject) {
        this.stopBothVideoAndAudio(this.video.srcObject as MediaStream)
      }
    }
    if (this.codeReader) {
      this.codeReader.reset()
    }
  }

  get isScanditReader() {
    return this.customBarcodECameraReaderType === EBCRType.SCANDIT
  }

  async setupVideo() {
    if (!this.video) {
      this.video = document.getElementById('video') as HTMLVideoElement
    }
    const videoSettings = {
      video: {
        facingMode: 'environment',
        width: { ideal: 1000 },
        height: { ideal: 1000 },
        frameRate: {
          ideal: 30,
          max: 60,
        },
        aspectRatio: 1,
      },
    }

    await navigator.mediaDevices
      .getUserMedia(videoSettings)
      .then((stream) => {
        // Setup the video stream
        if (this.video) {
          this.video.srcObject = stream
          const streamSetting = stream.getVideoTracks()[0].getSettings()
          // actual width & height of the camera video
          this.pictureWidth = streamSetting.width || this.pictureWidth
          this.pictureHeight = streamSetting.height || this.pictureHeight
          this.loading = false
        }
      })
      .catch((e) => {
        errorHandler(e)
      })
  }
}
