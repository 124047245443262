<template>
  <div v-loading.fullscreen="loading" v-show="!loading" class="scan-view">
    <div class="message-wrapper top">
      <div>{{ $t(guideText) }}</div>
    </div>
    <div v-show="!isCaptured" id="scan-area" class="media-container relative">
      <video id="video" autoplay playsinline v-show="!isCaptured"></video>
      <canvas v-show="false"></canvas>
    </div>
    <div class="image-container relative">
      <img class="w-full" v-show="isCaptured" :src="img" id="imgTag" />
      <div v-show="isCaptured" class="message-wrapper bottom bottom-0">
        <div class="bg-red-500 opacity-80">{{ $t('pinch_guide') }}</div>
      </div>
    </div>
    <div>
      <div :class="['dectect-input']">
        <el-input class="text-center text-lg" v-model="detectedText">
          <template #prepend>
            <i v-if="isRecognizing" class="el-icon-loading is-loading"></i>
            <i v-else class="el-icon-view"></i>
          </template>
        </el-input>
      </div>
      <div class="step-button">
        <el-button :disabled="!isCaptured" circle type="primary" class="submit-button" @click="reTakePicture">
          <IconArrowBack />
        </el-button>
        <el-button v-if="!isCaptured" circle type="primary" class="submit-button" @click="snapshot">
          <IconCamera class="w-8 h-8" />
        </el-button>
        <el-button :disabled="!detectedText" v-else circle type="primary" class="submit-button" @click="confirm">
          {{ $t('OK') }}
        </el-button>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import BarcodeLabel from '@/components/BarcodeLabel.vue'
import IconArrowBack from '@/components/svg/IconArrowBack.vue'
import IconCamera from '@/components/svg/IconCamera.vue'
import errorHandler from '@/utils/errorHandler'
import { promptVideoPermission } from '@/utils/helpers'
import mitt from '@/utils/mitt'
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
import { TError } from 'smartbarcode-web-core/src/utils/types/index'
import Cropper from 'cropperjs'
import 'cropperjs/dist/cropper.css'
import { createWorker, Worker } from 'tesseract.js'
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
@Options({
  components: {
    BarcodeLabel,
    IconCamera,
    IconArrowBack,
  },
  emits: ['update:modelValue', 'update:confirm', 'update:img'],
  name: 'OCRComponent',
})
export default class OCRComponent extends Vue {
  @Prop({ type: String }) readonly fieldName!: string

  isRecognizing = false
  isRecognized = true
  loading = true
  video: HTMLVideoElement | null = null
  pictureWidth = 320
  pictureHeight = 320
  isCaptured = false
  croppedImg = {} as HTMLCanvasElement
  img = ''
  cropper = {} as Cropper
  detectedText = ''
  worker = {} as Worker

  onEmitCapturedImage() {
    mitt.emit('update:imageOCR', { [this.fieldName]: this.img })
  }

  saveBase64AsFile(fileName: string, hrefURL: string) {
    const link = document.createElement('a')
    link.download = fileName
    link.href = hrefURL
    link.click()
    link.remove()
  }

  confirm() {
    this.vidOff()
    this.onEmitCapturedImage()
    this.$emit('update:confirm', this.detectedText.trim())
  }

  async reTakePicture() {
    this.isCaptured = false
    this.$emit('update:img', false)
    this.detectedText = ''
    this.img = ''
    this.cropper.destroy()
    const container = Array.from(document.getElementsByClassName('image-container') as HTMLCollectionOf<HTMLElement>)
    container[0].style.display = 'none'
    await this.setupVideo()
  }

  async getCropData() {
    try {
      this.croppedImg = this.cropper.getCroppedCanvas({
        imageSmoothingEnabled: true,
        imageSmoothingQuality: 'high',
      })
      if (!this.croppedImg) return
      if (this.isRecognized) return
      if (!this.croppedImg) return

      await this.onRecognize()
    } catch (error) {
      console.error(error)
    }
  }

  async onRecognize() {
    this.isRecognizing = true
    try {
      const {
        data: { text },
      } = await this.worker.recognize(this.croppedImg)
      this.detectedText = text
    } catch (e) {
      errorHandler(e as TError)
    } finally {
      this.isRecognizing = false
    }
  }

  async beforeCreate() {
    try {
    this.worker = await createWorker('eng')
    } catch (e) {
      errorHandler(e as TError)
    }
  }

  async beforeUnmount() {
    if (this.worker) {
      await this.worker.terminate()
    }
    if (!isEmpty(this.cropper)) {
      // eslint-disable-next-line no-unused-expressions
      this.cropper?.clear()
      // eslint-disable-next-line no-unused-expressions
      this.cropper?.reset()
      // eslint-disable-next-line no-unused-expressions
      this.cropper?.destroy()
    }
    if (this.img) {
      URL.revokeObjectURL(this.img)
    }

    this.vidOff()
  }

  async mounted() {
    await promptVideoPermission()
    await this.setupVideo()
  }

  get guideText() {
    return this.isCaptured
      ? isEmpty(this.detectedText)
        ? 'please_select_ocr_text'
        : 'please_confirm_ocr_text'
      : 'please_take_ocr_area'
  }

  startCrop() {
    const image = document.getElementById('imgTag') as HTMLImageElement
    if (!image) return
    this.cropper = new Cropper(image, {
      center: false,
      highlight: false,
      viewMode: 3,
      autoCrop: false,
      background: false,
      autoCropArea: 1,
      cropend: async () => {
        this.isRecognized = false
        await this.getCropData()
      },
    })
  }

  stopBothVideoAndAudio(stream: MediaStream) {
    stream.getTracks().forEach((track) => {
      if (track.readyState === 'live') {
        track.enabled = false
        track.stop()
      }
    })
  }

  async vidOff() {
    if (this.video) {
      if (this.video.srcObject) {
        this.stopBothVideoAndAudio(this.video.srcObject as MediaStream)
      }
    }
  }

  snapshot() {
    this.isCaptured = true
    this.$emit('update:img', true)
    const canvas = document.querySelector('canvas')
    const container = Array.from(document.getElementsByClassName('image-container') as HTMLCollectionOf<HTMLElement>)
    if (!canvas) return
    canvas.width = this.pictureWidth
    canvas.height = this.pictureHeight
    const context = canvas?.getContext('2d')
    if (!context) return
    context.fillRect(0, 0, this.pictureWidth, this.pictureHeight)
    context.drawImage(this.video as CanvasImageSource, 0, 0, this.pictureWidth, this.pictureHeight)
    canvas.style.display = 'none'
    container[0].style.display = 'block'
    this.img = canvas.toDataURL()
    this.vidOff()
    setTimeout(() => {
      this.startCrop()
    }, 50)
  }

  async setupVideo() {
    if (!this.video) {
      this.video = document.getElementById('video') as HTMLVideoElement
    }
    const videoSettings = {
      video: {
        facingMode: 'environment',
        width: { ideal: 1000 },
        height: { ideal: 1000 },
        frameRate: {
          ideal: 30,
          max: 60,
        },
        aspectRatio: 1,
      },
    }

    await navigator.mediaDevices
      .getUserMedia(videoSettings)
      .then((stream) => {
        // Setup the video stream
        if (this.video) {
          this.video.srcObject = stream
          const streamSetting = stream.getVideoTracks()[0].getSettings()
          // actual width & height of the camera video
          this.pictureWidth = streamSetting.width || this.pictureWidth
          this.pictureHeight = streamSetting.height || this.pictureHeight
          this.loading = false
        }
      })
      .catch((e) => {
        errorHandler(e)
      })
  }
}
</script>
<style lang="scss" scoped>
@import './src/assets/css/mixins.scss';
.margin-ocr {
  margin-top: 10px;
}
.margin-top-reader {
  margin-top: 16px;
}

.step-button {
  display: flex;
  padding-left: 25%;
  padding-right: 25%;
}
.media-container {
  width: 100%;
  position: relative;
  height: 40vh;
  video {
    top: 0;
    left: 0;
    width: 100%;
    object-fit: cover;
    height: 40vh;
  }
}
.dectect-input {
  padding: 0 30px 0 30px;
  text-align-last: center;
}
.scan-view {
  text-align: center;
}

.message-wrapper {
  margin-top: 10px;
  word-break: break-word;
  position: absolute;
  z-index: 100;
  color: $pure-white;
  text-align: center;
  padding: 0 2rem;
  width: 100%;
  box-sizing: border-box;
  font-size: 0.85rem;
  &.top {
    > div {
      padding: 8px 12px;
      background: rgb(0, 0, 0, 0.7);
      border-radius: 5px;
    }
  }
  &.bottom {
    > div {
      margin-bottom: 10px;
      padding: 8px 12px;
      background: red;
      border-radius: 5px;
    }
  }
}

.image-container {
  max-width: 100%;
  height: 40vh;
  display: none;
  object-fit: cover;
}
.img {
  max-width: 100%;
  height: 40vh;
}
</style>
