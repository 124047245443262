<template>
  <div class="cursor-pointer flex flex-row justify-evenly digit-count-block" @click="redirectToUserTrackingCount">
    <span>{{ $t('Tracking Times') }}</span>
    <div>
      <IconTrackingCount />
    </div>
  </div>
</template>

<script lang="ts">
import IconTrackingCount from '@/components/svg/IconTrackingCount.vue'
import { Options, Vue } from 'vue-class-component'
@Options({
  components: { IconTrackingCount },
  name: 'DigitCountButton',
})
export default class DigitCountButton extends Vue {
  redirectToUserTrackingCount() {
    this.$router.push({ name: 'userTrackingHistory' })
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/mixins.scss';

.digit-count-block {
  background-color: #d8d8d8;
  align-items: center;
  min-height: 60px;
  border: #231815;
  border-radius: 10px;
}
</style>
