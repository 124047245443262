<template>
  <div class="display-in-center landing-view-layout view" v-loading.fullscreen="loading" id="Home_Loading">
    <img alt="SmartBarcode logo" :src="image" class="app-logo" />
    <CompanyName id="Home_CompanyName" />
    <div v-if="isAuth && projectDetail">
      <el-button id="Home_OnClickGenerate" type="primary" class="full-button" @click="onClickGenerate">
        <div class="action-button">
          <IconQRcode />
          {{ $t('Generate new barcode') }}
        </div>
      </el-button>
      <KeyenceScanButton v-if="isKeyenceEnterpriseBrowser" v-model:loading="loading" />
      <KeyenceScannerConfig v-if="isKeyenceEnterpriseBrowser" :scannerConfigName="'SingleScan'" />
      <router-link v-else :to="{ name: 'scan' }">
        <el-button id="Home_OnClickBarcodeScan" type="primary" class="full-button">
          <div class="action-button">
            <IconScanQR />
            {{ $t('Scan barcode') }}
          </div>
        </el-button>
      </router-link>
      <div class="mt-11"></div>
      <BarcodeSearchButton v-if="showBarcodeSearchPage" class="mt-3" />
      <DigitCountButton v-if="!isClientUser && showTrackingCountPage" class="mt-3" />
    </div>

    <div v-else>
      <div id="Home_LoginForm" class="login-form">
        <label>{{ $t('Access Code') }}</label>
        <div id="Home_AccessCode" class="access-code">
          <el-input
            id="Home_FirstCode"
            v-model="firstCode"
            class="access-code-input"
            v-maska="'####'"
            type="tel"
            @input="onCodeInput"
          />
          -
          <el-input
            id="Home_LastCode"
            v-model="lastCode"
            class="access-code-input"
            v-maska="'####'"
            type="tel"
            ref="code"
            @keyup.enter="start"
          />
        </div>

        <div class="password-input field-input" v-if="isRequirePassword">
          <label>{{ $t('account.password') }}</label>
          <el-input id="Home_Password" v-model="password" type="password" />
        </div>

        <div class="password-input field-input" v-if="isRequireOTP">
          <label>{{ $t('account.confirm_code') }}</label>
          <el-input :disabled="isDisableCodeInput" id="Home_OTP" v-model="otpCode" />
        </div>

        <p v-if="error" class="error-msg">{{ error }}</p>
        <el-button id="Home_Start" type="primary" @click="start" circle class="go-button is-larger">
          {{ $t('Authenticate') }}
        </el-button>
      </div>
      <div class="app-links">
        <router-link
          id="Home_Register"
          v-if="isAllowUserSelfRegister"
          :to="{ name: 'register', params: { projectId: projectParam } }"
        >
          Register
        </router-link>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import BarcodeSearchButton from '@/components/common/BarcodeSearchButton.vue'
import DigitCountButton from '@/components/common/DigitCountButton.vue'
import CompanyName from '@/components/CompanyName.vue'
import KeyenceScanButton from '@/components/keyenceHT/ScanButton.vue'
import KeyenceScannerConfig from '@/components/keyenceHT/ScannerConfig.vue'
import IconLogout from '@/components/svg/IconLogout.vue'
import IconQRcode from '@/components/svg/IconQRcode.vue'
import IconScanQR from '@/components/svg/IconScanQR.vue'
import { CLEAR_BARCODE, FETCH_PROFILE } from '@/store/actions'
import { generateBarcode, login } from '@/utils/api'
import errorHandler from '@/utils/errorHandler'
import { openMessage } from '@/utils/helpers'
import { isKeyenceEnterpriseBrowser } from '@/utils/keyenceHT/keyenceModules'
import { maska } from 'maska'
import { LoginPayload, TError } from 'smartbarcode-web-core/src/utils/types/index'
import { Options, Vue } from 'vue-class-component'

@Options({
  components: {
    IconQRcode,
    IconScanQR,
    IconLogout,
    CompanyName,
    DigitCountButton,
    BarcodeSearchButton,
    KeyenceScanButton,
    KeyenceScannerConfig,
  },
  directives: { maska },
  name: 'Home',
})
export default class Home extends Vue {
  loading = false
  firstCode = ''
  lastCode = ''
  password = ''
  otpCode = ''
  isDisableCodeInput = true
  error = ''

  created() {
    this.$store.dispatch(CLEAR_BARCODE)
  }

  resetField() {
    this.loading = false
    this.firstCode = ''
    this.lastCode = ''
    this.password = ''
    this.otpCode = ''
    this.isDisableCodeInput = true
    this.error = ''
  }

  get image() {
    return this.$store.getters.customLogoImageUrl || require('@/assets/logo-smartBarcode.png')
  }

  get isAuth(): boolean {
    return this.$store.state.profile.user !== null
  }

  get projectParam() {
    return this.$store.getters.projectParam
  }

  get projectDetail() {
    return this.$store.state.project.details
  }

  get showBarcodeSearchPage() {
    return this.projectDetail.showBarcodeSearchPage
  }

  get showTrackingCountPage() {
    return this.projectDetail.showTrackingCountPage
  }

  get isRequirePassword(): boolean {
    return this.projectDetail?.requirePassword
  }

  get isRequireOTP(): boolean {
    return this.projectDetail?.requireOneTimePasscode
  }

  get isAllowUserSelfRegister(): boolean {
    return this.projectDetail?.allowUserSelfRegister
  }

  get loginPayload(): LoginPayload {
    return {
      projectId: this.projectDetail?.id,
      accessCode: `${this.firstCode}${this.lastCode}`,
      password: this.password,
    }
  }

  get nextRouteName() {
    return this.$route?.query?.name ?? ''
  }

  get nextRouteParams() {
    return this.$route.query?.id ?? ''
  }

  get isClientUser(): boolean {
    return this.$store.getters.isClientUser
  }

  get isKeyenceEnterpriseBrowser() {
    return isKeyenceEnterpriseBrowser()
  }

  saveLoginData() {
    this.$store.dispatch(FETCH_PROFILE)
    if (this.nextRouteName && this.nextRouteParams) {
      this.$router.push({
        name: this.nextRouteName,
        params: {
          barcodeId: this.nextRouteParams,
        },
      })
    }
    window.scroll(0, 0)
  }

  async start() {
    try {
      this.loading = true

      const loginPayload = this.loginPayload
      if (!this.isDisableCodeInput) {
        loginPayload.oneTimePasscode = this.otpCode
      }
      await login(this.loginPayload)
      this.resetField()
      this.saveLoginData()
    } catch (error) {
      if (error) {
        if (typeof error === 'object' && 'oneTimePasscode' in error && typeof error.oneTimePasscode === 'string') {
          if (error?.oneTimePasscode?.includes('1000')) {
            this.isDisableCodeInput = false
            this.error = this.$t('account.confirm_code_already_sent')
            return
          }
          if (error?.oneTimePasscode?.includes('2002')) {
            this.isDisableCodeInput = false
            this.error = this.$t('account.incorrect_otp_passcode')
            return
          }
        }

        switch (error) {
          case '3057':
            openMessage(this.$t('account.confirm_code_sent'), 'success')
            this.isDisableCodeInput = false
            return
          default:
            break
        }
      }

      this.error = this.$t('invalid password')
    } finally {
      this.loading = false
    }
  }

  onCodeInput(value: string) {
    if (value.length === 4) {
      this.$refs.code.focus()
    }
  }

  async onClickGenerate() {
    try {
      this.loading = true
      const result = await generateBarcode(this.$store.getters.projectCode, `${this.$store.getters.projectVersion}`)
      this.$router.push({
        name: 'detail',
        params: { project: this.projectParam, barcodeId: result.id },
      })
    } catch (error) {
      errorHandler(error as TError)
    } finally {
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/mixins.scss';

.login-form {
  label {
    margin-bottom: 5px;
  }

  .access-code {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .access-code-input {
      width: 45%;
      display: inline-block;
    }
  }

  .password-input {
    width: 100%;
    margin-top: 15px;
    display: inline-block;
  }

  .go-button {
    margin: 40px auto 0;
  }
}

.logout-button {
  border: 0;
}

.app-links {
  margin: 60px 0px 30px;

  a:link,
  a:hover,
  a:active {
    text-decoration: none;
    color: #778899;
  }

  a:visited {
    text-decoration: none;
    color: #1155dd;
  }
}
</style>
