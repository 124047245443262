<template>
  <SvgBase iconName="packages" width="33" height="29">
    <path
      d="M25.4811 13.101V0H7.51889V13.101H0V29H33V13.101H25.4811ZM14.9395 25.9083H3.02267V16.1927H6.89924V19.4544H11.0554V16.1927H14.932V25.9083H14.9395ZM15.0453 13.101H10.5491V3.09168H14.4257V6.35341H18.5819V3.09168H22.4584V13.101H15.0453ZM29.9773 25.9083H18.068V16.1927H21.9446V19.4544H26.1008V16.1927H29.9773V25.9083Z"
      fill="white"
    />
  </SvgBase>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: { SvgBase },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconPackages extends Vue {}
</script>
