
import IconTrackingCount from '@/components/svg/IconTrackingCount.vue'
import { Options, Vue } from 'vue-class-component'
@Options({
  components: { IconTrackingCount },
  name: 'DigitCountButton',
})
export default class DigitCountButton extends Vue {
  redirectToUserTrackingCount() {
    this.$router.push({ name: 'userTrackingHistory' })
  }
}
