<template>
  <SvgBase iconName="Activate" width="24" height="24" fill="none">
    <path
      d="M12 2L2 7.82251V22H8.08253V13.1306H15.9231V22H22V7.82251L12 2Z"
      :stroke="color"
      stroke-width="3"
    />
  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconHome extends Vue {}
</script>
