<template>
  <SvgBase iconName="ship" width="30" height="25">
    <path
      d="M26.5126 10.5859V2.59694H22.1056V0H19.2761V2.59694H11.4949V10.593H0L3.52983 20.7755C4.40698 23.3088 6.79793 25.0071 9.47182 25.0071H28.2386L30 10.593H26.5197L26.5126 10.5859ZM14.3245 5.42032H23.6831V10.5859H22.0278V8.2154H19.6722V10.5859H18.2575V8.2154H15.9019V10.5859H14.3245V5.42032ZM25.7345 22.1695H9.47182C8.00047 22.1695 6.68474 21.2355 6.20373 19.8415L3.97548 13.4164H26.8026L25.7345 22.1695Z"
      fill="white"
    />
  </SvgBase>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: { SvgBase },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconShip extends Vue {}
</script>
