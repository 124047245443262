<template>
  <el-dialog
    center
    width="90%"
    top="0"
    :lock-scroll="true"
    :model-value="visible"
    :show-close="!config.confirmation"
    :close-on-click-modal="false"
    :title="config.title"
    @closed="closeDialog"
  >
    <div v-html="message" class="dialogMessage"></div>
    <template #footer v-if="config.confirmation">
      <div class="buttons">
        <span v-if="!isAuth && !isExceptionPage" class="dialog-footer">
          <el-button id="AppDialog_closeDialog" type="primary" @click="onMoveToLoginPage">
            {{ $t('login') }}
          </el-button>
        </span>
        <span class="dialog-footer">
          <el-button id="AppDialog_closeDialog" type="primary" @click="closeDialog">
            {{ $t('OK') }}
          </el-button>
        </span>
      </div>
    </template>
  </el-dialog>
</template>

<script lang="ts">
import { CLOSE_DIALOG } from '@/store/actions'
import { Options, Vue } from 'vue-class-component'

@Options({
  components: {},
  name: 'AppDialog',
})
export default class AppDialog extends Vue {
  get isAuth() {
    return this.$store.getters.isAuth
  }

  get visible() {
    return this.$store.state.dialogVisible
  }

  get config() {
    return this.$store.state.dialogConfig
  }

  get barcodeId() {
    return this.$route.params.barcodeId || ''
  }

  get routeName() {
    return this.$route.name || ''
  }

  /** pages that don't need to display login button for display dialog */
  get exceptionPages() {
    return ['custom-html', 'home']
  }

  get isExceptionPage(): boolean {
    return this.exceptionPages.includes(this.$route.name)
  }

  onMoveToLoginPage() {
    this.closeDialog()
    const page =
      !this.barcodeId || !this.routeName
        ? { name: 'home' }
        : {
            name: 'home',
            query: {
              name: this.routeName,
              id: this.barcodeId,
            },
          }

    this.$router.push(page)
  }

  get message() {
    return this.config.needtranslation
      ? this.$t(this.config.message, { company: this.$store.state.project.enterprise })
      : this.config.message
  }

  closeDialog() {
    this.$store.dispatch(CLOSE_DIALOG)
  }
}
</script>
<style scoped>
.buttons {
  display: flex;
  justify-content: space-evenly;
}
</style>
