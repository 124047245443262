<template>
  <SvgBase iconName="forklift" width="29" height="28">
    <path
      d="M28.9079 22.0271H28.9513V11.2179H26.6216V0.046875H15.0093L7 12.9326V22.7507H7.07235C7.07235 22.7507 7.07235 22.8013 7.07235 22.8302C7.07235 25.5651 9.30077 27.7935 12.0356 27.7935C14.3002 27.7935 16.2031 26.2742 16.8036 24.1977H19.2563C19.8496 26.2669 21.7596 27.7935 24.0242 27.7935C26.7591 27.7935 28.9875 25.5651 28.9875 22.8302C28.9875 22.5553 28.9586 22.2876 28.9152 22.0271H28.9079ZM16.6155 2.94092H23.7276V11.2179H11.4713L16.6155 2.94092ZM12.0356 24.8995C10.8925 24.8995 9.9664 23.9734 9.9664 22.8302C9.9664 21.6871 10.8925 20.761 12.0356 20.761C13.1788 20.761 14.1049 21.6871 14.1049 22.8302C14.1049 23.9734 13.1788 24.8995 12.0356 24.8995ZM19.2997 21.3036H16.7529C16.109 19.314 14.2351 17.867 12.0356 17.867C11.2687 17.867 10.5452 18.0478 9.89405 18.3589V14.1119H26.0573V18.3083C25.4351 18.0261 24.7477 17.867 24.017 17.867C21.8175 17.867 19.9436 19.314 19.2997 21.3036ZM24.017 24.8995C22.8738 24.8995 21.9477 23.9734 21.9477 22.8302C21.9477 21.6871 22.8738 20.761 24.017 20.761C25.1601 20.761 26.0862 21.6871 26.0862 22.8302C26.0862 23.9734 25.1601 24.8995 24.017 24.8995Z"
      fill="white"
    />
    <path d="M4.57983 23.1524H0V26.0464H7.47387V0H4.57983V23.1524Z" fill="white" />
  </SvgBase>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: { SvgBase },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconForkLift extends Vue {}
</script>
