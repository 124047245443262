
import CustomTakePhotoCamera from '@/components/dialog/CustomTakePhotoCamera.vue'
import IconFile from '@/components/svg/IconFile.vue'
import IconImage from '@/components/svg/IconImage.vue'
import IconScannedBarcode from '@/components/svg/IconScannedBarcode.vue'
import IconTakePhoto from '@/components/svg/IconTakePhoto.vue'
import { baseUrl, customRequest, removeImage } from '@/utils/api'
import { IMAGE_EXTENSION, MAX_IMAGE_SIZE } from '@/utils/constants'
import errorHandler from '@/utils/errorHandler'
import { getFilePathFromURL, getMobileOS, isFirefoxOrOperaAgent, makeid, resizeImage } from '@/utils/helpers'
import mitt from '@/utils/mitt'
import { AxiosRequestConfig } from 'axios'
import { ElFile, ElUploadRequestOptions, UploadFile } from 'element-plus/lib/el-upload/src/upload.type'
import { EFileKind } from 'smartbarcode-web-core/src/utils/enums/index'
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
import { IBarcodeSavedImageStatus, IFile, TError } from 'smartbarcode-web-core/src/utils/types/index'
import { Options, Vue } from 'vue-class-component'
import { Prop, Ref, Watch } from 'vue-property-decorator'

@Options({
  inheritAttrs: false,
  components: {
    IconImage,
    IconFile,
    IconTakePhoto,
    IconScannedBarcode,
    CustomTakePhotoCamera,
  },
  emits: ['uploaded'],
  name: 'InputImageUpload',
})
export default class InputImageUpload extends Vue {
  @Ref() readonly inputUpload!: unknown
  @Ref() readonly triggerFileDialogBtn!: unknown
  @Prop({ type: String }) readonly fieldName!: string
  @Prop({ type: String }) readonly label!: string
  @Prop({ type: String }) readonly fileKind!: string
  @Prop({ type: String }) readonly overlayImage?: string
  @Prop({ type: Boolean }) readonly isShowCounter!: boolean
  @Prop({ type: [String, Number, Array, Object, Date] }) readonly modelValue?: unknown

  imageUrls = new Set()

  fileList = [] as UploadFile[]
  showDialogAlert = false
  isTakePhotoDialog = false

  get isSupportNativeImageOptions() {
    return getMobileOS() === 'iOS' || isFirefoxOrOperaAgent()
  }

  get imageOption() {
    return this.isSupportNativeImageOptions ? 'image/*' : 'image/*;capture=camera'
  }

  get getImageLabel() {
    return this.$t(this.isImageFileKind ? 'Pictures' : 'file_urls')
  }

  onCaptureImageUpdated(blob: Blob) {
    const uploadFile = new File([blob], `${makeid(16)}.png`, { type: 'image/png' })
    this.$refs.inputUpload.handleStart(uploadFile)
    this.$refs.inputUpload.submit()
  }

  onUploadButtonClicked() {
    if (this.isPhotoOnlyKind) {
      this.isTakePhotoDialog = true
      return
    }

    this.$refs.triggerFileDialogBtn.$el.click()
  }

  get component() {
    return this.isImageFileKind ? IconImage : IconFile
  }

  get isImageFileKind() {
    return [EFileKind.IMAGE, EFileKind.BARCODE_SCAN_FRAME, EFileKind.PHOTO_ONLY].find((key) => this.fileKind === key)
  }

  get isScannedBarcodeFrame() {
    return this.fileKind === EFileKind.BARCODE_SCAN_FRAME
  }

  get isScannedBCAvail() {
    return !isEmpty(this.fileList)
  }

  get fileCount() {
    return Array.from(this.imageUrls).length || 0
  }

  get barcodeId() {
    return this.$store.state.barcode.barcode?.id
  }

  get actionUrl() {
    return `${baseUrl}/barcode/file`
  }

  get scannedBCFile() {
    return this.$store.getters.scannedBarcodeBlob
      ? new File([this.$store.getters.scannedBarcodeBlob], `${this.barcodeId}.png`, { type: 'image/png' })
      : null
  }

  get scannedBarcodeStatus(): Record<string, IBarcodeSavedImageStatus> {
    return this.$store.state.barcode.scannedBarcodeStatus || {}
  }

  @Watch('$store.state.barcode.scannedBarcodeStatus')
  uploadBCImage() {
    if (!this.scannedBarcodeStatus?.[this.fieldName]) return

    if (this.scannedBarcodeStatus?.[this.fieldName]?.isReady && this.isScannedBCAvail && !this.fileList[0].response) {
      this.$refs.inputUpload.handleStart(this.scannedBCFile)
      this.$refs.inputUpload.submit()
    } else {
      this.$emit('uploaded', { url: 'none' })
    }
  }

  onDialogClose() {
    this.showDialogAlert = false
  }

  fileIsImage(file: UploadFile) {
    return IMAGE_EXTENSION.includes(file?.name?.split('.')[file?.name?.split('.').length - 1] || '')
  }

  async preupload(rawFile: ElFile) {
    // File kind is scan frame so we need to re-assign uuid for uploading after click submit button instead
    if (this.isScannedBCAvail && this.fileKind === EFileKind.BARCODE_SCAN_FRAME) {
      this.fileList[0].uid = rawFile.uid
    }

    // If not image return original file
    if (!this.isImageFileKind) return rawFile
    try {
      const resizedBlob = (await resizeImage({ maxSize: MAX_IMAGE_SIZE, file: rawFile })) as Blob
      return resizedBlob || rawFile
    } catch (error) {
      return rawFile
    }
  }

  beforeRemove(file: IFile) {
    if (file) {
      this.imageUrls.delete(getFilePathFromURL(file.url || ''))
      this.imageUrls.delete(file.response || '')

      this.fileList.forEach((element, index) => {
        if (element.url === file.url) {
          this.fileList.splice(index, 1)
        }
      })
      return false
    }
    return true
  }

  async onRemove(file: IFile) {
    try {
      const remoteUrl = file?.response ?? ''
      await removeImage(remoteUrl)
      this.imageUrls.delete(remoteUrl)
      this.fileList.forEach((element, index) => {
        if (element.response === remoteUrl) {
          this.fileList.splice(index, 1)
        }
      })
    } catch (error) {
      errorHandler(error as TError)
    }
  }

  onSuccess(response: string, file: IFile, files: UploadFile[]) {
    this.fileList = files
    this.imageUrls.add(response)

    const uploadedResponse = { url: response }

    if (this.isScannedBarcodeFrame) {
      this.$emit('uploaded', uploadedResponse)
    }
  }

  async onError(error: Error) {
    // distinct handle max size 1015 error on server
    try {
      const msg = JSON.parse(error.message)
      const err = msg.errors?.File?.[0] ?? ''
      if (err.includes('1015')) {
        const byteNum = err.substring(err.indexOf('(') + 1, err.indexOf(')'))
        errorHandler('1015', { val1: byteNum })
      } else {
        errorHandler(err)
      }
    } catch (err) {
      errorHandler(error)
    }
  }

  onPreview(file?: IFile) {
    window.open(file?.url ?? this.scannedBCImg.src, '_blank')
  }

  @Watch('imageUrls', { deep: true })
  onImageChange() {
    mitt.emit('update:trackInfo', { [this.fieldName]: Array.from(this.imageUrls) })
    mitt.emit('update:fileList', { [this.fieldName]: Array.from(this.fileList) })
  }

  isUploadedScannedBC = false
  @Watch('$store.getters.scannedBarcodeBlob')
  displayBarcodeImage() {
    if (this.scannedBCFile) {
      const urlCreator = window.URL || window.webkitURL
      const blobUrl = urlCreator.createObjectURL(this.scannedBCFile)
      this.fileList = [
        {
          name: `${this.barcodeId}.png`,
          url: blobUrl,
        } as UploadFile,
      ]
    }
  }

  @Watch('$store.getters.scannedBarcodeBlob')
  scannedBCChanged() {
    this.isBarcodeScanFrame = this.fileKind === EFileKind.BARCODE_SCAN_FRAME
    if (this.isBarcodeScanFrame) this.displayBarcodeImage()
  }

  get isPhotoOnlyKind() {
    return this.fileKind === EFileKind.PHOTO_ONLY
  }

  isBarcodeScanFrame = false
  mounted() {
    this.scannedBCChanged()

    this.imageUrls = new Set()
    try {
      if (isEmpty(this.modelValue)) return

      this.fileList.push(
        ...(((this.modelValue as UploadFile[]) || []).map((val) => {
          const dataFile = { name: val.name, url: val.url, response: val.url } as UploadFile
          this.imageUrls.add(getFilePathFromURL(val.url || ''))

          return dataFile
        }) || [])
      )
    } catch (error) {
      errorHandler(error as TError)
    }
  }

  onAddScannedBCImg() {
    if (this.scannedBCFile) {
      this.displayBarcodeImage()
    } else {
      this.showDialogAlert = true
    }
  }

  httpRequestController(options: ElUploadRequestOptions) {
    const formData = new FormData()

    formData.append('barcodeId', this.barcodeId)
    formData.append('file', options.file)
    const request = {
      url: options.action,
      method: 'post',
      headers: options.headers as unknown,

      data: formData,
      onUploadProgress: (progressEvent) => {
        progressEvent.percent = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        options.onProgress(progressEvent)
      },
      withCredentials: true,
      timeout: 1800000, // 30'
    } as AxiosRequestConfig

    return customRequest(request)
  }
}
