<template>
  <SvgBase iconName="IconSearch" width="22" height="22">
    <svg width="22" height="22" viewBox="0 0 22 22" :fill="color" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.33333 18.6667C11.4041 18.6662 13.4153 17.973 15.0465 16.6973L20.1752 21.826L21.8248 20.1763L16.6962 15.0477C17.9725 13.4163 18.6662 11.4047 18.6667 9.33333C18.6667 4.18717 14.4795 0 9.33333 0C4.18717 0 0 4.18717 0 9.33333C0 14.4795 4.18717 18.6667 9.33333 18.6667ZM9.33333 2.33333C13.1938 2.33333 16.3333 5.47283 16.3333 9.33333C16.3333 13.1938 13.1938 16.3333 9.33333 16.3333C5.47283 16.3333 2.33333 13.1938 2.33333 9.33333C2.33333 5.47283 5.47283 2.33333 9.33333 2.33333Z"
        :fill="color"
      />
    </svg>
  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: { SvgBase },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconSearch extends Vue {}
</script>
