import { getDefaultDialogConfig } from '@/utils/constants'
import { createStore } from 'vuex'
import { CLOSE_DIALOG, OPEN_DIALOG, RESET_PAGE_NOT_FOUND, SET_PAGE_NOT_FOUND } from './actions'
import BarcodeModule from './barcode'
import LocationModule from './location'
import NotificationModule from './notification'
import ProfileModule from './profile'
import ProjectModule from './project'
interface TAppState {
  dialogVisible: boolean
  dialogConfig: {
    title: string
    message: string
    confirmation: boolean
    needtranslation?: boolean
  }
  isNotFound: boolean
  notFoundItem: string
}

export default createStore({
  strict: true,
  state: {
    dialogVisible: false,
    dialogConfig: {
      title: '',
      message: '',
      confirmation: true,
    },
    isNotFound: false,
    notFoundItem: '',
  },
  mutations: {
    [OPEN_DIALOG]: (
      state: TAppState,
      payload: {
        title: string
        message: string
        confirmation: boolean
      }
    ) => {
      state.dialogVisible = true
      state.dialogConfig = payload
    },
    [CLOSE_DIALOG]: (state: TAppState) => {
      state.dialogVisible = false
      state.dialogConfig = getDefaultDialogConfig()
    },
    [SET_PAGE_NOT_FOUND]: (state: TAppState, payload: { item: string }) => {
      state.isNotFound = true
      state.notFoundItem = payload.item
    },
    [RESET_PAGE_NOT_FOUND]: (state: TAppState) => {
      state.isNotFound = false
      state.notFoundItem = ''
    },
  },
  actions: {
    [OPEN_DIALOG]: (
      {
        commit,
      }: {
        commit: Function
      },
      payload: {
        title?: string
        message: string
        confirmation?: boolean
        needtranslation?: boolean
      }
    ) => {
      commit(OPEN_DIALOG, { ...getDefaultDialogConfig(), ...payload })
    },
    [CLOSE_DIALOG]: ({ commit }: { commit: Function }) => {
      commit(CLOSE_DIALOG)
    },
    [SET_PAGE_NOT_FOUND]: (
      {
        commit,
      }: {
        commit: Function
      },
      payload: {
        item: string
      }
    ) => {
      commit(SET_PAGE_NOT_FOUND, payload)
    },
    [RESET_PAGE_NOT_FOUND]: ({ commit }: { commit: Function }) => {
      commit(RESET_PAGE_NOT_FOUND)
    },
  },
  modules: {
    profile: ProfileModule,
    project: ProjectModule,
    barcode: BarcodeModule,
    notification: NotificationModule,
    location: LocationModule,
  },
})
