<template>
  <svg fill="none" width="40" height="40" viewBox="-4 -4 40 40">
    <path
      d="M26.1149 8.11163L28.2483 5.95349L29.9402 7.66512L32 5.5814L26.6299 0L24.5701 2.08372L26.2621 3.79535L24.1287 5.95349L20.5977 2.45581L18.4644 0.297674L16.4046 2.3814L18.5379 4.53953L2.86897 20.3907L3.97241 25.8977L0 29.9163L2.05977 32L6.03218 27.9814L11.623 29.1721L27.2184 13.3953L29.646 15.8512L31.7057 13.7674L29.2782 11.3116L26.1149 8.11163ZM10.6667 25.9721L6.91494 25.1535L6.10575 21.3581L8.01839 19.4233L10.2253 21.6558L11.7701 20.093L9.56322 17.8605L12.6529 14.7349L14.8598 16.9674L16.4046 15.4047L14.1977 13.1721L17.2874 10.0465L19.4943 12.2791L21.0391 10.7163L18.8322 8.48372L20.5977 6.69767L25.1586 11.3116L10.6667 25.9721Z"
      :fill="color"
    />
  </svg>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'

@Options({
  components: {},
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconVaccine extends Vue {}
</script>
