<template>
  <div class="field-input" :class="{ error: !!error }">
    <el-autocomplete
      :id="`Input_${name}`"
      :name="`Input_${name}`"
      v-model="_modelValue"
      :fetch-suggestions="suggestingItems"
      type="email"
      :placeholder="placeholder"
      :disabled="disabled"
      clearable
      class="inline-input w-full"
    >
    </el-autocomplete>
    <span v-show="!disableError && error" class="error-msg">{{ error }}</span>
  </div>
</template>

<script lang="ts">
import { IBarcodeDefinitionType, IProject } from 'smartbarcode-web-core/src/utils/types/index'
import { Options, Vue } from 'vue-class-component'
import { InjectReactive, Prop, PropSync } from 'vue-property-decorator'
@Options({
  emits: ['update:modelValue'],
})
export default class InputEmail extends Vue {
  @PropSync('modelValue', { type: String }) _modelValue?: string
  @Prop({ type: String }) readonly name?: string = ''
  @Prop({ type: Boolean }) readonly disableError?: boolean
  @Prop({ type: String }) readonly error?: string
  @Prop({ type: String }) readonly placeholder?: string = ''
  @Prop({ type: Boolean }) readonly disabled?: boolean = false
  @InjectReactive() currentBarcodeType!: string

  get currentBCType(): IBarcodeDefinitionType {
    const bcTypeCode = this.$store.getters.barcodeType ?? this.currentBarcodeType

    const project = this.$store.state.project.details as IProject
    return project.barcodeTypes[bcTypeCode]
  }

  get projectNotificationEmails() {
    const routes = this.currentBCType.trackPointRouteNotifications ?? []
    const emails = routes.reduce((all, e) => new Set([...all, ...(e.emailDestinations ?? [])]), new Set([] as string[]))
    return [...emails]
  }

  get predefinedEmails() {
    return [...(this.currentBCType.predefinedContact?.emails ?? [])]
  }

  suggestingItems(queryString: string, callback: Function) {
    const suggestions = this.projectNotificationEmails.concat(this.predefinedEmails).map((e) => ({ value: e }))
    callback(suggestions)
  }
}
</script>
<style lang="scss" scoped>
@import '~@/assets/css/theme.scss';
.half-width {
  width: 40%;
}
.align-middle {
  vertical-align: middle;
}
.field-input {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-evenly;

  &.error .el-input {
    border-color: $error;
  }

  .error-msg {
    color: $error;
    line-height: 1.2;
  }
}

el-dialog {
  height: 100vh;
  width: 100%;
}

.base-input-block {
  position: relative;

  &__child {
    position: absolute;
    bottom: 0%;
    right: 50%;
  }
}
</style>
