
import { CLOSE_DIALOG } from '@/store/actions'
import { Options, Vue } from 'vue-class-component'

@Options({
  components: {},
  name: 'AppDialog',
})
export default class AppDialog extends Vue {
  get isAuth() {
    return this.$store.getters.isAuth
  }

  get visible() {
    return this.$store.state.dialogVisible
  }

  get config() {
    return this.$store.state.dialogConfig
  }

  get barcodeId() {
    return this.$route.params.barcodeId || ''
  }

  get routeName() {
    return this.$route.name || ''
  }

  /** pages that don't need to display login button for display dialog */
  get exceptionPages() {
    return ['custom-html', 'home']
  }

  get isExceptionPage(): boolean {
    return this.exceptionPages.includes(this.$route.name)
  }

  onMoveToLoginPage() {
    this.closeDialog()
    const page =
      !this.barcodeId || !this.routeName
        ? { name: 'home' }
        : {
            name: 'home',
            query: {
              name: this.routeName,
              id: this.barcodeId,
            },
          }

    this.$router.push(page)
  }

  get message() {
    return this.config.needtranslation
      ? this.$t(this.config.message, { company: this.$store.state.project.enterprise })
      : this.config.message
  }

  closeDialog() {
    this.$store.dispatch(CLOSE_DIALOG)
  }
}
