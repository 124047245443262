<template>
  <el-radio-group :id="'InputRadio_' + fieldName">
    <el-radio
      v-for="(settings, value) in selections"
      :id="'InputRadio_' + settings.label"
      :key="value"
      :label="value"
      :name="name"
    >
      {{ settings.label }}
    </el-radio>
  </el-radio-group>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { TOptions } from 'smartbarcode-web-core/src/utils/types/index'

@Options({
  name: 'InputRadio',
})
export default class InputRadio extends Vue {
  @Prop({ type: Object }) readonly selections!: TOptions
  @Prop({ type: String }) readonly name!: string
  @Prop({ type: String }) readonly fieldName?: string = ''
}
</script>
