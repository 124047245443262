<template>
  <SvgBase iconName="rack" width="37" height="31">
    <path
      d="M36.2715 10.8724L19.3215 0.235503C18.8178 -0.0785009 18.19 -0.0785009 17.6862 0.235503L0.73628 10.8724C0.279011 11.155 0 11.6652 0 12.2069V19.3897L18.5 31L37 19.3897V12.2069C37 11.6652 36.721 11.155 36.2637 10.8724H36.2715ZM29.4357 17.8276V20.4416L22.9797 24.4923V21.8782L29.4357 17.8276ZM18.5 3.41479L32.5126 12.2069L18.5 20.999L4.49518 12.2069L18.5 3.41479ZM14.0203 21.8782V24.4923L7.56431 20.4416V17.8276L14.0203 21.8782ZM3.09238 15.0251L4.45643 15.8807V18.4948L3.09238 17.6391V15.0251ZM17.1204 26.4391V23.825L17.6785 24.1783C17.9265 24.3353 18.2132 24.4138 18.4922 24.4138C18.7713 24.4138 19.058 24.3353 19.306 24.1783L19.8641 23.825V26.4391L18.4845 27.3026L17.1049 26.4391H17.1204ZM32.5358 18.4948V15.8807L33.8999 15.0251V17.6391L32.5358 18.4948Z"
      fill="white"
    />
  </SvgBase>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: { SvgBase },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconRack extends Vue {}
</script>
