import store from '@/store'
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import NotFound from '../components/common/NotFound.vue'
import Home from '../views/Home.vue'
import Register from '../views/Register.vue'
import { ELinkType } from './../utils/enums'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:project',
    name: 'home',
    component: Home,
    meta: {
      header: {
        logo: false,
      },
      restricted: false,
    },
  },
  {
    path: '/:project/register',
    name: 'register',
    component: Register,
    meta: {
      header: {
        logo: false,
      },
      restricted: false,
    },
  },
  {
    path: '/:project/qrcode/:barcodeId',
    name: 'detail',
    component: () => import(/* webpackChunkName: "detail" */ '../views/Detail.vue'),
    meta: {
      header: {
        logo: true,
        homeButtton: true,
      },
      restricted: false,
    },
  },
  {
    path: '/:project/qrcode/:barcodeId/activate',
    name: 'activate',
    component: () => import(/* webpackChunkName: "activate" */ '../views/Activate.vue'),
    meta: {
      header: {
        logo: true,
        homeButtton: true,
      },
      restricted: true,
    },
    redirect: { name: 'activate-type' },
    children: [
      {
        path: 'type',
        name: 'activate-type',
        component: () => import(/* webpackChunkName: "activate" */ '../views/ActivateType.vue'),
      },
      {
        path: 'trackpoint',
        name: 'activate-trackpoint',
        component: () => import(/* webpackChunkName: "activate" */ '../views/ActivateTrackpoint.vue'),
      },
      {
        path: 'origin',
        name: 'shipping-origin',
        component: () => import(/* webpackChunkName: "activate" */ '../views/ShippingSource.vue'),
      },
      {
        path: 'destination',
        name: 'shipping-destination',
        component: () => import(/* webpackChunkName: "activate" */ '../views/ShippingDestination.vue'),
      },
      {
        path: 'package',
        name: 'shipping-packageInfo',
        component: () => import(/* webpackChunkName: "activate" */ '../views/PackageInfo.vue'),
      },
    ],
    beforeEnter: (to, from, next) => {
      next()
    },
  },
  {
    path: '/:project/qrcode/:barcodeId/edit',
    name: 'edit',
    component: () => import(/* webpackChunkName: "activate" */ '../views/Edit.vue'),
    meta: {
      header: {
        logo: true,
        homeButtton: true,
      },
      restricted: true,
    },
    props: true,
  },
  {
    path: '/:project/qrcode/:barcodeId/trace',
    name: 'trace',
    component: () => import(/* webpackChunkName: "trace" */ '../views/Trace.vue'),
    meta: {
      header: {
        logo: true,
        homeButtton: true,
      },
      restricted: false,
    },
  },
  {
    path: '/:project/qrcode/:barcodeId/track',
    name: 'track',
    component: () => import(/* webpackChunkName: "trace" */ '../views/Track.vue'),
    meta: {
      header: {
        logo: true,
        homeButtton: true,
      },
      restricted: true,
    },
  },
  {
    path: '/:project/qrcode/:barcodeId/pair',
    name: 'pair',
    component: () => import(/* webpackChunkName: "scan" */ '../views/PairScan.vue'),
    meta: {
      header: {
        logo: true,
        homeButtton: true,
      },
      restricted: true,
    },
  },
  {
    path: '/:project/qrcode/:barcodeId/pairkht',
    name: 'pair-with-keyence-handyterminal',
    component: () => import(/* webpackChunkName: "scan" */ '../views/keyenceHT/PairScan.vue'),
    meta: {
      header: {
        logo: true,
        homeButtton: true,
      },
      restricted: true,
    },
  },
  {
    path: '/:project/qrcode/:barcodeId/children',
    name: 'children',
    component: () => import(/* webpackChunkName: "children" */ '../views/Children.vue'),
    meta: {
      header: {
        logo: true,
        homeButtton: true,
      },
      restricted: false,
    },
  },
  {
    path: `/:project/qrcode/:barcodeId/${ELinkType.LINK_TO}`,
    name: `${ELinkType.LINK_TO}-scan`,
    component: () => import(/* webpackChunkName: "scan" */ '../views/BarcodeLinkScan.vue'),
    meta: {
      header: {
        logo: true,
        homeButtton: true,
      },
      restricted: true,
    },
  },
  {
    path: `/:project/qrcode/:barcodeId/${ELinkType.BE_LINKED}`,
    name: `${ELinkType.BE_LINKED}-scan`,
    component: () => import(/* webpackChunkName: "scan" */ '../views/BarcodeLinkScan.vue'),
    meta: {
      header: {
        logo: true,
        homeButtton: true,
      },
      restricted: true,
    },
  },
  {
    path: '/:project/qrcode/:barcodeId/unpair',
    name: 'barcode-unpair-scan',
    component: () => import(/* webpackChunkName: "scan" */ '../views/BarcodeUnpairScan.vue'),
    meta: {
      header: {
        logo: true,
        homeButtton: true,
      },
      restricted: true,
    },
  },
  {
    path: '/:project/qrcode/:barcodeId/linkkht',
    name: 'barcode-link-scan-with-keyence-handyterminal',
    component: () => import(/* webpackChunkName: "scan" */ '../views/keyenceHT/LinkScan.vue'),
    meta: {
      header: {
        logo: true,
        homeButtton: true,
      },
      restricted: true,
    },
  },
  {
    path: '/:project/qrcode/:barcodeId/bulk-tracking',
    name: 'bulk-tracking',
    component: () => import(/* webpackChunkName: "children" */ '../views/BulkTracking.vue'),
    meta: {
      header: {
        logo: true,
        homeButtton: true,
      },
      restricted: true,
    },
  },
  {
    path: '/:project/qrcode/:barcodeId/bulk-tracking-kht',
    name: 'bulk-tracking-with-keyence-handyterminal',
    component: () => import(/* webpackChunkName: "children" */ '../views/keyenceHT/BulkTracking.vue'),
    meta: {
      header: {
        logo: true,
        homeButtton: true,
      },
      restricted: true,
    },
  },
  {
    path: '/:project/qrcode/:barcodeId/bulk-skip-tracking',
    name: 'bulk-skip-tracking',
    component: () => import(/* webpackChunkName: "children" */ '../views/BulkSkipTracking.vue'),
    meta: {
      header: {
        logo: true,
        homeButtton: true,
      },
      restricted: true,
    },
  },
  {
    path: '/:project/qrcode/:barcodeId/bulk-skip-tracking-kht',
    name: 'bulk-skip-tracking-with-keyence-handyterminal',
    component: () => import(/* webpackChunkName: "children" */ '../views/keyenceHT/BulkSkipTracking.vue'),
    meta: {
      header: {
        logo: true,
        homeButtton: true,
      },
      restricted: true,
    },
  },
  {
    path: '/:project/qrcode/:barcodeId/scanned-barcodes',
    name: 'scanned-barcode-list',
    component: () => import(/* webpackChunkName: "children" */ '../views/ScannedBCList.vue'),
    meta: {
      header: {
        logo: true,
        homeButtton: true,
      },
      restricted: true,
    },
  },
  {
    path: '/:project/qrcode/scan-ocr',
    name: 'ocr-scan',
    component: () => import(/* webpackChunkName: "children" */ '../components/OCRComponent.vue'),
    meta: {
      header: {
        logo: true,
        homeButtton: true,
      },
      restricted: false,
    },
  },
  {
    path: '/:project/qrcode/:barcodeId/history',
    name: 'history',
    component: () => import(/* webpackChunkName: "children" */ '../views/RecycleHistory.vue'),
    meta: {
      header: {
        logo: true,
        homeButtton: true,
      },
      restricted: true,
    },
  },
  {
    path: '/:project/qrcode/:barcodeId/html',
    name: 'custom-html',
    component: () => import(/* webpackChunkName: "children" */ '../views/CustomHTML.vue'),
    meta: {
      header: {
        logo: true,
        homeButtton: false,
      },
      restricted: false,
    },
  },
  {
    path: '/:project/scan',
    name: 'scan',
    component: () => import(/* webpackChunkName: "scan" */ '../views/Scan.vue'),
    meta: {
      header: false,
      restricted: false,
    },
  },
  {
    path: '/:project/tracking-count',
    name: 'userTrackingHistory',
    component: () => import(/* webpackChunkName: "userTrackingHistory" */ '../views/UserTrackingHistory.vue'),
    meta: {
      header: {
        logo: true,
        homeButtton: true,
      },
      restricted: true,
    },
  },
  {
    path: '/:project/search',
    name: 'barcodeSearchPage',
    component: () => import(/* webpackChunkName: "barcodeSearchPage" */ '../views/BarcodeSearchPage.vue'),
    meta: {
      header: {
        logo: true,
        homeButtton: true,
      },
      restricted: true,
    },
  },
  {
    path: '/:project/barcode-list',
    name: 'barcodeListPage',
    component: () => import(/* webpackChunkName: "barcodeListPage" */ '../views/BarcodeListPage.vue'),
    meta: {
      header: {
        logo: true,
        homeButtton: true,
      },
      restricted: true,
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'notFound',
    component: NotFound,
    meta: {
      header: {
        logo: false,
        homeButtton: true,
      },
      restricted: false,
    },
    props: true,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.onError((error) => {
  if (/loading chunk \d* failed./i.test(error.message)) {
    window.location.reload()
  }
})

router.beforeEach((to, from, next) => {
  // block user from entering restricted area without login
  // skip before init
  if (store.getters.alreadyGetProfileOnInit && !store.getters.isAuth && to.meta.restricted) {
    next({
      name: 'home',
      params: { project: to.params.project },
    })
  }

  const allowEnterIfBarcodeExist = ['activate', 'edit', 'trace', 'track', 'children', 'history', 'pair']
  const routeName = to.name as string
  const parentRouteName = (to.matched[0].name as string) || ''
  const matched =
    allowEnterIfBarcodeExist.indexOf(routeName) !== -1 || allowEnterIfBarcodeExist.indexOf(parentRouteName) !== -1

  if (matched && !store.getters.barcodeExist) {
    next({
      name: 'detail',
      params: { ...to.params },
      replace: true,
    })
  } else {
    next()
  }
})

router.afterEach(() => {
  window.scrollTo(0, 0)
})

export default router
