<template>
  <div>
    <el-button
      :type="failToGetLocation ? 'info' : 'primary'"
      class="full-button button-break-word text-center"
      :icon="buttonIcon"
      @click="onClickButton"
    >
      {{ buttonText }}
    </el-button>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

import { OPEN_DIALOG } from '@/store/actions'
import mitt from '@/utils/mitt'
import { ILocationData } from 'smartbarcode-web-core/src/utils/types/index'

@Options({
  inheritAttrs: false,
  name: 'InputLocation',
})
export default class InputLocation extends Vue {
  @Prop({ type: Object }) readonly modelValue!: ILocationData
  @Prop({ type: String }) readonly fieldName!: string

  failToGetLocation = false
  loading = false

  get buttonText() {
    if (this.loading) return ''

    return this.failToGetLocation ? this.$t('Fail to get location') : this.$t('Location is tracked')
  }

  get buttonIcon() {
    return this.loading ? 'el-icon-loading' : 'el-icon-info'
  }

  onClickButton() {
    if (this.failToGetLocation) {
      this.requestPosition()
      return
    }

    const lat = this.modelValue?.latitude
    const lng = this.modelValue?.longitude

    if (lat && lng) {
      window.open(`https://www.google.com/maps/search/?api=1&query=${lat},${lng}`, '_blank')
    }
  }

  async requestPosition() {
    this.loading = true
    await new Promise(() => {
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          this.loading = false
          this.failToGetLocation = false
          mitt.emit('update:trackInfo', {
            [this.fieldName]: {
              latitude: pos.coords.latitude,
              longitude: pos.coords.longitude,
            },
          })
        },
        (err) => {
          this.loading = false
          this.failToGetLocation = true
          console.log(err)
          this.$store.dispatch(OPEN_DIALOG, {
            message: this.$t ? this.$t('Please allow location access') : 'Fail to get location',
          })
        },
        {
          enableHighAccuracy: true,
        }
      )
    })
  }

  mounted() {
    this.requestPosition()
  }
}
</script>
<style lang="scss" scoped>
@import '~@/assets/css/mixins.scss';

.el-button + .el-button {
  margin-left: 8px;
}
.button-break-word {
  white-space: unset;
}
.el-button:nth-child(2) {
  padding: 12px 16px;
}
</style>
