/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-unused-expressions */
import { MASKED_ADDRESS, MASKED_PACKAGE_DIMENSION } from './constants'
import projectStore from '@/store/project'
import get from 'lodash/get'
import { IBarcode, IFormInputSchema, IProject, ITrackingAuditData } from 'smartbarcode-web-core/src/utils/types/index'
import { ECustomFieldType } from 'smartbarcode-web-core/src/utils/enums/index'
import { MASKED_ANNOTATION } from 'smartbarcode-web-core/src/utils/constants'

function compileOriginData(barcode: IBarcode) {
  if (barcode?.activationData?.maskedFields?.includes('origin')) {
    barcode.activationData.origin = MASKED_ADDRESS
  }
}
function compileDestinationData(barcode: IBarcode) {
  if (barcode?.activationData?.maskedFields?.includes('origin')) {
    barcode.activationData.destination = MASKED_ADDRESS
  }
}

function compilePackageInfoData(barcode: IBarcode) {
  if (barcode.activationData?.maskedFields?.includes('dimension')) {
    barcode.activationData.dimension = {
      ...MASKED_PACKAGE_DIMENSION,
    }
  }

  if (barcode.activationData?.maskedFields?.includes('trackingNumber')) {
    barcode.activationData.trackingNumber = MASKED_ANNOTATION
  }

  if (barcode.activationData?.maskedFields?.includes('externalId')) {
    barcode.activationData.externalId = MASKED_ANNOTATION
  }
}

function compileCustomFieldData(customFields: unknown, maskedFields: string[], definition: unknown) {
  if (maskedFields) {
    const keys = maskedFields.filter((maskField) => maskField.match(/^customFields\./))
    keys.forEach((pathKey) => {
      const field: IFormInputSchema = get(definition, pathKey)
      const key = pathKey.split('.')[pathKey.split('.').length - 1]
      if (!customFields) return
      switch (field?.fieldType) {
        case ECustomFieldType.TEXT:
          Object.assign(customFields, {
            [key]: {
              fieldType: ECustomFieldType.TEXT,
              [ECustomFieldType.TEXT]: MASKED_ANNOTATION,
            },
          })
          break
        case ECustomFieldType.DATE:
          Object.assign(customFields, {
            [key]: {
              fieldType: ECustomFieldType.DATE,
              [ECustomFieldType.DATE]: MASKED_ANNOTATION,
            },
          })
          break
        case ECustomFieldType.EMAIL:
          Object.assign(customFields, {
            [key]: {
              fieldType: ECustomFieldType.EMAIL,
              [ECustomFieldType.EMAIL]: MASKED_ANNOTATION,
            },
          })
          break
        case ECustomFieldType.PHONE_NUMBER: {
          Object.assign(customFields, {
            [key]: {
              fieldType: ECustomFieldType.PHONE_NUMBER,
              [ECustomFieldType.PHONE_NUMBER]: {
                countryCode: MASKED_ANNOTATION,
                number: MASKED_ANNOTATION,
              },
            },
          })
          break
        }
        case ECustomFieldType.NUMBER:
          Object.assign(customFields, {
            [key]: {
              fieldType: ECustomFieldType.NUMBER,
              [ECustomFieldType.NUMBER]: MASKED_ANNOTATION,
            },
          })
          break
        case ECustomFieldType.MULTI_SELECT: {
          Object.assign(customFields, {
            [key]: {
              fieldType: ECustomFieldType.MULTI_SELECT,
              [ECustomFieldType.MULTI_SELECT]: [{ key: MASKED_ANNOTATION, value: MASKED_ANNOTATION }],
            },
          })
          break
        }
        case ECustomFieldType.SINGLE_SELECT: {
          Object.assign(customFields, {
            [key]: {
              fieldType: ECustomFieldType.SINGLE_SELECT,
              [ECustomFieldType.SINGLE_SELECT]: { key: MASKED_ANNOTATION, value: MASKED_ANNOTATION },
            },
          })
          break
        }
        case ECustomFieldType.REFERENCE: {
          Object.assign(customFields, {
            [key]: {
              fieldType: ECustomFieldType.REFERENCE,
              [ECustomFieldType.REFERENCE]: {
                template: MASKED_ANNOTATION,
                [ECustomFieldType.TEXT]: MASKED_ANNOTATION,
              },
            },
          })
          break
        }
        case ECustomFieldType.ESIGN: {
          Object.assign(customFields, {
            [key]: {
              fieldType: ECustomFieldType.ESIGN,
              [ECustomFieldType.ESIGN]: MASKED_ANNOTATION,
            },
          })
          break
        }
        case ECustomFieldType.GHG_EMISSION: {
          Object.assign(customFields, {
            [key]: {
              fieldType: ECustomFieldType.GHG_EMISSION,
              [ECustomFieldType.GHG_EMISSION]: MASKED_ANNOTATION,
            },
          })
          break
        }
      }
    })
  }
}

function compileActivationDataCustomFields(barcode: IBarcode) {
  const projectDetail = projectStore.state.details as IProject
  const currentBarcodeDefinition = projectDetail?.barcodeTypes[barcode?.barcodeType]
  if (!currentBarcodeDefinition) return
  compileCustomFieldData(
    barcode.activationData.customFields,
    barcode.activationData.maskedFields || [],
    currentBarcodeDefinition.activationFields
  )
  barcode.activationData.linkedBarcodes?.forEach((barcode) => {
    compileLinkedData(barcode)
  })
}

function compileTrackingDataAudit(maskedFields: string[], auditData?: ITrackingAuditData) {
  if (auditData && maskedFields) {
    if (maskedFields.includes('trackedUserName')) {
      auditData.trackedUserName = MASKED_ANNOTATION
    }
    if (maskedFields.includes('trackedUserId')) {
      auditData.trackedUserId = MASKED_ANNOTATION
    }
  }
}

function compileTrackingData(barcode: IBarcode) {
  const projectDetail = projectStore.state.details as IProject
  const trackingData = barcode?.trackingData || []
  trackingData.forEach((td, idx) => {
    const currentTPKey = td?.currentTrackPointKey
    const nextTPKey = td?.nextTrackPointKey
    const tpDefinition = projectDetail?.trackPoints?.[currentTPKey]?.trackPointForms?.[nextTPKey]
    compileCustomFieldData(td?.customFields, td?.maskedFields as string[], tpDefinition)
    compileTrackingDataAudit(td?.maskedFields as string[], td?.audit)
    trackingData?.[idx]?.linkedBarcodes?.forEach((barcode) => {
      compileLinkedData(barcode)
    })
  })
}

export async function getCompiledBarcode(barcode: IBarcode) {
  compileOriginData(barcode)
  compileDestinationData(barcode)
  compilePackageInfoData(barcode)
  compileActivationDataCustomFields(barcode)
  compileTrackingData(barcode)
  return barcode
}

function compileLinkedData(linkedBarcodes?: IBarcode) {
  if (!linkedBarcodes) return
  getCompiledBarcode(linkedBarcodes)
}
