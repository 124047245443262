
import { Vue, Options } from 'vue-class-component'

import IconLogout from '@/components/svg/IconLogout.vue'
import IconArrow from '@/components/svg/IconArrow.vue'
import IconHome from '@/components/svg/IconHome.vue'
import { LOGOUT } from '@/store/actions'
import errorHandler from '@/utils/errorHandler'
import { TError } from 'smartbarcode-web-core/src/utils/types/index'

@Options({
  components: {
    IconLogout,
    IconArrow,
    IconHome,
  },
  name: 'AppHeader',
})
export default class AppHeader extends Vue {
  showLogoutAlert = false

  get showHeader() {
    return this.$route.meta.header
  }

  get image() {
    return this.$store.getters.customLogoImageUrl || require('@/assets/logo-smartBarcode.png')
  }

  get isAuth() {
    return this.$store.state.profile.user !== null
  }

  get showLogo() {
    return !!this.$route.meta.header?.logo
  }

  get showHomeButton() {
    return this.$store.getters.projectParam && !!this.$route.meta.header?.homeButtton
  }

  async logout() {
    try {
      await this.$store.dispatch(LOGOUT)
      this.showLogoutAlert = false
      this.goHome()
    } catch (error) {
      errorHandler(error as TError)
    }
  }

  goHome() {
    this.$router.push({
      name: 'home',
      params: { project: this.$store.getters.projectParam },
    })
  }
}
