
import { EBCRType, EOCRType } from 'smartbarcode-web-core/src/utils/enums/index'
import { Options, Vue } from 'vue-class-component'
import { Prop, PropSync } from 'vue-property-decorator'
import InputReadersSuffix from '@/components/InputReadersSuffix.vue'
import { ICustomFieldData } from 'smartbarcode-web-core/src/utils/types/index'
@Options({
  emits: ['update:modelValue'],
  name: 'InputReference',
  components: {
    InputReadersSuffix,
  },
})
export default class InputReference extends Vue {
  @PropSync('modelValue', { type: Object }) _modelValue?: ICustomFieldData
  @Prop({ type: Boolean }) readonly disableError?: boolean
  @Prop({ type: String }) readonly error?: string
  @Prop({ type: Boolean }) readonly disabled?: boolean = false
  @Prop({ type: String }) readonly placeholder?: string = ''
  // input props number
  @Prop({ default: '' }) barcodeReaderType!: EBCRType
  @Prop({ default: '' }) ocrReaderType!: EOCRType
  @Prop({ type: Number }) readonly minValue?: number
  @Prop({ type: Number }) readonly maxValue?: number
  @Prop({ type: Boolean }) readonly multiLine?: boolean
  @Prop({ type: Number }) readonly minLength?: number
  @Prop({ type: Number }) readonly maxLength?: number
  @Prop({ type: String }) readonly fieldName?: string = ''

  get InputReadersSuffixProps() {
    return {
      fieldName: this.fieldName,
      bcrType: this.barcodeReaderType,
      ocrType: this.ocrReaderType,
    }
  }

  get textInputPropAttrs() {
    return {
      fieldName: this.fieldName,
      type: 'textarea',
      disabled: this.disabled,
      placeholder: this.placeholder,
      minlength: this.minLength,
      maxlength: this.maxLength,
      min: this.minValue,
      max: this.maxValue,
      'show-word-limit': true,
      ...(!!this.multiLine && {
        rows: Math.ceil((this.maxLength ?? 0) / 20),
      }),
    }
  }
}
