
import { DATE_TIME } from '@/utils/constants'
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
import { format } from 'date-fns'
import { Options, Vue } from 'vue-class-component'
import { Watch, Prop } from 'vue-property-decorator'
@Options({
  emits: ['update:modelValue', 'change'],
  inheritAttrs: false,
  name: 'InputDate',
})
export default class InputDate extends Vue {
  @Prop({ type: String }) readonly modelValue?: string

  get datePickerFormat() {
    return DATE_TIME.DISPLAY_FORMAT
  }

  get value() {
    return this.modelValue
  }

  set value(newVal) {
    this.$emit('update:modelValue', newVal)
  }

  @Watch('modelValue')
  modelValueChanged() {
    this.$emit('change')
  }

  convertDate(value: Date) {
    this.value = format(value, DATE_TIME.WITHOUT_SECOND).toString()
  }

  created() {
    if (isEmpty(this.modelValue) || this.modelValue === '0') {
      const currentDate = new Date()
      this.value = format(currentDate, DATE_TIME.WITHOUT_SECOND).toString()
    }
  }
}
