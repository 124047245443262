
import {
  default1DFormat,
  default1DScanditDataCaptureFormat,
  default2DFormat,
  default2DScanditDataCaptureFormat,
  defaultBarcodeReader,
  defaultFormat,
  scanditDataCapture,
} from '@/utils/barcodeReader'
import { BARCODE_SCAN, LOCAL_STORAGE_ITEM } from '@/utils/constants'
import { EBCReaderCategory, EBCRType } from 'smartbarcode-web-core/src/utils/enums/index'
import { BarcodeFormat, BrowserMultiFormatReader, DecodeHintType } from '@zxing/library'
import { Options, Vue } from 'vue-class-component'
import { SymbologySettings, Symbology, BarcodeCaptureSettings, BarcodeCapture } from 'scandit-web-datacapture-barcode'
import { FrameSourceState, DataCaptureContext } from 'scandit-web-datacapture-core'

@Options({
  components: {},
  name: 'BarcodeReaderMixin',
})
export default class BarcodeReaderMixin extends Vue {
  privateSymbology = ''
  context = {}
  get symbologies() {
    const lastSelectedSymbology = localStorage.getItem(this.localStorageItem)
    if (!lastSelectedSymbology && !this.privateSymbology) {
      localStorage.setItem(this.localStorageItem, EBCReaderCategory.DEFAULT)
      this.privateSymbology = EBCReaderCategory.DEFAULT
      return this.privateSymbology
    }

    if (this.privateSymbology) return this.privateSymbology

    switch (lastSelectedSymbology) {
      case EBCReaderCategory.ONE_DIMENSION:
      case EBCReaderCategory.QR:
      case EBCReaderCategory.DEFAULT:
        this.privateSymbology = lastSelectedSymbology
        return this.privateSymbology
      default:
        localStorage.setItem(this.localStorageItem, EBCReaderCategory.DEFAULT)
        this.privateSymbology = EBCReaderCategory.DEFAULT
        return this.privateSymbology
    }
  }

  set symbologies(symbology: string) {
    this.privateSymbology = symbology
    localStorage.setItem(this.localStorageItem, symbology)
  }

  get localStorageItem() {
    return `[${this.$route.name}]_${LOCAL_STORAGE_ITEM.LAST_SELECTED_SYMBOLOGY}`
  }

  barcodeDataCaptureContext(context: DataCaptureContext, barcodeDataCapture: BarcodeCapture) {
    this.loading = false
    this._barcodeDataCapture = barcodeDataCapture
    this.context = context
  }

  get zxingInstance(): BrowserMultiFormatReader {
    return this.codeReader
  }

  get isScanditReaderProject() {
    return this.$store.getters.isProjectSupportScandit
  }

  get barcodeReaderType() {
    return this.customBarcodeReaderType ?? (this.isScanditReaderProject ? EBCRType.SCANDIT : EBCRType.DEFAULT)
  }

  async initScanditReader() {
    const enableSymbologies = []
    if (this.symbologies === EBCReaderCategory.QR) {
      enableSymbologies.push(...default2DScanditDataCaptureFormat)
    }

    if (this.symbologies === EBCReaderCategory.ONE_DIMENSION) {
      enableSymbologies.push(...default1DScanditDataCaptureFormat)
    }

    if (this.symbologies === EBCReaderCategory.DEFAULT) {
      enableSymbologies.push(...default1DScanditDataCaptureFormat, ...default2DScanditDataCaptureFormat)
    }
    const video = document.getElementById('video') as HTMLVideoElement
    if (video) {
      video.style.display = 'none'
    }
    await scanditDataCapture(
      this.scanditDataResult,
      document.getElementById('scan-area') || new HTMLElement(),
      true,
      this.barcodeDataCaptureContext,
      enableSymbologies
    )
  }

  async initZxingReader(barcodeFormat?: BarcodeFormat[]) {
    const enableSymbologies = []
    if (this.symbologies === EBCReaderCategory.QR) {
      enableSymbologies.push(...default2DFormat)
    }

    if (this.symbologies === EBCReaderCategory.ONE_DIMENSION) {
      enableSymbologies.push(...default1DFormat)
    }

    if (this.symbologies === EBCReaderCategory.DEFAULT) {
      enableSymbologies.push(...default1DFormat, ...default2DFormat)
    }
    await this.setupVideo()
    if (!this.video?.srcObject) return
    await defaultBarcodeReader(
      this.defaultScanReader,
      (document.getElementById('scan-area') as HTMLVideoElement) || new HTMLElement(),
      this.video.srcObject as MediaStream,
      true,
      barcodeFormat || enableSymbologies || defaultFormat
    )
  }

  async setBarcodeSymbologies(selection: string) {
    switch (this.barcodeReaderType) {
      case EBCRType.SCANDIT:
        this.applyScanditSymbologies(selection)
        break
      default:
        await this.applyXzingSymbologies(selection)
        break
    }
  }

  async applyXzingSymbologies(selection: string) {
    const enableSymbologies: BarcodeFormat[] = []
    if (selection === EBCReaderCategory.QR) {
      enableSymbologies.push(...default2DFormat)
    }

    if (selection === EBCReaderCategory.ONE_DIMENSION) {
      enableSymbologies.push(...default1DFormat)
    }

    if (selection === EBCReaderCategory.DEFAULT) {
      enableSymbologies.push(...default1DFormat, ...default2DFormat)
    }
    const hints = new Map()
    hints.set(DecodeHintType.POSSIBLE_FORMATS, enableSymbologies)
    this.zxingInstance.hints = hints
    this.zxingInstance.reset()
    await this.initZxingReader(enableSymbologies)
  }

  async applyScanditSymbologies(selection: string) {
    const currentScanner = this._barcodeDataCapture
    if (!currentScanner) return
    const enableSymbologies: Symbology[] = []
    if (selection === EBCReaderCategory.QR) {
      enableSymbologies.push(...default2DScanditDataCaptureFormat)
    }

    if (selection === EBCReaderCategory.ONE_DIMENSION) {
      enableSymbologies.push(...default1DScanditDataCaptureFormat)
    }

    if (selection === EBCReaderCategory.DEFAULT) {
      enableSymbologies.push(...default1DScanditDataCaptureFormat, ...default2DScanditDataCaptureFormat)
    }

    const symbologies = [...default1DScanditDataCaptureFormat, ...default2DScanditDataCaptureFormat]
    const settings: BarcodeCaptureSettings = new BarcodeCaptureSettings()

    // enable all symbologies
    settings.enableSymbologies(symbologies)

    const codabarSetting: SymbologySettings = settings.settingsForSymbology(Symbology.Codabar)
    codabarSetting.activeSymbolCounts = [
      1, 2, 3, 4, 5, 67, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
    ]

    settings.codeDuplicateFilter = BARCODE_SCAN.timeBetweenScansMillis || 500

    symbologies.forEach((element) => {
      // disable not use symbologies
      if (!enableSymbologies.includes(element)) {
        settings.enableSymbology(element, false)
      }
    })
    await (currentScanner as BarcodeCapture).applySettings(settings)
  }

  async beforeUnmount() {
    if (this.zxingInstance) this.zxingInstance.reset()
    if (this.context && this._barcodeDataCapture) {
      await this._barcodeDataCapture.setEnabled(false)
      await (this.context as DataCaptureContext).frameSource?.switchToDesiredState(FrameSourceState.Standby)
      await (this.context as DataCaptureContext).dispose()
    }
  }
}
