
<template>
  <SvgBase iconName="ScannedBarcode" width="42" height="38" fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M41.0769 17.8571C41.0769 19.0402 40.1079 20 38.9135 20H31.5V34C31.5 35.925 29.925 37.5 28 37.5H3.5C1.575 37.5 0 35.925 0 34V9.5C0 7.575 1.575 6 3.5 6H18V5C18 3.81696 18.9691 2.85714 20.1635 2.85714H24.1298L24.6842 1.38839C24.9997 0.553571 25.8065 0 26.7079 0H32.3645C33.2659 0 34.0727 0.553571 34.3882 1.38839L34.9471 2.85714H38.9135C40.1079 2.85714 41.0769 3.81696 41.0769 5V17.8571ZM28 34V20H22H20.1635H18V17.8571V16V9.5H3.5V34H28ZM18 24V20H14V16H10V20H14V24H10V28H14V24H18ZM18 24V28H22V24H18ZM34.9471 11.4286C34.9471 8.47322 32.5222 6.07143 29.5385 6.07143C26.5547 6.07143 24.1298 8.47322 24.1298 11.4286C24.1298 14.3839 26.5547 16.7857 29.5385 16.7857C32.5222 16.7857 34.9471 14.3839 34.9471 11.4286ZM33.5048 11.4286C33.5048 13.5938 31.7245 15.3571 29.5385 15.3571C27.3525 15.3571 25.5721 13.5938 25.5721 11.4286C25.5721 9.26339 27.3525 7.5 29.5385 7.5C31.7245 7.5 33.5048 9.26339 33.5048 11.4286Z"
      :fill="color"
    />
  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconScannedBarcode extends Vue {}
</script>
