
import IconArrowBack from '@/components/svg/IconArrowBack.vue'
import IconCamera from '@/components/svg/IconCamera.vue'
import { mobileCheck } from '@/utils/helpers'
import { Options, Vue } from 'vue-class-component'
import { Prop, PropSync, Watch } from 'vue-property-decorator'

@Options({
  name: 'ActionDialog',
  components: {
    IconCamera,
    IconArrowBack,
  },
  emits: ['update:isShowActionDialog', 'update:captureImage'],
})
export default class CustomTakePhotoCamera extends Vue {
  @PropSync('isShowActionDialog', { type: Boolean }) syncedModel?: boolean
  @Prop({ type: String }) readonly overlayImage?: string
  isCaptured = false
  imageCaptured: null | Blob = null
  stream: undefined | MediaStream = undefined
  video: HTMLVideoElement | null = null

  @Watch('syncedModel')
  onDialogChanged() {
    if (!this.syncedModel) return
    this.$nextTick(() => this.initCamera())
  }

  async initCamera() {
    try {
      if (!this.video) {
        this.video = document.getElementById('video') as HTMLVideoElement
      }
      this.stream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: 'environment', aspectRatio: mobileCheck() ? 16 / 9 : 9 / 16 },
      })

      this.$refs.cameraVideo.srcObject = this.stream
    } catch (error) {
      console.error('Error accessing media devices.', error)
    }
  }

  stopBothVideoAndAudio(stream: MediaStream) {
    stream.getTracks().forEach((track) => {
      if (track.readyState === 'live') {
        track.enabled = false
        track.stop()
      }
    })
  }

  vidOff() {
    if (this.video) {
      if (this.video.srcObject) {
        this.stopBothVideoAndAudio(this.video.srcObject as MediaStream)
      }
    }
  }

  beforeUnmount() {
    this.vidOff()
  }

  retakePhoto() {
    this.imageCaptured = null
    this.isCaptured = false
    this.$refs.cameraVideo.play()
  }

  onCaptureClicked() {
    this.isCaptured = true
    const video = this.$refs.cameraVideo
    const canvas = this.$refs.cameraCanvas

    canvas.width = video.videoWidth
    canvas.height = video.videoHeight

    const context = canvas.getContext('2d')
    context.drawImage(video, 0, 0, canvas.width, canvas.height)

    // Do something with the captured image, e.g. save to server or display on page
    canvas.toBlob((blob: Blob) => (this.imageCaptured = blob), 'image/png')
    this.$refs.cameraVideo.pause()
  }

  confirm() {
    this.$emit('update:captureImage', this.imageCaptured)
    this.syncedModel = false
  }

  onDialogClosing() {
    this.$refs.cameraVideo.srcObject = null
    if (this.stream) this.stream.getTracks()[0].stop()
    this.syncedModel = false
  }
}
