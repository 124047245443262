<template>
  <SvgBase iconName="Link" width="40" height="33">
    <path
      d="M27.6923 6V21.4286C27.6923 22.8482 26.5294 24 25.0962 24H2.59615C1.16286 24 0 22.8482 0 21.4286V6C0 4.58036 1.16286 3.42857 2.59615 3.42857H7.35577L8.02103 1.66607C8.39964 0.664286 9.36779 0 10.4495 0H17.2374C18.3191 0 19.2873 0.664286 19.6659 1.66607L20.3365 3.42857H25.0962C26.5294 3.42857 27.6923 4.58036 27.6923 6ZM20.3365 13.7143C20.3365 10.1679 17.4267 7.28571 13.8462 7.28571C10.2656 7.28571 7.35577 10.1679 7.35577 13.7143C7.35577 17.2607 10.2656 20.1429 13.8462 20.1429C17.4267 20.1429 20.3365 17.2607 20.3365 13.7143ZM18.6058 13.7143C18.6058 16.3125 16.4694 18.4286 13.8462 18.4286C11.223 18.4286 9.08654 16.3125 9.08654 13.7143C9.08654 11.1161 11.223 9 13.8462 9C16.4694 9 18.6058 11.1161 18.6058 13.7143Z"
      fill="black"
    />
  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
})
export default class IconBarcodeReader extends Vue {}
</script>
