<template>
  <SvgBase iconName="Link" width="40" height="33">
    <path
      d="M27.6923 21.4286V6C27.6923 4.58036 26.5294 3.42857 25.0962 3.42857H20.3365L19.6659 1.66607C19.2873 0.664286 18.3191 0 17.2374 0H10.4495C9.36779 0 8.39964 0.664286 8.02103 1.66607L7.35577 3.42857H2.59615C1.16286 3.42857 0 4.58036 0 6V21.4286C0 22.8482 1.16286 24 2.59615 24H25.0962C26.5294 24 27.6923 22.8482 27.6923 21.4286Z"
      fill="black"
    />
    <g clip-path="url(#clip0)">
      <path
        d="M7.55566 16.5117V20.4451H11.489"
        stroke="white"
        stroke-width="2"
      />
      <path
        d="M11.489 6.55469H7.55566V10.488"
        stroke="white"
        stroke-width="2"
      />
      <path
        d="M21.4446 10.488V6.55469H17.5112"
        stroke="white"
        stroke-width="2"
      />
      <path
        d="M17.5112 20.4451H21.4446V16.5117"
        stroke="white"
        stroke-width="2"
      />
      <path
        d="M15.7021 15.3447H12.9482L12.3721 17H11.0879L13.7734 9.89062H14.8818L17.5723 17H16.2832L15.7021 15.3447ZM13.2949 14.3486H15.3555L14.3252 11.3994L13.2949 14.3486Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="15" height="15" fill="white" transform="translate(7 6)" />
      </clipPath>
    </defs>
  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
})
export default class IconOCR extends Vue {}
</script>
