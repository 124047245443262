<template>
  <h2>{{ $t('keyenceModule.trigger.label') }}</h2>
  <div class="aimer-target-options">
    <div v-for="(option, index) in options" :key="index" class="aimer-target-option">
      <input type="radio" :id="option.value" :value="option.value" v-model="aimerTarget" @change="onOptionChange"
        class="hidden-radio" />
      <label :for="option.value" class="aimer-target-option-label">
        {{ $t(option.label) }}
      </label>
    </div>
  </div>
</template>

<script lang="ts">
import { KeyenceModule, isKeyenceEnterpriseBrowser, ConfigId, isBulkReadableHT } from '@/utils/keyenceHT/keyenceModules'
import { Vue } from 'vue-class-component'
import { LOCAL_STORAGE_ITEM } from '@/utils/constants'
import { Prop } from 'vue-property-decorator'

export default class ScanerConfig extends Vue {
  @Prop({ type: String }) scannerConfigName?: string = 'ScannerConfig'
  @Prop({ type: Boolean }) shouldBulkMode?: boolean = false
  aimerTarget = 'DISABLE'
  storageKey = `${LOCAL_STORAGE_ITEM.KEYENCE_SCANNER_CONFIG_TRIGGER_MODE}-on-${this.scannerConfigName || ''}`
  options = [
    { label: 'keyenceModule.trigger.aimerTarget_disable', value: 'DISABLE' },
    { label: 'keyenceModule.trigger.aimerTarget_center', value: 'CENTER' },
    { label: 'keyenceModule.trigger.aimerTarget_horizontal', value: 'HORIZONTAL_LINE' },
  ] as LabelOption[]

  get isEnterpriseBrowser() {
    return isKeyenceEnterpriseBrowser()
  }

  async mounted() {
    this.loadSelectionFromLocalStorage()
    this.setOption()
  }

  onOptionChange() {
    this.setOption()
  }

  setOption() {
    const currentConfig = KeyenceModule.Scanner.getConfig(ConfigId.ScanParams) as {}
    const aimerTargetOption = {
      ...currentConfig,
      ...{
        target: {
          aimerTarget: this.aimerTarget,
          decodeArea: 'NORMAL',
          scanningFormat: '',
        },
        trigger: {
          continuousMode: {
            redundancyTimeout: 2000,
            successCodesCounter: {
              count: 2,
              enable: false,
            },

          },
          scannerRendencyTimeout: 3,
          scannerTimeout: 25,
          triggerMode: this.shouldBulkMode && isBulkReadableHT
            ? 'CONTINUOUS'
            : 'NORMAL',
        },
      },
    }
    KeyenceModule.Scanner.setConfig(ConfigId.ScanParams, aimerTargetOption)
    this.saveSelectionToLocalStorage()
  }

  saveSelectionToLocalStorage() {
    localStorage.setItem(this.storageKey, this.aimerTarget)
  }

  loadSelectionFromLocalStorage() {
    const savedAimerTarget = localStorage.getItem(this.storageKey)
    this.aimerTarget = savedAimerTarget || this.options[0].value
  }
}

type LabelOption = {
  label: string
  value: string
}
</script>

<style lang="scss">
@import '~@/assets/css/mixins.scss';

.hidden-radio {
  display: none;
}

.aimer-target-options {
  width: 100%;
  display: inline-flex;
}

.aimer-target-option {
  flex: 1;
  display: flex;
}

.aimer-target-option input:checked {
  display: none;
}

.aimer-target-option-label {
  margin: 8px 0;
  height: 36px;
  width: 100%;
  padding: 8px;
  border-radius: 5px;
  word-break: keep-all;
}

.aimer-target-option input:checked+.aimer-target-option-label {
  background-color: #525252;
  color: white;
}
</style>
