<template>
  <div class="cursor-pointer flex flex-row justify-evenly barcode-search-btn" @click="redirectToBarcodeSearchPage">
    <span>{{ $t('Barcode Search') }}</span>
    <div>
      <IconSearch />
    </div>
  </div>
</template>

<script lang="ts">
import IconSearch from '@/components/svg/IconSearch.vue'
import { Options, Vue } from 'vue-class-component'
@Options({
  components: { IconSearch },
  name: 'BarcodeSearchButton',
})
export default class BarcodeSearchButton extends Vue {
  redirectToBarcodeSearchPage() {
    this.$router.push({ name: 'barcodeSearchPage' })
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/mixins.scss';

.barcode-search-btn {
  background-color: #d8d8d8;
  align-items: center;
  min-height: 60px;
  border: #231815;
  border-radius: 10px;
}
</style>
