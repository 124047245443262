<template>
  <div>
    <el-button type="primary" class="my-5 w-full" @click="clickBtn">{{ $t('calculation_review') }}</el-button>
  </div>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'

@Options({
  name: 'CalculationPreview',
  emits: ['reviewCalculation'],
  components: {},
})
export default class CalculationPreview extends Vue {
  isShow = false

  clickBtn() {
    this.$emit('reviewCalculation')
  }
}
</script>
<style lang="scss" scoped>
@import '~@/assets/css/mixins.scss';
</style>
