<template>
  <div class="company-label">
    <div>
      {{ $store.state.project.enterprise || '' }}
    </div>
    <div v-if="$store.getters.projectVersion">
      {{ $store.getters.projectName }}
      ver.{{ $store.getters.projectVersion || '' }}
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'

@Options({
  name: 'CompanyLabel',
})
export default class CompanyLabel extends Vue {}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/mixins.scss';

.company-label {
  @include label-box;
  border-radius: 5px;
  font-size: pxToRem(14);
  display: block;
}
</style>
