<template>
  <SvgBase iconName="IconSave" :width="42" :height="42">
    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="21" cy="21" r="21" :fill="color" />
      <path d="M21.001 9L21.001 24.0085" stroke="white" stroke-width="2" />
      <path
        d="M25.1775 15.7171L31 15.7171L31 28.9336L11 28.9336L11 15.7171L16.8225 15.7171"
        stroke="white"
        stroke-width="2"
      />
      <path d="M25.4329 19.832L21.001 24.2639L16.5691 19.832" stroke="white" stroke-width="2" />
    </svg>
  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconSave extends Vue {}
</script>
