
import { IBarcodeDefinitionType, IProject } from 'smartbarcode-web-core/src/utils/types/index'
import { Options, Vue } from 'vue-class-component'
import { InjectReactive, Prop, PropSync } from 'vue-property-decorator'
@Options({
  emits: ['update:modelValue'],
})
export default class InputEmail extends Vue {
  @PropSync('modelValue', { type: String }) _modelValue?: string
  @Prop({ type: String }) readonly name?: string = ''
  @Prop({ type: Boolean }) readonly disableError?: boolean
  @Prop({ type: String }) readonly error?: string
  @Prop({ type: String }) readonly placeholder?: string = ''
  @Prop({ type: Boolean }) readonly disabled?: boolean = false
  @InjectReactive() currentBarcodeType!: string

  get currentBCType(): IBarcodeDefinitionType {
    const bcTypeCode = this.$store.getters.barcodeType ?? this.currentBarcodeType

    const project = this.$store.state.project.details as IProject
    return project.barcodeTypes[bcTypeCode]
  }

  get projectNotificationEmails() {
    const routes = this.currentBCType.trackPointRouteNotifications ?? []
    const emails = routes.reduce((all, e) => new Set([...all, ...(e.emailDestinations ?? [])]), new Set([] as string[]))
    return [...emails]
  }

  get predefinedEmails() {
    return [...(this.currentBCType.predefinedContact?.emails ?? [])]
  }

  suggestingItems(queryString: string, callback: Function) {
    const suggestions = this.projectNotificationEmails.concat(this.predefinedEmails).map((e) => ({ value: e }))
    callback(suggestions)
  }
}
