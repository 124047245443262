<template>
  <SvgBase iconName="truck" width="48" height="30">
    <path
      d="M15.2 25.7H0V15.8L6.2 6.5H15.2V10.5H8.4L4 17.1V21.7H15.2V25.7Z"
      fill="white"
    />
    <path
      d="M47.5999 25.7H12.8999V0H47.5999V25.7ZM16.8999 21.7H43.5999V4H16.8999V21.7Z"
      fill="white"
    />
    <path
      d="M8.5001 29.8C6.3001 29.8 4.6001 28 4.6001 25.9C4.6001 23.8 6.3001 22 8.5001 22C10.7001 22 12.4001 23.8 12.4001 25.9C12.4001 28 10.7001 29.8 8.5001 29.8ZM8.5001 25C8.0001 25 7.6001 25.4 7.6001 25.9C7.6001 26.4 8.0001 26.8 8.5001 26.8C9.0001 26.8 9.4001 26.4 9.4001 25.9C9.4001 25.4 9.0001 25 8.5001 25Z"
      fill="white"
    />
    <path
      d="M36.5999 29.8C34.3999 29.8 32.7 28 32.7 25.9C32.7 23.8 34.4999 22 36.5999 22C38.6999 22 40.5 23.8 40.5 25.9C40.5 28 38.6999 29.8 36.5999 29.8ZM36.5999 25C36.0999 25 35.7 25.4 35.7 25.9C35.7 26.4 36.0999 26.8 36.5999 26.8C37.0999 26.8 37.5 26.4 37.5 25.9C37.5 25.4 37.0999 25 36.5999 25Z"
      fill="white"
    />
    <path d="M9.6 14.4H2V18.4H9.6V14.4Z" fill="white" />
  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: { SvgBase },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconTruck extends Vue {}
</script>
