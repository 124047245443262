
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { TOptions } from 'smartbarcode-web-core/src/utils/types/index'

@Options({
  name: 'InputRadio',
})
export default class InputRadio extends Vue {
  @Prop({ type: Object }) readonly selections!: TOptions
  @Prop({ type: String }) readonly name!: string
  @Prop({ type: String }) readonly fieldName?: string = ''
}
