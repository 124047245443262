
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import SvgBase from './SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
})
export default class IconTrackingCount extends Vue {
  @Prop({ type: String }) readonly color?: string = 'white'
}
