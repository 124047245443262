import { getAddressBook, getProfileResponse, getUserTrackingCount, logout } from '@/utils/api'
import { DATE_TIME, DEFAULT_ADDRESS } from '@/utils/constants'
import { IAddress, IOrganization, IUser, TTrackingDataCount } from 'smartbarcode-web-core/src/utils/types/index'
import { EUserType } from 'smartbarcode-web-core/src/utils/enums/index'
import {
  CLEAR_PROFILE,
  FETCH_PRODUCTS,
  FETCH_PROFILE,
  FETCH_TRACKING_COUNT,
  LOGOUT,
  OPEN_DIALOG,
  SAVE_ADDRESS,
  SAVE_PROFILE,
  SAVE_TRACKING_COUNT,
} from './actions'

import { getCurrentUtcOffset } from '@/utils/helpers'
import { format } from 'date-fns'

type TProfileState = {
  user: IUser | null
  organization: IOrganization | null
  addressBook: Array<IAddress>
  addressHistory: Array<IAddress>
  enterprise: string
  getProfileOnInit: boolean
  userTrackingDataHistory: TTrackingDataCount[]
}
export default {
  state: {
    user: null,
    addressBook: [],
    addressHistory: [],
    enterprise: '',
    getProfileOnInit: true,
    userTrackingDataHistory: [],
  },
  getters: {
    isAuth: (state: TProfileState) => {
      return !!state.user
    },
    userType: (state: TProfileState) => {
      return state.user?.userType
    },
    isClientUser: (state: TProfileState) => {
      return state.user?.userType === EUserType.CLIENT
    },
    userOrganization: (state: TProfileState) => {
      return state.user?.organizationId || ''
    },
    enterpriseId: (state: TProfileState) => {
      return state.organization?.enterpriseId
    },
    alreadyGetProfileOnInit: (state: TProfileState) => {
      return !state.getProfileOnInit
    },
  },
  mutations: {
    [SAVE_PROFILE]: (
      state: TProfileState,
      {
        user,
        organization,
      }: {
        user: IUser
        organization: IOrganization
      }
    ) => {
      state.user = user
      state.organization = organization
      state.getProfileOnInit = false
    },
    [SAVE_ADDRESS]: (
      state: TProfileState,
      {
        addressBook,
        addressHistory,
      }: {
        addressBook: Array<IAddress>
        addressHistory: Array<IAddress>
      }
    ) => {
      if (addressBook) {
        state.addressBook = addressBook
      }

      if (addressHistory) {
        state.addressHistory = addressHistory
      }
    },
    [SAVE_TRACKING_COUNT]: (state: TProfileState, { data }: { data: TTrackingDataCount[] }) => {
      state.userTrackingDataHistory = data || []
    },
    [CLEAR_PROFILE]: (state: TProfileState) => {
      state.user = null
      state.userTrackingDataHistory = []
      state.addressBook = []
      state.addressHistory = []
      state.enterprise = ''
    },
  },
  actions: {
    [FETCH_PROFILE]: async ({
      commit,
      dispatch,
      state,
      rootGetters,
    }: {
      commit: Function
      dispatch: Function
      state: TProfileState
      rootGetters: { projectOrganization: string }
    }) => {
      try {
        if (!state.user) {
          const profileRes = await getProfileResponse()
          const enterpriseId = profileRes.headers['enterprise-id']
          const { user, organization } = profileRes?.data
          commit(SAVE_PROFILE, { user, organization: { ...organization, enterpriseId } })
        }

        // Check if user belongs to the enterprise of project
        if (rootGetters.projectOrganization && state.organization?.enterpriseId !== rootGetters.projectOrganization) {
          throw new Error('Not belong to Enterprise')
        }

        const addressBookResponse = await getAddressBook()
        if (addressBookResponse) {
          const { addressBook, addressHistory } = addressBookResponse
          const convertAddress = (addressList: Array<object> = []) =>
            addressList.map((a) => ({ ...DEFAULT_ADDRESS, ...a }))
          commit(SAVE_ADDRESS, {
            addressBook: convertAddress(addressBook),
            addressHistory: convertAddress(addressHistory),
          })
        }

        if (state.user?.userType === EUserType.CLIENT) {
          dispatch(FETCH_PRODUCTS)
        }
      } catch (error) {
        const ex = error as Error
        if (ex.message === 'Not belong to Enterprise') {
          dispatch(OPEN_DIALOG, {
            message: ex.message,
            needtranslation: true,
          })
          dispatch(LOGOUT)
        } else {
          commit(CLEAR_PROFILE)
        }
      }
    },
    [LOGOUT]: async ({ commit }: { commit: Function }) => {
      await logout()
      commit(CLEAR_PROFILE)
    },
    [FETCH_TRACKING_COUNT]: async (
      { commit }: { commit: Function },
      payload: {
        date: string
        projectId: string
        utcOffset: string
      }
    ) => {
      const currentDate = new Date()
      const date = payload.date || format(currentDate, DATE_TIME.WITHOUT_SECOND).toString()
      const utcOffset = getCurrentUtcOffset()
      const data = await getUserTrackingCount(date, payload.projectId, utcOffset)
      commit(SAVE_TRACKING_COUNT, data)
    },
  },
}
