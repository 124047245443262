<template>
  <div class="field-input" :class="{ error: !!error }">
    <label :for="fieldName" v-if="label" :class="isSign ? 'label-signature' : ''">
      {{ label }}
    </label>
    <component
      v-if="component"
      v-model="value"
      v-bind="filteredProps"
      :is="component"
      :name="fieldName"
      :placeholder="placeholder"
      @change="$emit('change')"
      @uploaded="onUploaded"
      @reviewCalculation="$emit('reviewCalculation', fieldName)"
    />

    <div v-else-if="isNumberMode" :class="`base-input-block ${multiLine ? 'textarea-max-length' : ''}`">
      <component
        v-model="value"
        v-bind="filteredProps"
        :is="InputNumberComponent"
        :name="fieldName"
        :placeholder="placeholder"
        @change="$emit('change')"
      />
    </div>

    <div v-else :class="`base-input-block ${multiLine ? 'textarea-max-length' : ''}`">
      <el-input v-bind="textInputPropAttrs" v-model="value" v-maska="mask" @change="$emit('change', $event)" />
      <div class="base-input-block__child">
        <InputReadersSuffix v-bind="InputReadersSuffixProps" v-model="value" @update:result="$emit('change', $event)" />
      </div>
    </div>

    <span v-show="!disableError && error" class="error-msg">{{ error }}</span>
  </div>
</template>

<script lang="ts">
import InputNumber from '@/components/InputNumber.vue'
import OCRComponent from '@/components/OCRComponent.vue'
import SignaturePad from '@/components/SignaturePad.vue'
import IconBarcodeReader from '@/components/svg/IconBarcodeReader.vue'
import IconCamera from '@/components/svg/IconCamera.vue'
import IconOCR from '@/components/svg/IconOCR.vue'
import IconSave from '@/components/svg/IconSave.vue'
import { maska } from 'maska'
import { EBCRType, ECustomFieldType, EOCRType } from 'smartbarcode-web-core/src/utils/enums/index'
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import InputImageUpload from './InputImageUpload.vue'
import InputLocation from './InputLocation.vue'
import InputReadersSuffix from './InputReadersSuffix.vue'

@Options({
  directives: { maska },
  inheritAttrs: false,
  emits: ['update:modelValue', 'change', 'uploaded', 'reviewCalculation'],
  components: {
    OCRComponent,
    IconOCR,
    IconBarcodeReader,
    IconCamera,
    IconSave,
    InputReadersSuffix,
    InputNumber,
  },
  name: 'BaseInput',
})
export default class BaseInput extends Vue {
  @Prop({ type: Function }) readonly component?: Function
  @Prop({ type: String }) readonly fieldName?: string = ''
  @Prop({ type: [String, Number, Array, Object, Date] }) readonly modelValue?: unknown
  @Prop({ type: String }) readonly label?: string
  @Prop({ type: String }) readonly placeholder?: string = ''
  @Prop({ type: Boolean }) readonly disabled?: boolean = false
  @Prop({ default: '' }) barcodeReaderType!: EBCRType
  @Prop({ default: '' }) ocrReaderType!: EOCRType
  @Prop({ default: '' }) ocrImageAutoSaveField!: ''
  @Prop({ type: String }) readonly inputType?: string = ECustomFieldType.TEXT
  @Prop({ type: Boolean, default: true }) readonly isSuggestion?: boolean = true
  @Prop({ type: String }) readonly mask?: string
  @Prop({ type: Number }) readonly minLength?: number
  @Prop({ type: Number }) readonly maxLength?: number
  @Prop({ type: Object }) readonly selections?: object
  @Prop({ type: Boolean }) readonly disableError?: boolean
  @Prop({ type: String }) readonly barcodeType?: string
  @Prop({ type: String }) readonly error?: string
  @Prop({ type: String }) readonly fileKind?: string
  @Prop({ type: String }) readonly overlayImage?: string
  @Prop({ type: Boolean }) readonly multiLine?: boolean
  @Prop({ type: String }) readonly countryCode?: string
  @Prop({ type: Boolean, default: true }) readonly isShowControlButton?: boolean

  // input props number
  @Prop({ type: Number }) readonly minValue?: number
  @Prop({ type: Number }) readonly maxValue?: number
  @Prop({ type: Number }) readonly precision?: number

  get image() {
    return this.$store.getters.customLogoImageUrl || require('@/assets/logo-smartBarcode.png')
  }

  get inputmode() {
    return this.inputType === 'decimal' ? 'decimal' : ''
  }

  get InputNumberComponent() {
    return InputNumber
  }

  get isNumberMode() {
    return this.inputmode === 'decimal'
  }

  get isSign() {
    return this.fieldName === ECustomFieldType.ESIGN
  }

  get nativeInputType() {
    if (this.multiLine) return 'textarea'
    if (this.inputType === 'decimal') return 'tel'

    return this.inputType
  }

  get InputReadersSuffixProps() {
    return {
      fieldName: this.fieldName,
      bcrType: this.barcodeReaderType,
      ocrType: this.ocrReaderType,
    }
  }

  get textInputPropAttrs() {
    return {
      fieldName: this.fieldName,
      type: this.nativeInputType,
      inputmode: this.inputmode,
      disabled: this.disabled,
      placeholder: this.placeholder,
      minlength: this.minLength,
      maxlength: this.maxLength,
      min: this.minValue,
      max: this.maxValue,
      'show-word-limit': true,
      ...(!!this.multiLine && {
        rows: Math.ceil((this.maxLength ?? 0) / 20),
      }),
    }
  }

  onUploaded(uploadedResponse: { url: string }) {
    this.$emit('uploaded', uploadedResponse)
  }

  get filteredProps() {
    const fProps = { ...this.$props }
    if (!(this.component === InputImageUpload || this.component === SignaturePad || this.component === InputLocation)) {
      delete fProps.disableError
      delete fProps.error
      delete fProps.fieldName
    }

    ;['component', 'label'].forEach((key) => delete fProps[key])
    // Exception fields that not remove 'fieldName' in properties
    return fProps
  }

  get value() {
    return this.modelValue
  }

  set value(newVal) {
    this.$emit('update:modelValue', newVal)
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/theme.scss';

.app-logo {
  flex: 1;
  text-align: center;

  img {
    height: 15px;
  }
}

.half-width {
  width: 40%;
}

.align-middle {
  vertical-align: middle;
}

.field-input {
  margin: 8px 0;

  &.error .el-input {
    border-color: $error;
  }

  .error-msg {
    color: $error;
    line-height: 1.2;
  }
}

.base-input-block {
  position: relative;

  &__child {
    position: absolute;
    bottom: 0%;
    right: 10%;
  }
}

.label-signature {
  width: 240px;
  margin: auto;
}
</style>
