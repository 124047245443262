<template>
  <el-collapse accordion>
    <div class="barcode-children" v-for="(notificationBarcode) in notifications" :key="notificationBarcode?.barcodeId">
      <div :class="['notification-info-block']" :style="backgroundColor">
        <el-collapse-item>
          <template #title>
            <div class="p-3">
              <div class="cursor-pointer h-4 flex items-center text-gray-400 mb-3">
                {{ notificationBarcode?.barcode?.label || notificationBarcode?.barcodeId }}
              </div>
              <div class="h-8 flex items-center text-lg break-words">
                {{ notificationBarcode?.subject }}
              </div>
            </div>

          </template>
          <hr class="bg-white" />
          <div v-html="notificationBarcode?.body" class="notification-message px-4"></div>
          <div v-if="isNavigateToBarcodeEnable" class="text-right m-4 underline underline-offset-4"
            @click="goToDetail(notificationBarcode?.barcodeId)">
            >>> {{ $t('go_to_this_barcode') }}</div>
        </el-collapse-item>
      </div>

    </div>
  </el-collapse>
</template>

<script lang="ts">
import { INotification } from 'smartbarcode-web-core/src/utils/types/index'
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
@Options({
  components: {},
  name: 'NotificationListItems',
})
export default class AppNotificationDialog extends Vue {
  @Prop({ type: Object }) readonly notifications?: INotification[]
  @Prop({ type: Boolean }) readonly isNavigateToBarcodeEnable?: boolean
  @Prop({ type: String }) readonly backgroundColor?: string

  get projectParam() {
    return this.$store.getters.projectParam
  }

  goToDetail(barcodeId: string) {
    this.$router.push({
      name: 'detail',
      params: {
        project: this.projectParam,
        barcodeId: barcodeId,
      },
    })
  }
}
</script>
<style lang="scss" scoped>
.notification-message {
  white-space: break-spaces;
  word-break: break-word;
}

.barcode-children {
  a {
    color: unset;
    text-decoration: none;
  }
}

.el-collapse {
  border: 0px;
}

.notification-info-block :deep() {
  // background-color: #e0e0e0;
  border-radius: 10px;
  margin-top: 15px;
  margin-bottom: 15px;
  padding-top: 18px;
  border: 1px solid #fff;

  .el-collapse-item {
    margin-bottom: 20px;
  }

  .el-collapse-item__header,
  .el-collapse-item__wrap {
    border-bottom: none;
    background-color: inherit;
  }

}
</style>
