import { addLocationTracking, fetchLocationsByProjectIdAndTrackpoint } from '@/utils/api'
import { EStockOperationType } from 'smartbarcode-web-core/src/utils/enums/index'
import { IInventoryLocation } from 'smartbarcode-web-core/src/utils/types/index'
import isEmpty from 'lodash/isEmpty'
import { FETCH_LOCATIONS, INSTOCK_LOCATION, OUTSTOCK_LOCATION } from './actions'

interface ILocationState {
  locations: IInventoryLocation[]
}

export default {
  state: {
    locations: [] as IInventoryLocation[],
  },
  getters: {},
  mutations: {
    [FETCH_LOCATIONS]: (state: ILocationState, locations?: IInventoryLocation[]) => {
      state.locations = locations ?? []
    },
  },
  actions: {
    [FETCH_LOCATIONS]: async (
      {
        commit,
        state,
      }: {
        commit: Function
        state: ILocationState
      },
      {
        projectId,
        barcodeId,
        trackPointKey,
        isNew,
      }: {
        projectId: string
        barcodeId: string
        trackPointKey: string
        isNew?: boolean
      }
    ) => {
      if (isEmpty(state.locations) || isNew) {
        const locations = await fetchLocationsByProjectIdAndTrackpoint({
          projectId,
          barcodeId,
          trackPointKey,
        })
        commit(FETCH_LOCATIONS, locations)
      }
    },
    [INSTOCK_LOCATION]: async (
      { state }: { state: ILocationState },
      {
        barcodeIds,
        locationId,
      }: {
        barcodeIds: string[]
        locationId: string
      }
    ) => {
      await addLocationTracking({
        type: EStockOperationType.INSTOCK,
        barcodeIds,
        locationId,
      })
    },
    [OUTSTOCK_LOCATION]: async (
      { state }: { state: ILocationState },
      {
        barcodeIds,
      }: {
        barcodeIds: string[]
      }
    ) => {
      await addLocationTracking({
        type: EStockOperationType.OUTSTOCK,
        barcodeIds,
      })
    },
  },
}
