<template>
  <SvgBase iconName="scanQR" width="34" height="34">
    <path d="M2 17H32" :stroke="color" stroke-width="3.42" />
    <path d="M2 10.7337V2H10.7337" :stroke="color" stroke-width="3.42" />
    <path d="M23.2661 2H31.9998V10.7337" :stroke="color" stroke-width="3.42" />
    <path d="M2 23.2666V32.0003H10.7337" :stroke="color" stroke-width="3.42" />
    <path
      d="M23.2661 32.0003H31.9998V23.2666"
      :stroke="color"
      stroke-width="3.42"
    />
  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconScanQR extends Vue {}
</script>
