<template>
  <SvgBase iconName="person" width="29" height="29">
    <g clip-path="url(#clip0_12092_4305)">
      <path
        d="M14.5035 14.2291C18.4871 14.2291 21.7297 11.0366 21.7297 7.11457C21.7297 3.19253 18.4871 0 14.5035 0C10.5199 0 7.27734 3.19253 7.27734 7.11457C7.27734 11.0366 10.5199 14.2291 14.5035 14.2291ZM14.5035 2.88917C16.8658 2.88917 18.7952 4.78157 18.7952 7.11457C18.7952 9.44757 16.8731 11.34 14.5035 11.34C12.1339 11.34 10.2118 9.44757 10.2118 7.11457C10.2118 4.78157 12.1339 2.88917 14.5035 2.88917Z"
        fill="white"
      />
      <path
        d="M22.4928 15.5H6.51455C2.92714 15.5 0 18.3747 0 21.9139V28.9996H29V21.9139C29 18.3819 26.0802 15.5 22.4855 15.5H22.4928ZM26.0729 26.1105H2.93448V21.9139C2.93448 19.971 4.54111 18.3892 6.51455 18.3892H22.4928C24.4662 18.3892 26.0729 19.971 26.0729 21.9139V26.1105Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_12092_4305">
        <rect width="29" height="29" fill="white" />
      </clipPath>
    </defs>
  </SvgBase>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: { SvgBase },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconPerson extends Vue {}
</script>
