import { getNotification } from '@/utils/api'
import { IBarcode, INotification, INotificationsResponse } from 'smartbarcode-web-core/src/utils/types/index'
import {
  FETCH_NOTIFICATION,
  SAVE_NOTIFICATION,
  CLEAR_NOTIFICATION,
  OPEN_NOTIFICATION_DIALOG,
  CLOSE_NOTIFICATION_DIALOG,
} from './actions'
import BarcodeModule from './barcode'

interface INotificationState {
  notifications: INotification[]
  dialogVisible: boolean
  error: boolean
  errorMsg: string | number
}

export default {
  state: {
    notifications: [],
    dialogVisible: false,
    error: false,
  },
  getters: {
    notifications: (state: INotificationState) => {
      return state.notifications
    },
  },
  mutations: {
    [SAVE_NOTIFICATION]: (state: INotificationState, payload?: INotification[]) => {
      if (payload) {
        state.notifications = [...payload]
        state.error = false
      } else {
        state.notifications = []
        state.error = true
      }
    },
    [CLEAR_NOTIFICATION]: (state: INotificationState) => {
      state.notifications = []
      state.dialogVisible = false
      state.error = false
    },
    [OPEN_NOTIFICATION_DIALOG]: (state: INotificationState) => {
      state.dialogVisible = true
    },
    [CLOSE_NOTIFICATION_DIALOG]: (state: INotificationState) => {
      state.dialogVisible = false
    },
  },
  actions: {
    [FETCH_NOTIFICATION]: async (
      {
        commit,
        state,
      }: {
        commit: Function
        state: INotificationState
      },
      data: {
        barcodeId: string
      }
    ) => {
      try {
        const currentBarcode = (BarcodeModule.state.barcode as unknown) as IBarcode
        if (!currentBarcode?.isDeactivated && state.notifications?.length > 0) {
          commit(OPEN_NOTIFICATION_DIALOG)
        }
        const result: INotificationsResponse = await getNotification(data.barcodeId)
        const currentDate = Date.parse(new Date().toUTCString())
        const filteredResult =
          result?.notifications?.filter((val) => Date.parse(val.displayedDateTime) <= currentDate) || []
        if (filteredResult.length > 0) {
          commit(SAVE_NOTIFICATION, filteredResult)
          if (!currentBarcode?.isDeactivated) {
            commit(OPEN_NOTIFICATION_DIALOG)
          }
        }
      } catch (error) {}
    },
  },
}
