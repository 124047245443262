
import { getProject } from '@/utils/api'
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Options({
  name: 'NotFound',
})
export default class NotFound extends Vue {
  @Prop({ type: String }) readonly item: string = 'item'
  customURL = ''

  async created() {
    if (!this.$store.state.project.details) {
      const fullPath = this.$route.fullPath
      const projectCode = fullPath.split('/')[1].split('@')[0]
      const { project } = await getProject(projectCode)
      this.customURL = project?.customLogoImageUrl
    }
  }

  get image() {
    return this.customURL || require('@/assets/logo-smartBarcode.png')
  }
}
