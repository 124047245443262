<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="viewBox"
    :aria-labelledby="iconName"
    fill="none"
  >
    <slot />
  </svg>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'

@Options({
  name: 'svgBase',
  props: {
    iconName: {
      type: String,
      default: 'icon',
    },
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 18,
    },
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class SvgBase extends Vue {
  get viewBox() {
    return `0 0 ${this.width} ${this.height}`
  }
}
</script>
