<template>
  <SvgBase
    width="36"
    height="36"
    viewBox="0 0 37 36"
    fill="none"
    :style="transform"
  >
    <path
      d="M17.14 3L14.7 6.16L27.22 15.85H0V19.85H27.22L14.7 29.54L17.14 32.7L36.33 17.85L17.14 3Z"
      :fill="color"
    />
  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
    // Rotation number in degrees
    rotation: {
      type: Number,
      default: 0,
    },
  },
})
export default class IconArrow extends Vue {
  get transform() {
    return `transform: rotate(${this.rotation}deg)`
  }
}
</script>
