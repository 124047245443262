
import { Vue, Options } from 'vue-class-component'

@Options({
  components: {},
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconVaccine extends Vue {}
