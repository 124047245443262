<template>
<SvgBase width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M7.82239 12H22.8309" :stroke="color" stroke-width="3"/>
  <path d="M15.2165 16.1775V22H2V2H15.2165V7.82249" :stroke="color" stroke-width="3"/>
  <path d="M18.6534 7.56787L23.0853 11.9998L18.6534 16.4316" :stroke="color" stroke-width="3"/>
</SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconLogout extends Vue {}
</script>
