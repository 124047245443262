import { getProducts, getProject, getProjectById } from '@/utils/api'
import { EBCRType, EUserType } from 'smartbarcode-web-core/src/utils/enums/index'
import { IProduct, IProject } from 'smartbarcode-web-core/src/utils/types/index'
import { FETCH_PRODUCTS, FETCH_PROJECT, FETCH_PROJECT_BY_ID, INIT_DONE, SAVE_PRODUCTS, SAVE_PROJECT } from './actions'
import { initScanditConfigure } from '@/utils/barcodeReader'

export interface TProjectState {
  barcodeReaderLicenseKey: string
  initialized: boolean
  details: IProject
  enterprise: string
  products: Array<IProduct>
}

function getInitialState() {
  return {
    initialized: false,
    barcodeReaderLicenseKey: '',
    details: {},
    enterprise: '',
    products: [],
  }
}

export default {
  state: getInitialState(),
  getters: {
    projectVersion: (state: TProjectState) => {
      return state?.details?.version || ''
    },
    customLogoImageUrl: (state: TProjectState) => {
      return state?.details?.customLogoImageUrl || ''
    },
    projectParam: (state: TProjectState, getters: { projectVersion: string }) => {
      if (state?.details) {
        return `${state.details.code}@${getters.projectVersion}`
      }
      return ''
    },
    projectCode: (state: TProjectState) => {
      return state.details?.code || ''
    },
    isProjectSupportScandit: (state: TProjectState) => {
      return state.details?.barcodeReaderType === EBCRType.SCANDIT || false
    },
    projectName: (state: TProjectState) => {
      return state.details?.name || ''
    },

    projectOrganization: (state: TProjectState) => {
      return state.details?.enterpriseId || ''
    },

    projectEndTrackpointKey: (state: TProjectState) => {
      return state.details?.endTrackPointKey || ''
    },

    barcodeReaderLicenseKey: (state: TProjectState) => {
      return state.barcodeReaderLicenseKey || ''
    },
  },
  mutations: {
    [SAVE_PROJECT]: (
      state: TProjectState,
      payload?: {
        enterpriseName: string
        project: IProject
        barcodeReaderLicenseKey: string
      }
    ) => {
      if (payload) {
        state.enterprise = payload.enterpriseName
        state.details = payload.project
        state.barcodeReaderLicenseKey = payload?.barcodeReaderLicenseKey || ''
      } else {
        Object.assign(state, getInitialState())
      }
    },
    [SAVE_PRODUCTS]: (state: TProjectState, payload?: Array<IProduct>) => {
      if (payload) {
        state.products = payload
      } else {
        state.products = []
      }
    },
    [INIT_DONE]: (state: TProjectState) => {
      state.initialized = true
    },
  },
  actions: {
    [FETCH_PROJECT]: async (
      {
        commit,
        state,
        dispatch,
        rootGetters,
      }: {
        commit: Function
        dispatch: Function
        state: TProjectState
        rootGetters: {
          isAuth: boolean
          userType?: string
          userOrganization: string
        }
      },
      projectCode: string
    ) => {
      try {
        const [code, version] = projectCode.split('@')
        const response: {
          enterpriseName: string
          project: IProject
          barcodeReaderLicenseKey: string
        } = await getProject(code, version)
        commit(SAVE_PROJECT, response)

        if (rootGetters.userType === EUserType.CLIENT) {
          dispatch(FETCH_PRODUCTS)
        }
        if (response.project.barcodeReaderType === EBCRType.SCANDIT) {
          initScanditConfigure()
        }
      } catch (error) {
        commit(SAVE_PROJECT)
      } finally {
        if (!state.initialized) {
          commit(INIT_DONE)
        }
      }
    },
    [FETCH_PROJECT_BY_ID]: async (
      {
        commit,
        state,
        dispatch,
        rootGetters,
      }: {
        commit: Function
        dispatch: Function
        state: TProjectState
        rootGetters: {
          isAuth: boolean
          userType?: string
          userOrganization: string
        }
      },
      projectId: string
    ) => {
      try {
        const response = await getProjectById(projectId)
        commit(SAVE_PROJECT, response)

        if (rootGetters.userType === EUserType.CLIENT) {
          dispatch(FETCH_PRODUCTS)
        }
      } catch (error) {
        commit(SAVE_PROJECT)
      } finally {
        if (!state.initialized) {
          commit(INIT_DONE)
        }
      }
    },
    [FETCH_PRODUCTS]: async ({ commit }: { commit: Function }) => {
      try {
        const products = await getProducts()
        commit(SAVE_PRODUCTS, products)
      } catch (error) {
        console.error(error)
        commit(SAVE_PRODUCTS)
      }
    },
  },
}
