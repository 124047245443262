<template>
  <div class="field-input" :class="{ error: !!error }">
    <div>
      <div class="d-column my-2">
        <span class="label">{{ $t('ghgEmission.calcType.label') }}</span>
        <span class="bold px-4">{{ $t(`ghgEmission.calcType.${calculationType}`) }}</span>
      </div>
      <span class="label">{{ $t('ghgEmission.transportType.label') }}</span>
      <el-select class="selection" @change="onSelectChange" :model-value="currentTransportType">
        <el-option v-for="(transportType, index) in transportTypes" :key="index" :value="transportType"
          :label="$t(`ghgEmission.transportType.${transportType}`)">
          {{ $t(`ghgEmission.transportType.${transportType}`) }}
        </el-option>
      </el-select>
    </div>

    <div v-if="shouldShowInputField('vehicleUsageType')" class="d-column">
      <span class="label">{{ $t('ghgEmission.vehicleUsageType.label') }}</span>
      <el-radio-group v-model="vehicleUsageType" class="px-4 my-2" @update:modelValue="updateInputValue">
        <el-radio v-for="(vehicleUsageType, index) in vehicleUsageTypes" :key="index" :value=vehicleUsageType
          :label=vehicleUsageType>
          {{ $t(`ghgEmission.vehicleUsageType.${vehicleUsageType}`) }}
        </el-radio>
      </el-radio-group>
    </div>

    <div v-if="shouldShowInputField('weight')" class="d-column">
      <span class="label">{{ $t('ghgEmission.weight') }}</span>
      <div class="inline-flex">
        <InputNumber v-model="weight" :precision=3 placeholder="0.0" :minValue=0 :maxValue=9999
          @update:modelValue="updateInputValue" />
        <span class="unit">{{ $t('ghgEmission.weightUnit') }}</span>
      </div>
    </div>

    <div v-if="shouldShowInputField('distance')" class="d-column">
      <span class="label">{{ $t('ghgEmission.distance') }}</span>
      <div class="inline-flex">
        <InputNumber v-model="distance" :precision=3 placeholder="0.0" :minValue=0 :maxValue=9999
          @update:modelValue="updateInputValue" />
        <span class="unit">
          {{ $t('ghgEmission.distanceUnit') }}
        </span>
      </div>
    </div>

    <div v-if="shouldShowInputField('maxWeightCapacity')" class="d-column">
      <span class="label">{{ $t('ghgEmission.maxWeightCapacity') }}</span>
      <div class="inline-flex">
        <InputNumber v-model="maxWeightCapacity" :precision=3 placeholder="0.0" :minValue=0 :maxValue=9999
          @update:modelValue="updateInputValue" />
        <span class="unit">
          {{ $t('ghgEmission.maxWeightCapacityUnit') }}
        </span>
      </div>
    </div>

    <div v-if="shouldShowInputField('loadRate')" class="d-column">
      <span class="label">{{ $t('ghgEmission.loadRate') }}</span>
      <div class="inline-flex">
        <div v-if="isEnabledLoadRateAutoCalc" class="load-rate-display">
          {{ loadRateDisplay }}
        </div>
        <InputNumber v-else v-model="loadRateDisplay" :precision=3 placeholder="0.0" :minValue=0 :maxValue=9999
          @update:modelValue="updateLoadRateDisplay" :disabled="isEnabledLoadRateAutoCalc" />
        <span class="unit">
          {{ $t('ghgEmission.loadRateUnit') }}
        </span>
      </div>
      <el-checkbox v-model="isEnabledLoadRateAutoCalc" :checked=isEnabledLoadRateAutoCalc
        :label="$t('ghgEmission.loadRateAutoCalcToggle')" @update:model-value="changeLoadRateAutoCalc" border />
    </div>

    <div v-if="shouldShowInputField('fuelConsumption')" class="d-column">
      <span class="label">{{ $t('ghgEmission.fuelConsumption') }}</span>
      <div class="inline-flex">
        <InputNumber v-model="fuelConsumption" :precision=3 placeholder="0.0" :minValue=0 :maxValue=9999
          @update:modelValue="updateInputValue" />
        <span class="unit">
          {{ $t('ghgEmission.fuelConsumptionUnit') }}
        </span>
      </div>
    </div>

    <span v-show="!disableError && error" class="error-msg">{{ error }}</span>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { Prop, PropSync } from 'vue-property-decorator'
import InputNumber from './InputNumber.vue'
import { FuelEfficiencyTransportType, FuelTransportType, ModifiedTonkiloTransportType, TonkiloTransportType, TransportType } from 'smartbarcode-web-core/src/utils/ghgEmission/TransportType'
import { GhgEmissionInput } from 'smartbarcode-web-core/src/utils/ghgEmission/GhgEmissionInput'
import { GhgEmissionCalculationType } from 'smartbarcode-web-core/src/utils/ghgEmission/GhgEmissionCalculationType'
import { GhgEmissionRequiredInputMap } from 'smartbarcode-web-core/src/utils/ghgEmission/GhgEmissionRequiredInputMap'
import { ECustomFieldType } from 'smartbarcode-web-core/src/utils/enums'
import { ICustomFieldData } from 'smartbarcode-web-core/src/utils/types'
import { VehicleUsageType } from 'smartbarcode-web-core/src/utils/ghgEmission/GhgEmissionCalculationMaterial'

@Options({
  emits: ['update:modelValue'],
  components: {
    InputNumber,
  },
})
export default class InputGhgEmission extends Vue {
  @PropSync('modelValue', { type: Object }) ghgEmissionInput?: GhgEmissionInput

  @Prop({ type: Boolean }) readonly disableError?: boolean
  @Prop({ type: String }) readonly error?: string

  currentTransportType = ''
  weight = 0
  distance = 0
  loadRate = 0
  loadRateDisplay = 0
  maxWeightCapacity = 0
  vehicleUsageType = ''
  fuelConsumption = 0
  isProcessedLoadRateAutoCalc = false
  isEnabledLoadRateAutoCalc = localStorage.getItem('ghgEmissionInputLoadRateAutoCalc') === null
    ? true
    : localStorage.getItem('ghgEmissionInputLoadRateAutoCalc')?.toLowerCase() === 'true'

  get transportTypes(): TransportType[] {
    switch (this.calculationType) {
      case 'tonkilo':
        return [
          'railway',
          'aviation-international',
          'aviation-domestic',
          'container-ship-domestic',
          'container-ship-asia-route',
          'container-ship-european-route',
          'container-ship-north-american-route',
        ] as TonkiloTransportType[]
      case 'modifiedTonkilo':
        return [
          'gasoline-light-cargo',
          'gasoline-lt-2t',
          'gasoline-gte-2t',
          'light-oil-lt-1t',
          'light-oil-gte-1t-lt-2t',
          'light-oil-gte-2t-lt-4t',
          'light-oil-gte-4t-lt-6t',
          'light-oil-gte-6t-lt-8t',
          'light-oil-gte-8t-lt-10t',
          'light-oil-gte-10t-lt-12t',
          'light-oil-gte-12t-lt-17t',
        ] as ModifiedTonkiloTransportType[]
      case 'fuelEfficiency':
        return [
          'gasoline-light-cargo',
          'gasoline-lt-2t',
          'gasoline-gte-2t',
          'light-oil-lt-1t',
          'light-oil-gte-1t-lt-2t',
          'light-oil-gte-2t-lt-4t',
          'light-oil-gte-4t-lt-6t',
          'light-oil-gte-6t-lt-8t',
          'light-oil-gte-8t-lt-10t',
          'light-oil-gte-10t-lt-12t',
          'light-oil-gte-12t-lt-17t',
        ] as FuelEfficiencyTransportType[]
      case 'fuel':
        return [
          'gasoline',
          'light-oil',
          'heavy-oil-a',
          'heavy-oil-b-c',
          'liquefied-petroleum-gas',
          'jet-fuel-oil',
          'city-gas',
        ] as FuelTransportType[]
    }
  }

  get vehicleUsageTypes() {
    return ['business', 'private'] as VehicleUsageType[]
  }

  get calculationType(): GhgEmissionCalculationType {
    const currentTrackPointKey = this.$store.getters.currentTrackingPoint
    const trackPointForms = this.$store.state.project.details.trackPoints[currentTrackPointKey]?.trackPointForms

    return Object.values<Record<string, ICustomFieldData>>(trackPointForms).map(
      (form: Record<string, ICustomFieldData>) => {
        // Only the first ghg emission field in the barcode is supported.
        return Object.values(form.customFields).find(
          (customField: ICustomFieldData) => customField.fieldType === ECustomFieldType.GHG_EMISSION.toString()
        ).calculationType
      }
    )[0]
  }

  shouldShowInputField(fieldName: string) {
    const inputFields = GhgEmissionRequiredInputMap.get(this.calculationType)
    if (!inputFields) {
      throw new Error(`The calculation type "${this.calculationType}" is not supported`)
    }
    return inputFields.includes(fieldName)
  }

  updateInputValue() {
    if (this.isEnabledLoadRateAutoCalc) {
      this.updateLoadRate()
    }
    this.emitValue()
  }

  changeLoadRateAutoCalc() {
    localStorage.setItem('ghgEmissionInputLoadRateAutoCalc', this.isEnabledLoadRateAutoCalc.toString())
    if (this.isEnabledLoadRateAutoCalc) {
      this.updateInputValue()
    }
  }

  updateLoadRateDisplay() {
    this.loadRateDisplay = parseFloat(this.loadRateDisplay.toFixed(2))
    this.loadRate = this.loadRateDisplay / 100
    this.loadRate = parseFloat(this.loadRate.toFixed(4))
    this.isProcessedLoadRateAutoCalc = false
    this.updateInputValue()
  }

  updateLoadRate() {
    this.isProcessedLoadRateAutoCalc = false
    if (this.weight > 0 && this.maxWeightCapacity > 0) {
      this.loadRate = this.weight / this.maxWeightCapacity
      this.loadRate = parseFloat(this.loadRate.toFixed(4))
      this.loadRateDisplay = this.loadRate * 100
      this.loadRateDisplay = parseFloat(this.loadRateDisplay.toFixed(2))
      this.isProcessedLoadRateAutoCalc = true
    } else if (this.weight <= 0 || this.maxWeightCapacity <= 0) {
      this.loadRate = 0
      this.loadRateDisplay = 0
    }
  }

  emitValue() {
    const ghgEmissionInput = {
      transportType: this.currentTransportType as TransportType,
      weight: Number(this.weight),
      distance: Number(this.distance),
      loadRate: Number(this.loadRate),
      maxWeightCapacity: Number(this.maxWeightCapacity),
      vehicleUsageType: this.vehicleUsageType as VehicleUsageType,
      fuelConsumption: Number(this.fuelConsumption),
    } as GhgEmissionInput
    this.$emit('update:modelValue', ghgEmissionInput)
  }

  mounted() {
    this.emitValue()
  }

  onSelectChange(newValue: string) {
    this.currentTransportType = newValue
    this.emitValue()
  }
}
</script>
<style lang="scss" scoped>
@import '~@/assets/css/theme.scss';

.field-input {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-evenly;

  &.error .el-input {
    border-color: $error;
  }

  .error-msg {
    color: $error;
    line-height: 1.2;
  }
}

.label {
  margin-top: 5px;
  margin-bottom: 5px;
}

.selection {
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 8px;
}

.unit {
  margin-left: 5px;
  margin-right: 10px;
}

.d-column {
  display: flex;
  flex-direction: column;
}

.inline-flex {
  display: inline-flex;
  align-items: baseline;
}

.load-rate-display {
  display: inline-flex;
  height: 40px;
  align-items: center;
  padding-left: 20px;
  font-size: large;
}

.auto-calc-notice {
  color: rosybrown;
  font-size: smaller;
}
</style>
