<template>
  <SvgBase iconName="IconTrackingCount" width="24" height="23">
    <g clip-path="url(#clip0_10169_7941)">
      <path
        d="M4.38961 7.68156C6.20663 7.68156 7.67961 6.20858 7.67961 4.39156C7.67961 2.57455 6.20663 1.10156 4.38961 1.10156C2.57259 1.10156 1.09961 2.57455 1.09961 4.39156C1.09961 6.20858 2.57259 7.68156 4.38961 7.68156Z"
        stroke="black"
        stroke-width="2.2"
      />
      <path
        d="M4.38961 21.4589C6.20663 21.4589 7.67961 19.9859 7.67961 18.1689C7.67961 16.3519 6.20663 14.8789 4.38961 14.8789C2.57259 14.8789 1.09961 16.3519 1.09961 18.1689C1.09961 19.9859 2.57259 21.4589 4.38961 21.4589Z"
        stroke="black"
        stroke-width="2.2"
      />
      <path
        d="M19.0498 7.68156C20.8668 7.68156 22.3398 6.20858 22.3398 4.39156C22.3398 2.57455 20.8668 1.10156 19.0498 1.10156C17.2327 1.10156 15.7598 2.57455 15.7598 4.39156C15.7598 6.20858 17.2327 7.68156 19.0498 7.68156Z"
        stroke="black"
        stroke-width="2.2"
      />
      <path
        d="M19.0498 21.4589C20.8668 21.4589 22.3398 19.9859 22.3398 18.1689C22.3398 16.3519 20.8668 14.8789 19.0498 14.8789C17.2327 14.8789 15.7598 16.3519 15.7598 18.1689C15.7598 19.9859 17.2327 21.4589 19.0498 21.4589Z"
        stroke="black"
        stroke-width="2.2"
      />
      <path d="M4.38965 7.67969V14.8797" stroke="black" stroke-width="2.2" />
      <path d="M19.0498 7.67969V14.8797" stroke="black" stroke-width="2.2" />
      <path d="M16.74 6.33984L7 16.0798" stroke="black" stroke-width="2.2" />
    </g>
    <defs>
      <clipPath id="clip0_10169_7941">
        <rect width="23.44" height="22.56" fill="white" />
      </clipPath>
    </defs>
  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import SvgBase from './SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
})
export default class IconTrackingCount extends Vue {
  @Prop({ type: String }) readonly color?: string = 'white'
}
</script>
