<template>
  <div class="notfound-view display-in-center view">
    <img alt="SmartBarcode logo" :src="image" class="app-logo" />
    <h1>404</h1>
    <h2>{{ $t('Not Found', { item: $t(item) }) }}</h2>
  </div>
</template>

<script lang="ts">
import { getProject } from '@/utils/api'
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Options({
  name: 'NotFound',
})
export default class NotFound extends Vue {
  @Prop({ type: String }) readonly item: string = 'item'
  customURL = ''

  async created() {
    if (!this.$store.state.project.details) {
      const fullPath = this.$route.fullPath
      const projectCode = fullPath.split('/')[1].split('@')[0]
      const { project } = await getProject(projectCode)
      this.customURL = project?.customLogoImageUrl
    }
  }

  get image() {
    return this.customURL || require('@/assets/logo-smartBarcode.png')
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/mixins.scss';

h1 {
  font-size: 42px;
  margin-bottom: 16px;
}

.notfound-view {
  width: 240px;
}

.app-logo {
  padding: 24px 0;
  width: 100%;
}
</style>
