<template>
  <SvgBase iconName="warehouse" width="29" height="28">
    <path
      d="M14.5 0L0 7.58969V28H29V7.58969L14.5 0ZM12.119 25.0237V19.2123H16.881V25.0237H12.119ZM26.0513 25.0237H19.8297V16.236H9.17031V25.0237H2.94865V9.40526L14.5 3.35583L26.0513 9.40526V25.0311V25.0237Z"
      fill="white"
    />
  </SvgBase>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: { SvgBase },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconWarehouse extends Vue {}
</script>
