<template>
  <SvgBase iconName="airplane" width="37" height="24">
    <path
      d="M37 1.26733H31.0468L27.662 5.68857L24.7865 5.17732L29.7887 0H19.8966L14.3702 3.32673L10.2965 2.59946C6.89679 1.9946 3.36978 3.32673 1.31048 5.9982L0.988476 6.41584C-0.142266 7.86318 -0.314499 9.76418 0.531686 11.3771C1.37038 12.9901 3.06275 13.991 4.94233 13.991H11.3524L21.6938 24.0072H31.2714L23.7456 13.991H33.443L37 1.26733ZM22.9444 21.1197L15.9652 14.3654H20.3384L25.4155 21.1197H22.9444ZM20.7578 2.88029H22.9219L21.3568 4.56526L18.661 4.09001L20.7578 2.88029ZM31.1591 11.1107H4.93484C4.18601 11.1107 3.54201 10.7291 3.20503 10.081C2.86806 9.44014 2.93545 8.71287 3.38475 8.12961L3.70675 7.71197C4.86745 6.20702 6.73205 5.33573 8.64907 5.33573C9.016 5.33573 9.38293 5.36454 9.74986 5.42934L28.9575 8.84248L32.5594 4.14041H33.0911L31.1516 11.1035L31.1591 11.1107Z"
      fill="white"
    />
  </SvgBase>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: { SvgBase },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconAirplane extends Vue {}
</script>
