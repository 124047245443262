
import IconScan1D from '@/components/svg/IconScan1D.vue'
import IconScan2D from '@/components/svg/IconScan2D.vue'
import IconScanDefaultMini from '@/components/svg/IconScanDefaultMini.vue'
import { EBCReaderCategory } from 'smartbarcode-web-core/src/utils/enums/index'
import { Options, Vue } from 'vue-class-component'
import { PropSync } from 'vue-property-decorator'
// Mixins
@Options({
  components: {
    IconScan1D,
    IconScan2D,
    IconScanDefaultMini,
  },
  emits: ['update:modelValue', 'update:onSymbologiesChange', 'onUpdate:symbologies', 'update:symbologies'],
  name: ' BarcodeSymbologiesSelection',
})
export default class BarcodeSymbologiesSelection extends Vue {
  @PropSync('symbologies', { type: String, default: EBCReaderCategory.DEFAULT }) _symbologies!: string
  selecting = false
  containerStatus = 1 // 0: normal_smaller - 1: normal - 2: full - 3: full_bigger
  get containerStatusData() {
    return {
      normalSmaller: 0,
      normal: 1,
      full: 2,
      fullBigger: 3,
    }
  }

  get isAnimationContainerType() {
    return this._symbologies === EBCReaderCategory.DEFAULT
  }

  isShowElement(value: string) {
    return (
      this._symbologies === value &&
      (this.containerStatus === this.containerStatusData.normal ||
        this.containerStatus === this.containerStatusData.normalSmaller)
    )
  }

  isHideElement(value: string) {
    return !(
      this.containerStatus === this.containerStatusData.full ||
      this.containerStatus === this.containerStatusData.fullBigger ||
      this._symbologies === value
    )
  }

  onSymbologiesChange(newVal: string) {
    if (this.containerStatus === 2) {
      this._symbologies = newVal
      if (this._symbologies !== newVal) {
        this.$emit('update:onSymbologiesChange', newVal)
      }
      setTimeout(() => {
        if (this.isAnimationContainerType) {
          this.containerStatus = 0
          setTimeout(() => (this.containerStatus = 1), 500)
        } else {
          this.containerStatus = 1
        }
      }, 0)
    }
  }

  selectContainer() {
    if (this.isAnimationContainerType) {
      this.containerStatus = 3
      setTimeout(() => (this.containerStatus = 2), 500)
    } else {
      this.containerStatus = 2
    }
  }

  get displayBarcodeList() {
    const displayList = []
    displayList.push({ label: EBCReaderCategory.DEFAULT, component: IconScanDefaultMini })
    displayList.push({ label: EBCReaderCategory.QR, component: IconScan2D })
    displayList.push({ label: EBCReaderCategory.ONE_DIMENSION, component: IconScan1D })
    return displayList
  }

  get component() {
    switch (this._symbologies) {
      case EBCReaderCategory.QR:
        return IconScan2D
      case EBCReaderCategory.ONE_DIMENSION:
        return IconScan1D
      default:
        return IconScanDefaultMini
    }
  }
}
