
import { Options, Vue } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: { SvgBase },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconPaper extends Vue {}
