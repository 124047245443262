<template>
  <el-dialog :destroy-on-close="true" center width="90%" top="0" :lock-scroll="true" :model-value="visible"
    :show-close="true" :close-on-click-modal="false" :title="title" @closed="closeDialog">
    <div>
      <NotificationListItem :isNavigateToBarcodeEnable="false"
        :notifications="notificationsModelValue.filter((val) => val?.barcodeId === barcode?.id)"
        :backgroundColor="`background-color: ${parentBlockBackground}`" />
      <div v-if="notificationsModelValue.filter((val) => val?.barcodeId !== barcode?.id).length > 0">
        <hr />
        <span>{{ $t('notification.descendants_barcode_notification') }}</span>
        <NotificationListItem :isNavigateToBarcodeEnable="false"
          :notifications="notificationsModelValue.filter((val) => val?.barcodeId !== barcode?.id)"
          :backgroundColor="`background-color: ${descendantsBlockBackground}`" />
      </div>
    </div>
  </el-dialog>
</template>

<script lang="ts">
import { CLOSE_NOTIFICATION_DIALOG } from '@/store/actions'
import { addLabelToBarcode } from '@/utils/helpers'
import { IBarcode, IDisplayBarcodes, INotification } from 'smartbarcode-web-core/src/utils/types/index'
import { cloneDeep } from 'lodash'
import { Options, Vue } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import BarcodeLabel from '@/components/BarcodeLabel.vue'
import BarcodeInfoLabel from 'smartbarcode-web-core/src/components/BarcodeIconLabel.vue'
import NotificationListItem from '@/components/NotificationListtItems.vue'
@Options({
  components: { BarcodeLabel, BarcodeInfoLabel, NotificationListItem },
  name: 'AppNotificationDialog',
})
export default class AppNotificationDialog extends Vue {
  notificationsModelValue: INotification[] = []
  get visible() {
    return this.$store.state.notification.dialogVisible
  }

  get barcode(): IBarcode {
    return this.$store.state.barcode?.barcode
  }

  get project() {
    return this.$store.state.project?.details
  }

  get notifications(): INotification[] {
    return this.$store.state.notification.notifications || []
  }

  get title(): string {
    return this.$t('notification.alert_list')
  }

  get parentBlockBackground(): string {
    return '#56CCF233'
  }

  get descendantsBlockBackground(): string {
    return '#e0e0e0'
  }

  @Watch('notifications')
  onNotificationChanged() {
    if (!this.notifications) return
    this.notificationsModelValue = cloneDeep(this.notifications)
    const barcode = (this.notificationsModelValue.map((notification) => {
      notification.barcode = cloneDeep(this.barcode)
      return notification.barcode
    }) as IDisplayBarcodes[])
    addLabelToBarcode(barcode, this.project)
  }

  closeDialog() {
    this.$store.commit(CLOSE_NOTIFICATION_DIALOG)
  }
}
</script>
