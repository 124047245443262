
import OCRComponent from '@/components/OCRComponent.vue'
import ScanComponent from '@/components/ScanComponent.vue'
import IconBarcodeReader from '@/components/svg/IconBarcodeReader.vue'
import IconOCR from '@/components/svg/IconOCR.vue'
import { EBCRType, ECameraReaderType, EOCRType } from 'smartbarcode-web-core/src/utils/enums/index'
import isEmpty from 'lodash/isEmpty'
import { Options, Vue } from 'vue-class-component'
import { Prop, PropSync } from 'vue-property-decorator'
import ActionDialog from './common/ActionDialog.vue'
@Options({
  inheritAttrs: false,
  emits: ['update:modelValue', 'update:result'],
  components: {
    IconOCR,
    IconBarcodeReader,
    OCRComponent,
    ScanComponent,
    ActionDialog,
  },
  name: 'InputReadersSuffix',
})
export default class InputReadersSuffix extends Vue {
  @PropSync('modelValue', { type: [String, Number] }) _modelValue?: string | number
  @Prop({ type: String }) readonly fieldName!: string
  @Prop({ type: String }) readonly ocrType!: string
  @Prop({ type: String }) readonly bcrType!: string

  isShowDialog = false
  ocrAction = ECameraReaderType.OCR

  get hasOCR(): boolean {
    return !isEmpty(this.ocrType) && this.ocrType !== EOCRType.UNKNOWN
  }

  get hasBCR(): boolean {
    return !isEmpty(this.bcrType) && this.bcrType !== EBCRType.UNKNOWN
  }

  get isOCRComponent(): boolean {
    return this.ocrAction === ECameraReaderType.OCR
  }

  get scannerType(): EBCRType {
    return this.bcrType as EBCRType
  }

  openBCR() {
    this.openCameraDialog(ECameraReaderType.BCR)
  }

  openOCR() {
    this.openCameraDialog(ECameraReaderType.OCR)
  }

  confirmText(value: string) {
    this.isShowDialog = false
    this._modelValue = value
    this.$emit('update:result', this._modelValue)
  }

  openCameraDialog(action: ECameraReaderType) {
    this.isShowDialog = true
    this.ocrAction = action
  }

  onDialogClosing() {
    if (this.$refs?.ocrComponent) this.$refs.ocrComponent.vidOff()
  }
}
