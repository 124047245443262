/* eslint-disable promise/param-names */
import i18n from '@/i18n/i18n'
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import { ElMessage, ElMessageBox } from 'element-plus'
import { ECustomFieldType, EDisplaySetting, EPackageInfoKey } from 'smartbarcode-web-core/src/utils/enums/index'
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
import {
  IActivationFields,
  IBarcode,
  ICustomFieldData,
  IFieldSettings,
  IFormInputSchema,
  IMessageType,
  INotificationsResponse,
  IProject,
  ISelectionItem,
  ITrackPointKeyVal,
} from 'smartbarcode-web-core/src/utils/types/index'
import { FIXED_PACKAGE_INFO_KEYS, LOCAL_STORAGE_ITEM, QRCODE_BARCODE_TYPE_REGEX } from './constants'
import errorHandler from './errorHandler'
import { getNotification } from './api'
const t = i18n.global.t

export const observerClean = (value: unknown) => {
  if (!value) {
    return {}
  }

  return JSON.parse(JSON.stringify(value))
}

const maxMessage = 3
const messageArr: string[] = []

export function openMessage(message: string, type: IMessageType, duration = 3000) {
  if (messageArr.length === maxMessage) return message
  messageArr.push(message)
  ElMessage({
    showClose: true,
    center: true,
    message,
    duration,
    type,
    onClose: () => {
      messageArr.shift()
    },
  })
}

export function validateEmail(email: string): boolean {
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  return regex.test(email?.toLowerCase())
}

export const deepSearch = (object: object, key: string, predicate: Function): object | null => {
  const newObj = object as Record<string, object>
  if (Object.prototype.hasOwnProperty.call(object, key) && predicate(key, newObj[key]) === true) {
    return object
  }

  for (let i = 0; i < Object.keys(object).length; i++) {
    const value = newObj[Object.keys(object)[i]] as Record<string, object>
    if (typeof value === 'object' && value != null) {
      const o = deepSearch(value, key, predicate)
      if (o != null) return o
    }
  }

  return null
}

type Obj = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function searchValueByKey(obj: Obj, key: string): any {
  for (const prop in obj) {
    if (prop === key) {
      return obj[prop]
    } else if (typeof obj[prop] === 'object') {
      const result = searchValueByKey(obj[prop], key)
      if (result !== undefined) {
        return result
      }
    }
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function findValueByPath(obj: Obj, path: string): any {
  const keys = path.split('.')
  let result = obj
  for (const key of keys) {
    result = result[key]
    if (result === undefined) {
      return undefined
    }
  }
  return result
}

export const isAllObjectValueEmpty = (obj: object): boolean => {
  const temp: Record<string, unknown> = { ...obj }
  return !Object.keys(temp).find((key) => {
    return !isEmpty(temp[key])
  })
}

export const getDefaultValueOfData = (data: unknown) => {
  if (typeof data === 'number') {
    return 0
  }
  if (typeof data === 'string') {
    return ''
  }
  if (typeof data === 'boolean') {
    return false
  }
  if (typeof data === 'object') {
    if (Object.prototype.toString.call(data) === '[object Array]') {
      return []
    }
    return {}
  }
}

export const addEmptyActivationData = (activateData: object, originActivationBarcode: object): object => {
  const cloneActivationData = observerClean(activateData)
  const cloneOriginActivationBarcode = observerClean(originActivationBarcode)
  return Object.keys(activateData).reduce((acc, key) => {
    if (isEmpty(cloneActivationData?.[key])) {
      if (isEmpty(cloneOriginActivationBarcode?.[key])) {
        return acc
      }
      return { ...acc, [key]: getDefaultValueOfData(cloneOriginActivationBarcode?.[key]) }
    }
    if (!isAllObjectValueEmpty(cloneActivationData?.[key])) {
      return { ...acc, [key]: cloneActivationData?.[key] }
    }
    const data = {
      ...acc,
      [key]: addEmptyActivationData(cloneActivationData?.[key], cloneOriginActivationBarcode?.[key]),
    } as Record<string, unknown>
    if (isEmpty(data[key])) {
      delete data[key]
    }
    return data
  }, {})
}

export const isJson = (item: string) => {
  item = typeof item !== 'string' ? JSON.stringify(item) : item

  try {
    item = JSON.parse(item)
  } catch (e) {
    return false
  }

  if (typeof item === 'object' && item !== null) {
    return true
  }

  return false
}

export const filterEmptyActivateData = (activateData: object): object => {
  const cloneObj = observerClean(activateData)
  return Object.keys(activateData).reduce((acc, key) => {
    if (isEmpty(cloneObj[key])) {
      return acc
    }
    if (!isAllObjectValueEmpty(cloneObj[key])) {
      return { ...acc, [key]: cloneObj[key] }
    }
    return { ...acc, ...filterEmptyActivateData(cloneObj[key]) }
  }, {})
}

export const removeEmpty = (obj: { [x: string]: unknown }) => {
  Object.keys(obj).forEach((k) => !obj[k] && obj[k] !== undefined && delete obj[k])
  return obj
}

export const toSnakeCase = (str: string) => {
  return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)
}

export function sortTrackingPoints(trackPointArr: ITrackPointKeyVal[]) {
  trackPointArr.sort((a, b) => {
    return a.value.order && b.value.order ? a.value.order - b.value.order : Number(a.key) - Number(b.key)
  })
}

export async function getVisitorId() {
  let visitorId = ''
  visitorId = localStorage.getItem(LOCAL_STORAGE_ITEM.VISITOR_ID) || ''
  if (visitorId) {
    return visitorId
  } else {
    visitorId = (await FingerprintJS.load().then((fp) => fp.get())).visitorId
    localStorage.setItem(LOCAL_STORAGE_ITEM.VISITOR_ID, visitorId)
    return visitorId
  }
}

export async function promptVideoPermission() {
  await navigator.mediaDevices
    .getUserMedia({ video: true })
    .then(async (stream) => {
      stream.getVideoTracks().forEach((track) => track.stop())
    })
    .catch((e) => {
      errorHandler(e)
    })
}

export function dataURLtoFile(dataURI: string) {
  try {
    if (isEmpty(dataURI) && dataURI.split(',').length < 2) return

    const byteString = atob(dataURI.split(',')[1])
    const ab = new ArrayBuffer(byteString.length)
    const ia = new Uint8Array(ab)

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i)
    }
    return new Blob([ab], { type: dataURI.split(',')[0].split(';')[0] })
  } catch (error) {
    return null
  }
}

export function getFilePathFromURL(string: string) {
  if (!string) return ''
  if (string.includes('blob:')) return string

  const start = string.indexOf('Enterprise') || string.indexOf('Barcode')
  const end = string.indexOf('?')

  return string.slice(start, end)
}

export function getCurrentUtcOffset() {
  const date = new Date()
  return date.getTimezoneOffset() / -60
}

export function makeid(len: number) {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  let counter = 0
  while (counter < len) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
    counter += 1
  }
  return result
}

interface IResizeImageOptions {
  maxSize: number
  file: File
}
export function resizeImage({ maxSize, file }: IResizeImageOptions) {
  const image = new Image()
  const canvas = document.createElement('canvas')
  const dataURItoBlob = (dataURI: string) => {
    const bytes =
      dataURI.split(',')[0].indexOf('base64') >= 0 ? atob(dataURI.split(',')[1]) : unescape(dataURI.split(',')[1])
    const mime = dataURI
      .split(',')[0]
      .split(':')[1]
      .split(';')[0]
    const max = bytes.length
    const ia = new Uint8Array(max)
    for (let i = 0; i < max; i++) ia[i] = bytes.charCodeAt(i)
    return new Blob([ia], { type: mime })
  }
  const resize = () => {
    let width = image.width
    let height = image.height

    if (width > height) {
      if (width > maxSize) {
        height *= maxSize / width
        width = maxSize
      }
    } else {
      if (height > maxSize) {
        width *= maxSize / height
        height = maxSize
      }
    }
    if (canvas) {
      canvas.width = width
      canvas.height = height
      // eslint-disable-next-line no-unused-expressions
      canvas?.getContext('2d')?.drawImage(image, 0, 0, width, height)
      const dataUrl = canvas.toDataURL('image/jpeg')
      return dataURItoBlob(dataUrl)
    }
  }

  return new Promise((ok, no) => {
    image.onload = () => ok(resize())

    if (!file.type.match(/image.*/)) {
      no(new Error('Not an image'))
      return
    }

    const reader = new FileReader()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    reader.onload = (evt: any) => (image.src = evt.target.result)
    reader.readAsDataURL(file)
  })
}

export function getFirstCustomActiveField(
  customValues: Record<string, ICustomFieldData>,
  customFields: Record<string, IFormInputSchema>
) {
  let label = ''
  if (!isEmpty(customValues)) {
    Object.keys(customValues).forEach((key) => {
      const value = customValues[key]
      const fSettings = customFields[key]
      if (!fSettings) return
      let val = ''
      if (value.fieldType === ECustomFieldType.SINGLE_SELECT) {
        val = value.singleSelect?.value || ''
      } else if (value.fieldType === ECustomFieldType.MULTI_SELECT) {
        val =
          value?.multiSelect
            ?.reduce((acc, curr) => {
              acc.push(curr?.value || '')
              return acc
            }, [] as string[])
            .join(', ') || ''
      } else if (value.fieldType) {
        val = value.text || value.number?.toString() || value.date || ''
      }

      if (FIXED_PACKAGE_INFO_KEYS.includes(key)) {
        switch (key) {
          case EPackageInfoKey.TRACKING_NUMBER:
            label = `${fSettings.label || t('tracking_number')}: ${value}`
            break
          case EPackageInfoKey.EXTERNAL_ID:
            label = `${t('externalId')}: ${value}`
            break
          default:
            label = ''
            break
        }
      }
      if (val) {
        label = `${fSettings.label}: ${val}`
      }
    })
  }
  return label
}

export function getValueFromCustomFields(
  flattenCustomValue: Record<string, ICustomFieldData>,
  flattenCustomFields: Record<string, IFormInputSchema>,
  path: string
): string {
  const splitedKey = path.split('.')
  const lastKey = splitedKey[splitedKey.length - 1]
  const fieldType = flattenCustomFields?.[lastKey]?.fieldType || ''

  if (!fieldType) {
    return ''
  }

  if (fieldType === ECustomFieldType.TEXT) {
    return `${flattenCustomFields[lastKey].label}: ${flattenCustomValue?.[lastKey]?.[ECustomFieldType.TEXT]}`
  }
  if (fieldType === ECustomFieldType.NUMBER) {
    return `${flattenCustomFields[lastKey].label}: ${flattenCustomValue?.[lastKey]?.[
      ECustomFieldType.NUMBER
    ]?.toLocaleString()}`
  }
  if (fieldType === ECustomFieldType.PHONE_NUMBER) {
    return `${flattenCustomFields[lastKey].label}: ${
      flattenCustomValue?.[lastKey]?.[ECustomFieldType.PHONE_NUMBER]?.number
    }`
  }
  if (fieldType === ECustomFieldType.DATE) {
    return `${flattenCustomFields[lastKey].label}: ${flattenCustomValue?.[lastKey]?.[ECustomFieldType.DATE]}`
  }
  if (fieldType === ECustomFieldType.EMAIL) {
    return `${flattenCustomFields[lastKey].label}: ${flattenCustomValue?.[lastKey]?.[ECustomFieldType.EMAIL]}`
  }

  if (fieldType === ECustomFieldType.REFERENCE) {
    const value =
      flattenCustomValue?.[lastKey]?.[ECustomFieldType.REFERENCE]?.text ||
      flattenCustomValue?.[lastKey]?.[ECustomFieldType.REFERENCE]?.template
    return `${flattenCustomFields[lastKey].label}: ${value}`
  }
  if (fieldType === ECustomFieldType.SINGLE_SELECT) {
    const selectionItem = (flattenCustomValue?.[lastKey]?.[ECustomFieldType.SINGLE_SELECT] as unknown) as ISelectionItem
    if (selectionItem?.value) {
      return `${flattenCustomFields[lastKey].label}: ${selectionItem.value}`
    }
  }
  if (fieldType === ECustomFieldType.MULTI_SELECT) {
    const multiSelect = (flattenCustomValue?.[lastKey]?.[ECustomFieldType.MULTI_SELECT] as unknown) as ISelectionItem[]
    if (multiSelect) {
      const valueOfMultiSelect = multiSelect
        .reduce((acc, curr) => {
          acc.push(curr.value)
          return acc
        }, [] as string[])
        .join(',')
      return `${flattenCustomFields[lastKey].label}: ${valueOfMultiSelect}`
    }
  }

  if (fieldType === ECustomFieldType.CALCULATION) {
    return `${flattenCustomFields[lastKey].label}: ${flattenCustomValue[fieldType]?.calculation ?? ''}`
  }

  if (flattenCustomValue[fieldType]) {
    return `${flattenCustomFields[lastKey].label}: ${flattenCustomValue[fieldType]}`
  }
  return ''
}

export function flattenCustomFields(obj: Record<string, Record<string, unknown>>): Record<string, unknown> {
  const flattenObj = Object.keys(obj || {}).reduce((acc, cur) => {
    // exclude nextTrackPointNotification now
    if (cur === 'nextTrackPointNotification') return acc

    if (cur.match(/^custom[a-zA-Z]*Fields/g)) return { ...acc, ...obj[cur] }

    if (cur === ECustomFieldType.LOCATION) {
      return { ...acc, [cur]: { ...obj[cur], fieldType: ECustomFieldType.LOCATION } }
    }

    if (cur === ECustomFieldType.ESIGN) {
      return { ...acc, [cur]: { ...obj[cur], fieldType: ECustomFieldType.ESIGN } }
    }

    return { ...acc, [cur]: obj[cur] }
  }, {})
  return flattenObj
}

export function getDynamicLabel(
  flattenFields: Record<string, IFieldSettings>,
  flattenValue: Record<string, ICustomFieldData>,
  pathKey: string
) {
  const processedpathKey = pathKey.replace(/^activationData\./, '')
  let label = ''
  const fixedPackageInfo = [
    {
      value: 'activationData.trackingNumber',
      title: ` ${flattenFields?.trackingNumber?.label || t('tracking_number')}`,
    },
    {
      value: 'activationData.externalId',
      title: ` ${t('externalId')}`,
    },
    {
      value: 'activationData.dimension.height',
      title: `${t('height')}`,
    },
    {
      value: 'activationData.dimension.width',
      title: `${t('width')}`,
    },
    {
      value: 'activationData.dimension.depth',
      title: `${t('depth')}`,
    },
    {
      value: 'activationData.dimension.weight',
      title: `${t('weight')}`,
    },
  ]

  // Search label in fixed fields
  fixedPackageInfo.forEach((element) => {
    if (element.value === pathKey) {
      const title = element?.title || ''

      const value = findValueByPath(flattenValue, processedpathKey) || ''
      label = `${title}: ${value}`
    }
  })

  return (
    label || // Stop If found label in fixed package info.
    getValueFromCustomFields(flattenValue, flattenFields, pathKey) ||
    getFirstCustomActiveField(flattenValue, flattenFields) ||
    ''
  )
}

export function isHideVisibilityConfig(visibility: string, isAuth: boolean, defaultVisibility = true): boolean {
  if (EDisplaySetting.VISIBLE === visibility) return false

  // If the value is unknown then will following the defaultVisibility logic.
  // If defaultVisibility = true it will determ as not hidden
  return visibility === EDisplaySetting.UNKNOWN
    ? !defaultVisibility
    : visibility === EDisplaySetting.HIDDEN || (visibility === EDisplaySetting.VISIBLE_WHEN_LOGGED_IN && !isAuth)
}

export function mobileCheck() {
  const toMatch = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i]

  return toMatch.some((toMatchItem) => navigator.userAgent.match(toMatchItem))
}

export function getMobileOS() {
  const ua = navigator.userAgent
  if (/android/i.test(ua)) {
    return 'Android'
  } else if (/iPad|iPhone|iPod/.test(ua) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) {
    return 'iOS'
  }
  return 'Other'
}

export function isFirefoxOrOperaAgent() {
  const ua = navigator.userAgent
  const isOpera = ua.indexOf('OPR') !== -1
  const isFirefox = ua.indexOf('Firefox') !== -1 && ua.indexOf('OPR') === -1
  return isOpera || isFirefox
}

function getBarcodeListConfigFromType(bc: IBarcode, project: IProject) {
  return `${project?.barcodeTypes?.[bc?.barcodeType]?.uiConfig?.listViewTitleLabelField}` || ''
}

function barcodeTypeInfoActivationFields(barcodeType: string, project: IProject): IActivationFields {
  return project?.barcodeTypes?.[barcodeType]?.activationFields || {}
}

export function addLabelToBarcode(barcodes: IBarcode[], project: IProject) {
  barcodes.forEach((val) => {
    if (!val) return
    const flattenValue = flattenCustomFields(
      (val?.activationData as unknown) as Record<string, Record<string, unknown>>
    ) as Record<string, ICustomFieldData>
    const flattenField = flattenCustomFields(
      (barcodeTypeInfoActivationFields(val?.barcodeType || '', project) as unknown) as Record<
        string,
        Record<string, unknown>
      >
    ) as Record<string, IFieldSettings>
    const label = getDynamicLabel(flattenField, flattenValue, getBarcodeListConfigFromType(val, project))
    Object.assign(val, {
      label,
    })
  })
}

export function getProjectCode(project: string) {
  return project.split('@')[0]
}

export const isQrCode = (plainText: string) => !!plainText.match(QRCODE_BARCODE_TYPE_REGEX)

export const getBarcodeIdFromQrCode = (plainText: string) => plainText.split('/').pop()
export const shouldHandleAsNewBarcode = (
  plainText: string,
  previousScannedText: string,
  previousScannedBarcodeId: string
) => {
  if (!plainText) {
    return false
  }
  if (isQrCode(plainText)) {
    const barcodeId = getBarcodeIdFromQrCode(plainText) || ''
    if (barcodeId === previousScannedBarcodeId) {
      return false
    }
  }
  if (previousScannedText) {
    return plainText !== previousScannedText
  }
  return true
}

export const displayTargetAlert = async (resultScanned: IBarcode) => {
  const notifications: INotificationsResponse = await getNotification(resultScanned.id)
  const currentDate = Date.parse(new Date().toUTCString())
  const filteredResult =
    notifications?.notifications?.filter((val) => Date.parse(val.displayedDateTime) <= currentDate) || []
  if (filteredResult.length > 0) {
    const message = filteredResult.map((val) => val.body).join('\n--------------------------------\n')
    await ElMessageBox.confirm(message, '', {
      confirmButtonText: t('ok'),
      confirmButtonClass: 'danger',
      cancelButtonText: t('skip'),
    }).catch((e: Error) => {
      throw e
    })
  }
}
