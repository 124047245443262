import store from '@/store'
import { CLEAR_PROFILE } from '@/store/actions'
import axios, { AxiosRequestConfig } from 'axios'
import isEmpty from 'lodash/isEmpty'
import { EStockOperationType } from 'smartbarcode-web-core/src/utils/enums/index'
import {
  IActivateRequestBody,
  IBarcode,
  IBarcodeSearchForm,
  IPairRequestBody,
  IRegisterInfo,
  ITrackingRequestBody,
  IUnpairRequest,
  LoginPayload,
} from 'smartbarcode-web-core/src/utils/types/index'
import { LOCAL_STORAGE_ITEM } from './constants'
import { ELinkType } from './enums'
import { getCurrentUtcOffset, getVisitorId, removeEmpty } from './helpers'

export const baseUrl = `${process.env.VUE_APP_API_HOST}`

const axiosInstance = axios.create({
  baseURL: baseUrl,
  timeout: 60000,
  withCredentials: true,
})

const PROFILE_URL = '/user/profile'
const REFRESH_TOKEN_URL = '/user/refresh-token'
export async function refreshToken() {
  const payload = { visitorId: await getVisitorId() }
  return await axiosInstance.post(REFRESH_TOKEN_URL, payload)
}

interface IProcess {
  resolve: Function
  reject: Function
}
let isRefreshing = false
let failedQueue = [] as IProcess[]
const processQueue = (error: Error | null, isRefreshDone = false) => {
  failedQueue.forEach((prom) => {
    if (error) prom.reject(error)
    else prom.resolve(isRefreshDone)
  })

  failedQueue = []
}

axiosInstance.interceptors.request.use((config) => {
  config.headers!['UTC-Offset'] = getCurrentUtcOffset()
  return config
})

axiosInstance.interceptors.request.use(
  (config) => {
    const isRefreshTokenRequest = config.url === REFRESH_TOKEN_URL
    if (isRefreshing && !isRefreshTokenRequest) {
      return new Promise((resolve, reject) => failedQueue.push({ resolve, reject }))
        .then(() => config)
        .catch((err) => Promise.reject(err))
    }
    isRefreshing = config.url === PROFILE_URL

    return config
  },
  (error) => Promise.reject(error)
)

export async function execRefreshingProcess(originalRequest: AxiosRequestConfig) {
  try {
    isRefreshing = true
    await refreshToken()
    if (!isEmpty(originalRequest.data)) {
      originalRequest.data = JSON.parse(originalRequest.data)
    }

    return axiosInstance(originalRequest)
  } catch (err) {
    store.commit(CLEAR_PROFILE)
  } finally {
    isRefreshing = false
    processQueue(null, true)
  }
}

axiosInstance.interceptors.response.use(
  (response) => {
    if (store.getters.isAuth && response.config.url !== REFRESH_TOKEN_URL && isEmpty(response.headers['user-type'])) {
      execRefreshingProcess(response.config)
    }
    if (response.config.url === PROFILE_URL) {
      isRefreshing = false
      processQueue(null, true)
    }
    return response
  },
  async (error) => {
    const errors = error.response?.data?.errors
    switch (error.response?.status) {
      case 400:
        if (errors?.$common) {
          throw errors.$common[0]
        } else {
          throw errors || error.response?.data
        }
      case 401:
        return execRefreshingProcess(error.config)

      case 403:
        throw new Error('403')

      case 500:
        throw new Error('500')

      default:
        throw new Error(error)
    }
  }
)

export async function login(payload: LoginPayload) {
  payload.visitorId = await getVisitorId()
  await axiosInstance.post('/user/authorize', payload)
}

export async function logout() {
  const payload = { visitorId: await getVisitorId() }
  localStorage.removeItem(LOCAL_STORAGE_ITEM.VISITOR_ID)
  await axiosInstance.post('/user/logout', payload)
}

export async function getProfileResponse() {
  const response = await axiosInstance.get('/user/profile')
  return response
}

export async function getProfile() {
  return (await getProfileResponse())?.data
}

export async function getAddressBook() {
  const response = await axiosInstance.get('/user/address')
  return response?.data
}

export async function getProject(code: string, version?: string) {
  const response = await axiosInstance.get('/barcode/project', {
    params: {
      code,
      ...(version && { version }),
    },
  })
  return response?.data
}

export async function getProjectById(id: string) {
  const response = await axiosInstance.get('/barcode/project', {
    params: {
      id,
    },
  })
  return response?.data
}

export async function getProducts() {
  const response = await axiosInstance.get('/barcode/products')
  return response?.data
}

export async function generateBarcode(projectCode: string, projectVersion: string) {
  const response = await axiosInstance.post('/barcode/barcode', {
    projectCode,
    projectVersion,
  })
  return response?.data
}

export async function getBarcodeByTNorExtID(params: {
  projectCode: string
  externalId?: string
  trackingNumber?: string
}) {
  const response = await axiosInstance.get('/barcode/barcode', { params: { ...params } })
  return response?.data
}

export async function getBarcode(id: string, externalId?: string, trackingNumber?: string) {
  const params = id.includes('=')
    ? removeEmpty({
        projectCode: id.split('=')[1],
        externalId,
        trackingNumber,
      })
    : removeEmpty({ id })

  const response = await axiosInstance.get('/barcode/barcode', {
    params: { ...params },
  })
  return response?.data
}

export async function getNotification(id: string) {
  const response = await axiosInstance.get('/barcode/barcode-notifications', {
    params: { id },
  })
  return response?.data
}

export async function getBarcodeByPath(id: string, path: string) {
  const response = await axiosInstance.get('/barcode/linked-barcode', { params: { id, path } })
  return response?.data
}

export async function activateBarcode(barcodeData: IActivateRequestBody) {
  const response = await axiosInstance.post('/barcode/activation', barcodeData)
  return response?.data
}

export async function updateBarcode(barcodeData: Partial<IActivateRequestBody>) {
  const response = await axiosInstance.put('/barcode/activation', barcodeData)
  return response?.data
}

export async function pairBarcode(request: IPairRequestBody) {
  const response = await axiosInstance.post('/barcode/pairing', request)
  return response?.data
}
export async function unpairBarcode(request: IUnpairRequest) {
  const response = await axiosInstance.post('/barcode/unpairing', request)
  return response?.data
}

export async function getChildren(id: string) {
  const response = await axiosInstance.get('/barcode/children', {
    params: {
      id,
    },
  })
  return response?.data
}

export async function getRecycleHistory(id: string) {
  const response = await axiosInstance.get('/barcode/history', {
    params: {
      id,
    },
  })
  return response?.data
}

export async function tracking(trackingData: ITrackingRequestBody) {
  const response = await axiosInstance.post('/barcode/tracking', trackingData)
  return response?.data
}

export async function undoTracking(barcodeId: string) {
  const response = await axiosInstance.post('/barcode/undo-tracking', {
    barcodeId: barcodeId,
  })
  return response?.data
}

export async function uploadImage(payload: unknown) {
  const response = await axiosInstance.post('/barcode/file', payload, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
  return response?.data
}

export async function removeImage(filePath: string) {
  const response = await axiosInstance.delete('/barcode/file', { data: { filePath } })
  return response?.data
}

export async function recycleBarcode(barcodeID: string) {
  const response = await axiosInstance.post('/barcode/recycle', { barcodeId: barcodeID })
  return response
}

export async function getChildrenValue(id: string, key: string) {
  const response = await axiosInstance.get('/barcode/children/value', {
    params: { id, key },
  })
  return response?.data
}

export async function register(registerInfo: IRegisterInfo) {
  registerInfo.visitorId = await getVisitorId()
  const response = await axiosInstance.post('/user/carrier-user/self', registerInfo)
  return response
}

interface LinkingBCPayload {
  isDryRun?: boolean
  barcodeId: string
  targetBarcodeIds: string[]
}
export async function linkingBarcodes(type: ELinkType, data: LinkingBCPayload) {
  const mappingNewType = type === ELinkType.LINK_TO ? 'linking' : type

  const response = await axiosInstance.post(`/barcode/${mappingNewType}`, data)
  return response
}

export async function undolinkingBarcode(barcodeId: string) {
  const response = await axiosInstance.post('/barcode/undo-linking', {
    barcodeId: barcodeId,
  })
  return response
}

export async function reconcileBarcode(barcodeId: string, index: number) {
  const response = await axiosInstance.post('barcode/ledger/reconcile', {
    barcodeId,
    index,
  })
  return response?.data
}

export async function getBarcodeLedgerStatus(id: string, index: number) {
  const response = await axiosInstance.get('/barcode/ledger/status', {
    params: { id, index },
  })
  return response?.data
}

export async function getEstimateBarcodeChildren(barcodeId: string) {
  const response = await axiosInstance.get('/barcode/pairing/reserved-count', {
    params: { barcodeId },
  })
  return response?.data
}

export async function getEstimateBarcodeLinked(barcodeId: string) {
  const response = await axiosInstance.get('/barcode/linking/reserved-count', {
    params: { barcodeId },
  })
  return response?.data
}

export async function compileMessage(barcodeId: string, template: string) {
  return (await axiosInstance.post('/barcode/compile-message', { barcodeId, template }))?.data
}

export async function getUserTrackingCount(date: string, projectId: string, utcOffset: number) {
  return await axiosInstance.get('/barcode/tracking-count', {
    params: {
      date,
      projectId,
      utcOffset,
    },
  })
}

export async function customRequest(payload: AxiosRequestConfig<unknown>) {
  return (await axiosInstance.request(payload)).data
}

export async function getBarcodeList(data: { condition: IBarcodeSearchForm }) {
  const response = await axiosInstance.post('/barcode/search', data)
  return response?.data
}

export async function updateReferenceFieldBarcode(barcodeId: string) {
  const response = await axiosInstance.get('/barcode/reference-data', {
    params: { barcodeId },
  })
  return response?.data
}

// ==== LOCATION ====
interface ILocationListParams {
  projectId: string
  barcodeId: string
  trackPointKey: string
}
export async function fetchLocationsByProjectIdAndTrackpoint(params: ILocationListParams) {
  const response = await axiosInstance.get('/location/available-locations', { params: { ...params } })
  return response?.data
}

interface ILocationTracking {
  locationId?: string
  type: EStockOperationType
  barcodeIds: string[]
}
export async function addLocationTracking(locationTrackingPayload: ILocationTracking) {
  const response = await axiosInstance.post('/location/location-tracking', locationTrackingPayload)
  return response?.data
}

// ==== Review calculation ====
interface IReviewCalculationPayload {
  field: string
  startTrackPointKey?: string
  endTrackPointKey?: string
  customFieldKey: string
  barcode: IBarcode
}
export async function reviewCalculation(reviewPayload: IReviewCalculationPayload) {
  const response = await axiosInstance.post('/barcode/review-calculation', reviewPayload)
  return response?.data
}
