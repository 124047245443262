
import mitt from '@/utils/mitt'
import SignaturePad from 'signature_pad'
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

import IconEdit from '@/components/svg/IconEdit.vue'

@Options({
  components: {
    IconEdit,
  },
  inheritAttrs: false,
  emits: ['change'],
  name: 'ESignature',
})
export default class ESignature extends Vue {
  @Prop({ type: String }) readonly fieldName!: string
  @Prop({ type: String }) readonly error!: string
  @Prop({ type: [String] })
  readonly modelValue!: string

  dialogVisible = false
  dataUrl = ''
  signPad: SignaturePad | null = null

  initSignaturePad() {
    if (!this.signPad) {
      this.signPad = new SignaturePad(this.$refs.canvas)
      if (this.dataUrl) {
        this.signPad.fromDataURL(this.dataUrl, {
          width: 300,
          height: 240,
        })
      }
      this.signPad.on()
    }
  }

  clearSignPad() {
    if (this.signPad) {
      this.signPad.clear()
      this.dataUrl = ''
      mitt.emit('update:trackInfo', {
        [this.fieldName]: '',
      })
    }
  }

  onCloseDialog() {
    if (this.signPad && !this.signPad.isEmpty()) {
      this.dataUrl = this.signPad.toDataURL()
      this.$emit('change')
      this.dialogVisible = false
      this.$nextTick(() => {
        mitt.emit('update:trackInfo', {
          [this.fieldName]: this.dataUrl,
        })
      })
    }
  }

  mounted() {
    this.dialogVisible = false
    this.dataUrl = this.modelValue || ''
  }

  unmounted() {
    if (this.signPad) {
      this.signPad.off()
    }
  }
}
