
import IconSearch from '@/components/svg/IconSearch.vue'
import { Options, Vue } from 'vue-class-component'
@Options({
  components: { IconSearch },
  name: 'BarcodeSearchButton',
})
export default class BarcodeSearchButton extends Vue {
  redirectToBarcodeSearchPage() {
    this.$router.push({ name: 'barcodeSearchPage' })
  }
}
