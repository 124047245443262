<template>
  <div class="header" v-if="showHeader">
    <div class="back-button">
      <el-button type="primary" circle class="is-small" @click="goHome" v-if="showHomeButton">
        <IconHome />
      </el-button>
    </div>
    <div class="app-logo">
      <img alt="SmartBarcode logo" :src="image" v-show="showLogo" />
    </div>
    <el-button v-if="isAuth" circle class="logout-button" @click="showLogoutAlert = true">
      <IconLogout />
    </el-button>
    <div v-else class="logout-button-placeholder"></div>
  </div>
  <el-dialog
    width="90%"
    top="0"
    :model-value="showLogoutAlert"
    :close-on-click-modal="false"
    :lock-scroll="true"
    @closed="showLogoutAlert = false"
  >
    <div>{{ $t('Are you sure to logout') }}</div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="showLogoutAlert = false">
          {{ $t('cancel') }}
        </el-button>
        <el-button type="primary" @click="logout">
          {{ $t('logout') }}
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'

import IconLogout from '@/components/svg/IconLogout.vue'
import IconArrow from '@/components/svg/IconArrow.vue'
import IconHome from '@/components/svg/IconHome.vue'
import { LOGOUT } from '@/store/actions'
import errorHandler from '@/utils/errorHandler'
import { TError } from 'smartbarcode-web-core/src/utils/types/index'

@Options({
  components: {
    IconLogout,
    IconArrow,
    IconHome,
  },
  name: 'AppHeader',
})
export default class AppHeader extends Vue {
  showLogoutAlert = false

  get showHeader() {
    return this.$route.meta.header
  }

  get image() {
    return this.$store.getters.customLogoImageUrl || require('@/assets/logo-smartBarcode.png')
  }

  get isAuth() {
    return this.$store.state.profile.user !== null
  }

  get showLogo() {
    return !!this.$route.meta.header?.logo
  }

  get showHomeButton() {
    return this.$store.getters.projectParam && !!this.$route.meta.header?.homeButtton
  }

  async logout() {
    try {
      await this.$store.dispatch(LOGOUT)
      this.showLogoutAlert = false
      this.goHome()
    } catch (error) {
      errorHandler(error as TError)
    }
  }

  goHome() {
    this.$router.push({
      name: 'home',
      params: { project: this.$store.getters.projectParam },
    })
  }
}
</script>
<style lang="scss" scoped>
@import '~@/assets/css/mixins.scss';

.header {
  padding: 12px;
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  position: fixed;
  background-color: $body-bg;
  z-index: 100;

  .app-logo {
    flex: 1;
    text-align: center;
    img {
      height: 15px;
    }
  }
}

.back-button {
  width: 40px;
  svg {
    width: 20px;
    height: 20px;
  }
}

.logout-button-placeholder {
  width: 40px;
  border: 0;
  background: none;
  svg {
    width: 20px;
    height: 20px;
  }
}

.el-button {
  &.is-circle {
    padding: 8px;
  }
  &.logout-button {
    width: 40px;
    border: 0;
    background: none;
    svg {
      width: 20px;
      height: 20px;
    }
  }
}
</style>
