<template>
  <el-dialog
    @close="onDialogClosing"
    :width="'100%'"
    :fullscreen="true"
    :destroy-on-close="true"
    :lock-scroll="true"
    :close-on-click-modal="false"
    v-model="syncedModel"
    custom-class="dialog-ocr"
  >
    <template #title>
      <div class="flex justify-center items-center">
        <div class="label-round">{{ label }}</div>
      </div>
    </template>
    <component :is="component.item" v-bind="component.props" @update:confirm="confirmText" />
  </el-dialog>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { Prop, PropSync } from 'vue-property-decorator'
import OCRComponent from '@/components/OCRComponent.vue'
import ScanComponent from '@/components/ScanComponent.vue'
import { ECameraReaderType, EOCRType, EBCRType } from 'smartbarcode-web-core/src/utils/enums/index'
@Options({
  name: 'ActionDialog',
  emits: ['update:isShowActionDialog', 'update:confirm'],
  components: {
    OCRComponent,
    ScanComponent,
  },
})
export default class ActionDialog extends Vue {
  @Prop({ type: String }) readonly label?: string
  @PropSync('isShowActionDialog', { type: Boolean }) syncedModel?: boolean
  @Prop({ default: ECameraReaderType.BCR }) actionType!: ECameraReaderType
  @Prop({ default: EOCRType.DEFAULT }) ocrReaderType!: EOCRType
  @Prop({ type: String }) readonly fieldName!: string
  @Prop({ default: EBCRType.DEFAULT }) customBarcodeReaderType!: EBCRType // This will override barcodeReaderType from mixins

  onDialogClosing() {
    this.syncedModel = false
    // if (this.$refs?.ocrComponent) this.$refs.ocrComponent.vidOff()
  }

  confirmText(text: string) {
    this.$emit('update:confirm', text.trim())
  }

  get component() {
    switch (this.actionType) {
      case ECameraReaderType.BCR:
        return {
          item: ScanComponent,
          props: {
            fieldName: this.fieldName,
            customBarcodECameraReaderType: this.customBarcodeReaderType,
          },
        }
      case ECameraReaderType.OCR:
        return {
          item: OCRComponent,
          props: {
            fieldName: this.fieldName,
            customBarcodECameraReaderType: this.customBarcodeReaderType,
          },
        }
      default:
        return {
          item: ScanComponent,
          props: {
            fieldName: this.fieldName,
            customBarcodECameraReaderType: this.customBarcodeReaderType,
          },
        }
    }
  }
}
</script>
