import store from '@/store'
import { OPEN_DIALOG } from '@/store/actions'
import { TError } from 'smartbarcode-web-core/src/utils/types/index'
import i18n from '@/i18n/i18n'
import { isJson } from './helpers'

export default function errorHandler(error: TError, fieldTemplate?: Record<string, string>) {
  const t = i18n.global.t
  let isCustomValidation = false
  let errorDetails
  if (typeof error === 'string') {
    if (isJson(error)) {
      const jsonObject = JSON.parse(error)
      if (jsonObject?.MessageResult) {
        isCustomValidation = true
        errorDetails = jsonObject.MessageResult
      } else {
        errorDetails = t(`errors.${error}`, { ...fieldTemplate })
      }
    } else {
      errorDetails = t(`errors.${error}`, { ...fieldTemplate })
    }
  } else if (error.message) {
    errorDetails = t(`errors.${error.message}`, { ...fieldTemplate })
  } else {
    errorDetails = Object.entries(error)
      .map(([field, value]) =>
        value
          .map((errorCode: string) => `[ERR.${errorCode}] ` + t(`errors.${errorCode}`, { field: field }))
          .join('<br />')
      )
      .join('<br />')
  }

  store.dispatch(OPEN_DIALOG, {
    message: isCustomValidation ? errorDetails : t('error template', { errors: errorDetails }),
  })
}
