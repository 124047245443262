<template>
  <SvgBase iconName="inventory" width="29" height="28">
    <path
      d="M21.2279 0V3.12976H7.77958V0H0V28H7.77958V24.5857H21.2279V28H29.0075V0H21.2279ZM21.2279 6.04794V9.30902H7.77958V6.04794H21.2279ZM21.2279 12.2272V15.4883H7.77958V12.2272H21.2279ZM4.79604 25.0818H2.98354V2.91819H4.79604V25.0818ZM7.77958 21.6675V18.4065H21.2279V21.6675H7.77958ZM26.0239 25.0818H24.2114V2.91819H26.0239V25.0818Z"
      fill="white"
    />
  </SvgBase>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: { SvgBase },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconInventory extends Vue {}
</script>
