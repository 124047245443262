<template>
  <SvgBase iconName="miniCode" width="40" height="40" fill="none">
    <path d="M16 8H8V16H16V8Z" :fill="color" />
    <path d="M24 16H16V24H24V16Z" :fill="color" />
    <path d="M32 8H24V16H32V8Z" :fill="color" />
    <path d="M16 24H8V32H16V24Z" :fill="color" />
    <path d="M32 24H24V32H32V24Z" :fill="color" />
  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconMiniCode extends Vue {}
</script>
