<template>
  <SvgBase iconName="paper" width="27" height="26">
    <path
      d="M17.5059 0H0V26H27V9.13269L17.5059 0ZM18.2868 4.68111L22.1336 8.38149L18.2073 8.458L18.2868 4.68111ZM2.89234 23.2178V2.78224H15.4306L15.2571 11.2959L24.1077 11.1289V23.2178H2.89234Z"
      fill="white"
    />
  </SvgBase>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: { SvgBase },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconPaper extends Vue {}
</script>
