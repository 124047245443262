
import { Vue, Options } from 'vue-class-component'

@Options({
  name: 'svgBase',
  props: {
    iconName: {
      type: String,
      default: 'icon',
    },
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 18,
    },
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class SvgBase extends Vue {
  get viewBox() {
    return `0 0 ${this.width} ${this.height}`
  }
}
