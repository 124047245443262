
import BarcodeTypesMixin from '@/components/mixins/BarcodeTypesMixin.vue'
import { IBarcodeDefinitionType } from 'smartbarcode-web-core/src/utils/types'
import { mixins, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Options({
  emits: ['icon:click', 'label:click', 'subLabel:click'],
  name: 'BarcodeLabel',
})
export default class BarcodeLabel extends mixins(BarcodeTypesMixin) {
  @Prop({ type: String }) readonly label?: string
  @Prop({ type: String }) readonly subLabel?: string
  @Prop({ type: String }) readonly type!: string
  @Prop({ type: Boolean }) readonly lite!: boolean
  @Prop({ type: Boolean }) readonly labelOnly?: boolean
  @Prop({ default: false }) readonly labelClickable?: boolean
  @Prop({ default: false }) readonly subLabelClickable?: boolean
  @Prop({ default: false }) readonly iconClickable?: boolean
  @Prop({ default: true }) readonly marginBottom?: boolean
  @Prop({ default: true }) readonly marginTop?: boolean
  @Prop({ default: {} }) readonly barcodeTypeDefinition?: IBarcodeDefinitionType

  get actionSlotAvailable() {
    return !!this.$slots['action-menu']
  }

  get barcodeIcon() {
    return (
      this.barcodeIconComponents[this.barcodeTypeDefinition?.icon || ''] ||
      this.barcodeIconComponents[this.currentBarcodeInfo?.icon]
    )
  }

  get barcodeTypeLabel() {
    return this.barcodeTypeInfo?.name
  }

  get project() {
    return this.$store.state.project.details
  }

  get barcodeTypeInfo(): IBarcodeDefinitionType {
    return this.project?.barcodeTypes[this.type] || {}
  }

  get currentBarcodeInfo(): IBarcodeDefinitionType {
    return this.getBarcodeTypeInfoByType(this.type) || this.barcodeTypeDefinition || {}
  }
}
