<template>
  <SvgBase iconName="package" width="28" height="26">
    <path
      d="M0 0V26H28V0H0ZM11.7011 2.78224H16.2989V8.21455H11.7011V2.78224ZM25.153 23.2178H2.84698V2.78224H8.85409V10.9968H19.1459V2.78224H25.153V23.2178Z"
      fill="white"
    />
  </SvgBase>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: { SvgBase },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconPackage extends Vue {}
</script>
