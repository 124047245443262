<template>
  <SvgBase iconName="truck" width="43" height="33">
    <path d="M13.3 14.1H9.30005V25.4H13.3V14.1Z" fill="white" />
    <path d="M23.3999 14.1H19.3999V25.4H23.3999V14.1Z" fill="white" />
    <path d="M33.6001 14.1H29.6001V25.4H33.6001V14.1Z" fill="white" />
    <path
      d="M35.7 0H7.1L0 7.8V32.6H42.9V7.8L35.7 0ZM8.9 4H34L36.4 6.6H6.5L8.9 4ZM4 28.7V10.7H38.9V28.7H4Z"
      fill="white"
    />
  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: { SvgBase },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconContainer extends Vue {}
</script>
