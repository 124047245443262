<template>
  <SvgBase iconName="Image" width="31" height="35" fill="none">
    <g clip-path="url(#clip0_8293_10043)">
      <path
        d="M1.5 23.62V4.14C1.5 3.43983 1.77814 2.76833 2.27324 2.27324C2.76833 1.77814 3.43983 1.5 4.14 1.5H26.78C27.4811 1.5 28.1536 1.77782 28.6503 2.27263C29.147 2.76744 29.4274 3.43891 29.43 4.14V30.51C29.4274 31.2111 29.147 31.8826 28.6503 32.3774C28.1536 32.8722 27.4811 33.15 26.78 33.15H11C10.5496 33.1489 10.118 32.9691 9.8 32.65L2 24.82C1.68093 24.502 1.5011 24.0704 1.5 23.62V23.62Z"
        stroke="#F2F2F2"
        stroke-width="3.3"
        stroke-miterlimit="10"
      />
      <path
        d="M1.42999 23.75H9.74999C10.0542 23.7526 10.3452 23.8746 10.5603 24.0897C10.7754 24.3048 10.8974 24.5958 10.9 24.9V33.19"
        stroke="#F2F2F2"
        stroke-width="3.3"
        stroke-miterlimit="10"
      />
      <path d="M6.71997 9.71094H24.19" stroke="#F2F2F2" stroke-width="3.3" stroke-miterlimit="10" />
      <path d="M6.71997 17.3008H24.19" stroke="#F2F2F2" stroke-width="3.3" stroke-miterlimit="10" />
    </g>
    <defs>
      <clipPath id="clip0_8293_10043">
        <rect width="30.93" height="34.65" :fill="color" />
      </clipPath>
    </defs>
  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconImage extends Vue {}
</script>
