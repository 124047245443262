<template>
  <SvgBase iconName="Image" width="32" height="32" fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M28 0H3.5C1.575 0 0 1.575 0 3.5V28C0 29.925 1.575 31.5 3.5 31.5H28C29.925 31.5 31.5 29.925 31.5 28V3.5C31.5 1.575 29.925 0 28 0ZM28 3.5V28H3.5V3.5H28ZM14.245 22.2774L19.495 15.5049L26.25 24.4999H5.25L10.5 17.7449L14.245 22.2774Z"
      :fill="color"
    />
  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconImage extends Vue {}
</script>
