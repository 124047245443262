
import { KeyenceModule, isKeyenceEnterpriseBrowser, ConfigId, isBulkReadableHT } from '@/utils/keyenceHT/keyenceModules'
import { Vue } from 'vue-class-component'
import { LOCAL_STORAGE_ITEM } from '@/utils/constants'
import { Prop } from 'vue-property-decorator'

export default class ScanerConfig extends Vue {
  @Prop({ type: String }) scannerConfigName?: string = 'ScannerConfig'
  @Prop({ type: Boolean }) shouldBulkMode?: boolean = false
  aimerTarget = 'DISABLE'
  storageKey = `${LOCAL_STORAGE_ITEM.KEYENCE_SCANNER_CONFIG_TRIGGER_MODE}-on-${this.scannerConfigName || ''}`
  options = [
    { label: 'keyenceModule.trigger.aimerTarget_disable', value: 'DISABLE' },
    { label: 'keyenceModule.trigger.aimerTarget_center', value: 'CENTER' },
    { label: 'keyenceModule.trigger.aimerTarget_horizontal', value: 'HORIZONTAL_LINE' },
  ] as LabelOption[]

  get isEnterpriseBrowser() {
    return isKeyenceEnterpriseBrowser()
  }

  async mounted() {
    this.loadSelectionFromLocalStorage()
    this.setOption()
  }

  onOptionChange() {
    this.setOption()
  }

  setOption() {
    const currentConfig = KeyenceModule.Scanner.getConfig(ConfigId.ScanParams) as {}
    const aimerTargetOption = {
      ...currentConfig,
      ...{
        target: {
          aimerTarget: this.aimerTarget,
          decodeArea: 'NORMAL',
          scanningFormat: '',
        },
        trigger: {
          continuousMode: {
            redundancyTimeout: 2000,
            successCodesCounter: {
              count: 2,
              enable: false,
            },

          },
          scannerRendencyTimeout: 3,
          scannerTimeout: 25,
          triggerMode: this.shouldBulkMode && isBulkReadableHT
            ? 'CONTINUOUS'
            : 'NORMAL',
        },
      },
    }
    KeyenceModule.Scanner.setConfig(ConfigId.ScanParams, aimerTargetOption)
    this.saveSelectionToLocalStorage()
  }

  saveSelectionToLocalStorage() {
    localStorage.setItem(this.storageKey, this.aimerTarget)
  }

  loadSelectionFromLocalStorage() {
    const savedAimerTarget = localStorage.getItem(this.storageKey)
    this.aimerTarget = savedAimerTarget || this.options[0].value
  }
}

type LabelOption = {
  label: string
  value: string
}
