import '@/assets/css/tailwind.scss'
import { Vue as VueIntegration } from '@sentry/integrations'
import { Integrations } from '@sentry/tracing'
import * as Sentry from '@sentry/vue'
import ElementPlus from 'element-plus'
import 'element-plus/lib/theme-chalk/index.css'
import 'reset-css'
import { createApp } from 'vue'
import LeaderLine from 'vue3-leaderline'
import i18n from '@/i18n/i18n'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './views/router-hooks'

Sentry.init({
  dsn: 'https://f4a6e17c54db48c69f010650e6ab35cd@o469851.ingest.sentry.io/5499855',
  release: 'smartbarcode@' + process.env.npm_package_version,
  integrations: [
    new VueIntegration({
      tracing: true,
    }),
    new Integrations.BrowserTracing(),
  ],
  environment: process.env.VUE_APP_ENV,
})

// disable auto position leader line when windows resize
LeaderLine.positionByWindowResize = false
createApp(App)
  .use(store)
  .use(router)
  .use(i18n)
  .use(ElementPlus)
  .mount('#app')
