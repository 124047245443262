
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { TOptions } from 'smartbarcode-web-core/src/utils/types/index'

@Options({
  emits: ['update:modelValue'],
  name: 'InputCheckboxes',
})
export default class InputCheckboxes extends Vue {
  @Prop({ type: Object }) readonly modelValue?: Record<string, string>
  @Prop({ type: Object }) readonly selections!: TOptions
  @Prop({ type: String }) readonly name!: string
  @Prop({ type: String }) readonly fieldName?: string = ''

  get value() {
    return this.modelValue ?? {}
  }

  set value(newVal) {
    this.$emit('update:modelValue', newVal)
  }
}
