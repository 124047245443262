<template>
  <SvgBase iconName="train" width="25" height="27">
    <path d="M9.00224 14.7656H6.38672V16.9667H9.00224V14.7656Z" fill="white" />
    <path d="M15.6501 2.42188H9.65625V4.62296H15.6501V2.42188Z" fill="white" />
    <path d="M18.6272 14.7656H16.0117V16.9667H18.6272V14.7656Z" fill="white" />
    <path
      d="M21.6725 27H25L21.5054 20.6462C22.4862 20.0886 23.1546 19.0467 23.1546 17.8361V3.59511C23.1546 1.61413 21.5562 0 19.5946 0H5.39087C3.42924 0 1.83086 1.61413 1.83086 3.59511V17.8288C1.83086 19.2228 2.71723 20.4041 3.93781 20.859L2.78262 22.6565H2.76809V22.6859L0 27H3.46556L4.36646 25.5913H20.8951L21.6725 27ZM5.03487 18.1296C4.86777 18.1296 4.737 17.9976 4.737 17.8288V13.6687H20.2412V17.8288C20.2412 17.9976 20.1104 18.1296 19.9433 18.1296H5.03487ZM5.39087 2.93478H19.5873C19.9506 2.93478 20.2412 3.22826 20.2412 3.59511V10.734H4.737V3.59511C4.737 3.22826 5.02761 2.93478 5.39087 2.93478ZM6.24818 22.6565L7.26533 21.0644H18.4031L19.2749 22.6565H6.24818Z"
      fill="white"
    />
  </SvgBase>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: { SvgBase },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconTrain extends Vue {}
</script>
