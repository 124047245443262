<template>
  <SvgBase iconName="Link" width="40" height="33">
    <path
      d="M36.2963 5.07692H30L28.5 0.96099C28.3964 0.679347 28.2065 0.435898 27.9562 0.263898C27.706 0.0918986 27.4076 -0.000264099 27.1019 5.68447e-07H12.8981C12.2731 5.68447e-07 11.713 0.385303 11.5046 0.96099L10 5.07692H3.7037C1.65741 5.07692 0 6.69973 0 8.7033V29.3736C0 31.3772 1.65741 33 3.7037 33H36.2963C38.3426 33 40 31.3772 40 29.3736V8.7033C40 6.69973 38.3426 5.07692 36.2963 5.07692ZM36.6667 29.3736C36.6667 29.5731 36.5 29.7363 36.2963 29.7363H3.7037C3.5 29.7363 3.33333 29.5731 3.33333 29.3736V8.7033C3.33333 8.50385 3.5 8.34066 3.7037 8.34066H12.3472L13.1389 6.1739L14.1991 3.26374H25.7963L26.8565 6.1739L27.6481 8.34066H36.2963C36.5 8.34066 36.6667 8.50385 36.6667 8.7033V29.3736ZM20 11.2418C15.9074 11.2418 12.5926 14.4874 12.5926 18.4945C12.5926 22.5016 15.9074 25.7473 20 25.7473C24.0926 25.7473 27.4074 22.5016 27.4074 18.4945C27.4074 14.4874 24.0926 11.2418 20 11.2418ZM20 22.8462C17.5463 22.8462 15.5556 20.897 15.5556 18.4945C15.5556 16.092 17.5463 14.1429 20 14.1429C22.4537 14.1429 24.4444 16.092 24.4444 18.4945C24.4444 20.897 22.4537 22.8462 20 22.8462Z"
      :fill="color"
    />
  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import SvgBase from './SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
})
export default class IconCamera extends Vue {
  @Prop({ type: String }) readonly color?: string = 'white'
}
</script>
