
import { INotification } from 'smartbarcode-web-core/src/utils/types/index'
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
@Options({
  components: {},
  name: 'NotificationListItems',
})
export default class AppNotificationDialog extends Vue {
  @Prop({ type: Object }) readonly notifications?: INotification[]
  @Prop({ type: Boolean }) readonly isNavigateToBarcodeEnable?: boolean
  @Prop({ type: String }) readonly backgroundColor?: string

  get projectParam() {
    return this.$store.getters.projectParam
  }

  goToDetail(barcodeId: string) {
    this.$router.push({
      name: 'detail',
      params: {
        project: this.projectParam,
        barcodeId: barcodeId,
      },
    })
  }
}
