import projectStore from '@/store/project'
import {
  getBarcode,
  getBarcodeByPath,
  linkingBarcodes,
  pairBarcode,
  tracking,
  undoTracking,
  unpairBarcode,
} from '@/utils/api'
import { getCompiledBarcode } from '@/utils/barcodeMaskHelper'
import { DEFAULT_ACTIVATION_DATA } from '@/utils/constants'
import { ELinkType } from '@/utils/enums'
import isEmpty from 'lodash/isEmpty'
import {
  IActivationData,
  IBarcode,
  IBarcodeSavedImageStatus,
  IBarcodeSearchForm,
  IProject,
  ITrackingRequestBody,
} from 'smartbarcode-web-core/src/utils/types/index'
import {
  ADD_TRACKING,
  BARCODE_ACTION_COMPLETE,
  BARCODE_SELECTED_TYPE,
  BE_LINKED,
  CLEAR_BARCODE,
  CLEAR_ERROR,
  CLEAR_NOTIFICATION,
  CLEAR_SCANNED_UPLOAD_STATUS,
  ERROR_OCCUR,
  FETCH_BARCODE,
  FETCH_NOTIFICATION,
  FETCH_PROJECT_BY_ID,
  LINK_TO,
  PAIRING,
  RESET_SCANNED_BARCODE_BLOB,
  SAVE_BARCODE,
  SAVE_BARCODE_SEARCH_PARAMS,
  SAVE_SCANNED_BARCODES,
  SET_BARCODE_FORM_STEP,
  UNDO_TRACKING,
  UNPAIR,
  UNPAIRING,
  UPDATE_ACTIVATION_DATA_BARCODE,
  UPDATE_SCANNED_RESULT,
  UPDATE_SCANNED_UPLOAD_STATUS,
} from './actions'

const enum EActionCompleted {
  ACTIVATED = 'activated',
  TRACKED = 'tracked',
  PAIRED = 'paired',
  UNPAIRED = 'unpaired',
  UNDO_TRACKING = 'undo_tracking',
  LINKED = 'linked',
  EMPTY = '',
}

interface IDefaultActionParams {
  commit: Function
  dispatch: Function
  state: TBarcodeState
}
interface TBarcodeState {
  barcode?: IBarcode | null
  error: boolean
  errorMsg: string | number
  actionCompleted: EActionCompleted
  currentSelectedBarcodeType?: string
  trackedStep: number
  scannedBCPool: IBarcode[]
  scannedBarcodeBlob: Blob | null
  scannedBarcodeStatus?: Record<string, IBarcodeSavedImageStatus>
  barcodeSearchParams: {
    condition?: IBarcodeSearchForm
  }
}

export default {
  state: {
    barcode: null,
    error: false,
    errorMsg: '',
    actionCompleted: '',
    currentSelectedBarcodeType: '',
    trackedStep: 0,
    scannedBCPool: [],
    scannedBarcodeBlob: null,
    scannedBarcodeStatus: false,
    barcodeSearchParams: {},
  },
  getters: {
    scannedBarcodeStatus: (state: TBarcodeState) => {
      return state.scannedBarcodeStatus
    },
    scannedBarcodeBlob: (state: TBarcodeState) => {
      return state.scannedBarcodeBlob
    },
    barcodeExist: (state: TBarcodeState) => {
      return state.barcode !== null
    },
    barcodeType: (state: TBarcodeState) => {
      return state.barcode?.barcodeType
    },
    barcodeActivated: (state: TBarcodeState) => {
      return !!state.barcode?.isActivated
    },
    trackingNumber: (state: TBarcodeState) => {
      return state.barcode?.activationData?.trackingNumber
    },
    externalId: (state: TBarcodeState) => {
      return state.barcode?.activationData?.externalId
    },
    dimension: (state: TBarcodeState) => {
      return state.barcode?.activationData?.dimension
    },
    origin: (state: TBarcodeState) => {
      return state.barcode?.activationData?.origin
    },
    destination: (state: TBarcodeState) => {
      return state.barcode?.activationData?.destination
    },
    searchCondition: (state: TBarcodeState) => {
      return state.barcodeSearchParams
    },
    currentTrackingPoint: (state: TBarcodeState) => {
      return state.barcode?.currentTrackPointKey
    },
  },
  mutations: {
    [SAVE_BARCODE]: (state: TBarcodeState, payload?: IBarcode) => {
      if (payload) {
        payload.activationData = { ...(DEFAULT_ACTIVATION_DATA as IActivationData), ...payload.activationData }
        state.barcode = { ...payload }
        state.error = false
      } else {
        state.barcode = null
        state.error = true
      }
    },
    [UPDATE_ACTIVATION_DATA_BARCODE]: (state: TBarcodeState, payload?: IActivationData) => {
      if (payload && state.barcode) {
        state.barcode.activationData = { ...state.barcode?.activationData, ...payload }
      }
    },
    [UPDATE_SCANNED_RESULT]: (state: TBarcodeState, payload?: Blob) => {
      if (payload && state) {
        state.scannedBarcodeBlob = payload
      }
    },
    [RESET_SCANNED_BARCODE_BLOB]: (state: TBarcodeState) => (state.scannedBarcodeBlob = null),
    [SET_BARCODE_FORM_STEP]: (state: TBarcodeState, step: number) => {
      state.trackedStep = step
    },
    [CLEAR_BARCODE]: (state: TBarcodeState) => {
      state.barcode = null
      state.scannedBarcodeBlob = null
    },
    [BARCODE_ACTION_COMPLETE]: (state: TBarcodeState, payload: EActionCompleted = EActionCompleted.EMPTY) => {
      state.actionCompleted = payload
    },
    [BARCODE_SELECTED_TYPE]: (state: TBarcodeState, payload: string) => {
      state.currentSelectedBarcodeType = payload
    },
    [UPDATE_SCANNED_UPLOAD_STATUS]: (state: TBarcodeState, status: Record<string, IBarcodeSavedImageStatus>) => {
      state.scannedBarcodeStatus = { ...state.scannedBarcodeStatus, ...status }
    },
    [CLEAR_SCANNED_UPLOAD_STATUS]: (state: TBarcodeState) => {
      state.scannedBarcodeStatus = {}
    },
    [SAVE_SCANNED_BARCODES]: (state: TBarcodeState, barcodes: IBarcode[]) => {
      state.scannedBCPool = barcodes
    },
    [SAVE_BARCODE_SEARCH_PARAMS]: (state: TBarcodeState, searchParams: { condition: IBarcodeSearchForm }) => {
      state.barcodeSearchParams = { ...searchParams }
    },
    [ERROR_OCCUR]: (state: TBarcodeState, errorMsg: string | number) => {
      state.error = true
      state.errorMsg = errorMsg
    },
    [CLEAR_ERROR]: (state: TBarcodeState) => {
      state.error = false
      state.errorMsg = ''
    },
  },
  actions: {
    [CLEAR_BARCODE]: ({ commit }: { commit: Function }) => {
      commit(CLEAR_BARCODE)
      commit(CLEAR_NOTIFICATION)
    },
    [FETCH_BARCODE]: async (
      {
        commit,
        dispatch,
      }: {
        commit: Function
        dispatch: Function
      },
      data: {
        id: string
        path?: string
        externalId?: string
        trackingNumber?: string
      }
    ) => {
      try {
        commit(CLEAR_NOTIFICATION)
        let result: IBarcode

        if (data.path) {
          result = await getBarcodeByPath(data.id, data.path)
        } else {
          result = await getBarcode(data.id, data.externalId, data.trackingNumber)
        }
        const project = projectStore.state.details as IProject

        // Check current project and the barcode ID. If not matched refetch the project.
        if (result.projectId !== project.id) {
          await dispatch(FETCH_PROJECT_BY_ID, result?.projectId)
          const project = projectStore.state.details as IProject
          if (isEmpty(project)) {
            throw Error('Project setting not found')
          }
        }
        getCompiledBarcode(result)
        commit(SAVE_BARCODE, result)
        dispatch(FETCH_NOTIFICATION, {
          barcodeId: data.id,
        })
      } catch (error) {
        // if no, show no found
        commit(SAVE_BARCODE)
      }
    },
    [UNPAIR]: async ({ commit, dispatch, state }: IDefaultActionParams) => {
      if (state.barcode?.id) {
        const result = await unpairBarcode({
          parentBarcodeId: state.barcode?.id,
          isUnpairAll: true,
          unpairChildrenIds: [],
        })
        commit(SAVE_BARCODE, result)
        dispatch(BARCODE_ACTION_COMPLETE, 'unpaired')
      }
    },

    [BARCODE_ACTION_COMPLETE]: (
      {
        commit,
      }: {
        commit: Function
      },
      action = ''
    ) => {
      commit(BARCODE_ACTION_COMPLETE, action)
    },

    [UNDO_TRACKING]: async (
      {
        commit,
        dispatch,
      }: {
        commit: Function
        dispatch: Function
        state: TBarcodeState
      },
      payload: {
        barcodeId: string
      }
    ) => {
      await undoTracking(payload.barcodeId)
      const barcode = await getBarcode(payload.barcodeId)
      commit(SAVE_BARCODE, barcode)
      dispatch(BARCODE_ACTION_COMPLETE, EActionCompleted.UNDO_TRACKING)
    },

    [SAVE_SCANNED_BARCODES]: async (
      {
        commit,
      }: {
        commit: Function
      },
      barcodes: IBarcode[]
    ) => {
      commit(SAVE_SCANNED_BARCODES, barcodes)
    },

    [ADD_TRACKING]: async (
      {
        commit,
        dispatch,
        state,
      }: {
        commit: Function
        dispatch: Function
        state: TBarcodeState
      },
      extParams: Record<string, unknown>
    ) => {
      const id = state.barcode?.id
      const barcodes = state.scannedBCPool
      if (!id || isEmpty(barcodes)) return

      await tracking({
        barcodeIds: [id, ...barcodes.map((bc) => bc.id)],
        ...extParams,
      } as ITrackingRequestBody)
        .then(() => dispatch(BARCODE_ACTION_COMPLETE, EActionCompleted.TRACKED))
        .catch((error) => commit(ERROR_OCCUR, error))
    },
    [LINK_TO]: async ({ commit, dispatch, state }: IDefaultActionParams) => {
      const id = state.barcode?.id
      const barcodes = state.scannedBCPool
      if (!id || isEmpty(barcodes)) return

      const data = {
        barcodeId: id,
        targetBarcodeIds: barcodes.filter((bc) => bc.id !== id).map((bc) => bc.id),
      }

      await linkingBarcodes(ELinkType.LINK_TO, data)
        .then(() => dispatch(BARCODE_ACTION_COMPLETE, EActionCompleted.LINKED))
        .catch((error) => commit(ERROR_OCCUR, error))
    },
    [BE_LINKED]: async ({ commit, dispatch, state }: IDefaultActionParams) => {
      const id = state.barcode?.id
      const barcodes = state.scannedBCPool
      if (!id || isEmpty(barcodes)) return

      const data = {
        barcodeId: id,
        targetBarcodeIds: barcodes.filter((bc) => bc.id !== id).map((bc) => bc.id),
      }

      await linkingBarcodes(ELinkType.BE_LINKED, data)
        .then(() => dispatch(BARCODE_ACTION_COMPLETE, EActionCompleted.LINKED))
        .catch((error) => commit(ERROR_OCCUR, error))
    },
    [PAIRING]: async (
      {
        commit,
        dispatch,
        state,
      }: {
        commit: Function
        dispatch: Function
        state: TBarcodeState
      },
      extParams: Record<string, unknown>
    ) => {
      const id = state.barcode?.id
      const barcodes = state.scannedBCPool
      if (!id || isEmpty(barcodes)) return

      const data = {
        parentBarcodeId: id as string,
        childrenBarcodeIds: barcodes.map((bc) => bc.id),
        ...extParams,
      }
      await pairBarcode(data)
        .then(() => dispatch(BARCODE_ACTION_COMPLETE, EActionCompleted.PAIRED))
        .catch((error) => commit(ERROR_OCCUR, error))
    },
    [UNPAIRING]: async (
      {
        commit,
        dispatch,
        state,
      }: {
        commit: Function
        dispatch: Function
        state: TBarcodeState
      },
      extParams: Record<string, unknown>
    ) => {
      const id = state.barcode?.id
      const barcodes = state.scannedBCPool
      if (!id || isEmpty(barcodes)) return

      const data = {
        parentBarcodeId: id as string,
        isUnpairAll: false,

        unpairChildrenIds: barcodes.map((bc) => bc.id),
        isDryRun: false,
        ...extParams,
      }

      await unpairBarcode(data)
        .then(() => dispatch(BARCODE_ACTION_COMPLETE, EActionCompleted.UNPAIRED))
        .catch((error) => commit(ERROR_OCCUR, error))
    },
  },
}
