
import { Vue, Options } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
    // Rotation number in degrees
    rotation: {
      type: Number,
      default: 0,
    },
  },
})
export default class IconArrow extends Vue {
  get transform() {
    return `transform: rotate(${this.rotation}deg)`
  }
}
