<template>
  <div class="display-in-center landing-view-layout view" v-loading.fullscreen="loading" id="Register_Loading">
    <img alt="SmartBarcode logo" :src="image" class="app-logo" />

    <CompanyName id="Home_CompanyName" />

    <div id="Register_Form" class="register-form">
      <div id="Register_FieldInput" class="client-name field-input" :class="{ error: !!nameError }">
        <div id="Register_FirstName" class="first-name-input">
          <label>{{ $t('account.first_name') }}</label>
          <el-input id="Register_FirstNameInput" v-model="firstName" type="text" />
        </div>
        <div id="Register_LastName" class="last-name-input">
          <label>{{ $t('account.last_name') }}</label>
          <el-input id="Register_LastNameInput" v-model="lastName" type="text" />
        </div>
      </div>
      <p id="Register_NameError" v-if="nameError" class="error-msg">
        {{ nameError }}
      </p>

      <div v-if="isRequirePassword" class="client-password field-input" :class="{ error: !!passwordError }">
        <div id="Register_Password" class="password-input">
          <label> {{ $t('account.password') }}</label>
          <el-input id="Register_PasswordInput" v-model="password" type="password" />
        </div>

        <div id="Register_ConfirmPassword" class="password-input">
          <label>{{ $t('account.confirm_password') }}</label>
          <el-input id="Register_ConfirmPasswordInput" v-model="confirmPassword" type="password" />
        </div>
        <p v-if="passwordError" class="error-msg">{{ passwordError }}</p>
      </div>

      <el-button type="primary" circle class="go-button is-larger" @click="onSubmit">
        {{ $t('account.register') }}
      </el-button>
    </div>
  </div>

  <el-dialog
    width="90%"
    :lock-scroll="true"
    top="0"
    class="register-dialog"
    :model-value="registerSuccessDialog"
    :close-on-click-modal="false"
    :show-close="false"
    @closed="registerSuccessDialog = false"
  >
    <div>
      <div class="dialogMessage success-register-message">
        {{ registerSuccessMessage }}
      </div>

      <br />

      <div class="register-dialog-footer">
        <el-button id="Register_OK" type="primary" circle @click="onFinish">
          {{ $t('OK') }}
        </el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { maska } from 'maska'

import IconQRcode from '@/components/svg/IconQRcode.vue'
import IconScanQR from '@/components/svg/IconScanQR.vue'
import IconLogout from '@/components/svg/IconLogout.vue'
import CompanyName from '@/components/CompanyName.vue'
import BaseInput from '@/components/BaseInput.vue'

import { register } from '@/utils/api'
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
import { IRegisterReponse } from 'smartbarcode-web-core/src/utils/types/index'
import errorHandler from '@/utils/errorHandler'
import { FETCH_PROFILE } from '@/store/actions'

@Options({
  components: {
    IconQRcode,
    IconScanQR,
    IconLogout,
    CompanyName,
    BaseInput,
  },
  directives: { maska },
  name: 'Register',
})
export default class Register extends Vue {
  loading = false
  registerSuccessDialog = false
  registerSuccessMessage = ''
  firstName = ''
  lastName = ''
  password = ''
  confirmPassword = ''

  nameError = ''
  passwordError = ''

  get image() {
    return this.$store.getters.customLogoImageUrl || require('@/assets/logo-smartBarcode.png')
  }

  get isAuth(): boolean {
    return this.$store.state.profile.user !== null
  }

  get projectParam() {
    return this.$store.getters.projectParam
  }

  get projectDetail() {
    return this.$store.state.project.details
  }

  get isValidName(): boolean {
    if (isEmpty(this.firstName)) {
      this.nameError = this.$t('account.first_name_not_empty')
      return false
    }

    if (isEmpty(this.lastName)) {
      this.nameError = this.$t('account.last_name_not_empty')
      return false
    }

    this.clearForm()
    return true
  }

  get isValidPassword(): boolean {
    if (this.isRequirePassword) {
      if (!this.password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,32}$/g)) {
        this.passwordError = this.$t('account.password_violate_validation_rule')
        return false
      }

      if (this.password !== this.confirmPassword) {
        this.passwordError = this.$t('account.password_and_confirm_password_not_same')
        return false
      }
    }

    this.clearForm()
    return true
  }

  get isRequirePassword(): boolean {
    return this.projectDetail?.requirePassword
  }

  get isAllowUserSelfRegister(): boolean {
    return this.projectDetail?.allowUserSelfRegister
  }

  async onSubmit() {
    if (this.isValidName && this.isValidPassword) {
      this.loading = true
      await register({
        projectId: this.projectDetail?.id,
        firstName: this.firstName,
        lastName: this.lastName,
        ...(this.password && { password: this.password }),
      })
        .then((response) => {
          this.registerSuccessDialog = true
          const successResult: IRegisterReponse = { ...response.data }

          // success message
          this.registerSuccessMessage = this.$t('account.register_success_message').replace(
            '####-####',
            successResult.accessCode.toString().replace(/\B(?=(\d{4})+(?!\d))/g, '-')
          )

          // Fetch user profile
          this.$store.dispatch(FETCH_PROFILE)
        })
        .catch((err) => {
          errorHandler(err)
        })
        .finally(() => {
          this.loading = false
        })
    }
  }

  onFinish(): void {
    this.registerSuccessDialog = false
    this.$router.push({
      name: 'home',
      params: { project: this.projectParam },
    })
  }

  clearForm(): void {
    this.nameError = ''
    this.passwordError = ''
  }

  created() {
    if (!this.isAllowUserSelfRegister) {
      this.$router.push({ name: 'notFound' })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/mixins.scss';
.register-form {
  label {
    text-align: left;
    margin-bottom: 5px;
  }

  .client-name {
    display: flex;
    justify-content: space-between;
    align-items: left;

    .first-name-input {
      width: 47%;
      display: inline-block;
    }

    .last-name-input {
      width: 47%;
      display: inline-block;
    }
  }

  .client-password {
    .password-input {
      width: 100%;
      margin-top: 15px;
      display: inline-block;
    }
  }

  .go-button {
    margin: 40px auto 0;
  }

  .field-input {
    margin: 8px 0;

    &.error .el-input {
      border-color: $error;
    }

    .error-msg {
      color: $error;
      line-height: 1.2;
    }
  }
}

.el-overlay {
  .el-dialog {
    .success-register-message {
      margin: 0px 20px 40px;
    }

    .register-dialog-footer {
      text-align: center;
    }
  }
}
</style>
