
import { Vue, Options } from 'vue-class-component'
import { maska } from 'maska'

import IconQRcode from '@/components/svg/IconQRcode.vue'
import IconScanQR from '@/components/svg/IconScanQR.vue'
import IconLogout from '@/components/svg/IconLogout.vue'
import CompanyName from '@/components/CompanyName.vue'
import BaseInput from '@/components/BaseInput.vue'

import { register } from '@/utils/api'
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
import { IRegisterReponse } from 'smartbarcode-web-core/src/utils/types/index'
import errorHandler from '@/utils/errorHandler'
import { FETCH_PROFILE } from '@/store/actions'

@Options({
  components: {
    IconQRcode,
    IconScanQR,
    IconLogout,
    CompanyName,
    BaseInput,
  },
  directives: { maska },
  name: 'Register',
})
export default class Register extends Vue {
  loading = false
  registerSuccessDialog = false
  registerSuccessMessage = ''
  firstName = ''
  lastName = ''
  password = ''
  confirmPassword = ''

  nameError = ''
  passwordError = ''

  get image() {
    return this.$store.getters.customLogoImageUrl || require('@/assets/logo-smartBarcode.png')
  }

  get isAuth(): boolean {
    return this.$store.state.profile.user !== null
  }

  get projectParam() {
    return this.$store.getters.projectParam
  }

  get projectDetail() {
    return this.$store.state.project.details
  }

  get isValidName(): boolean {
    if (isEmpty(this.firstName)) {
      this.nameError = this.$t('account.first_name_not_empty')
      return false
    }

    if (isEmpty(this.lastName)) {
      this.nameError = this.$t('account.last_name_not_empty')
      return false
    }

    this.clearForm()
    return true
  }

  get isValidPassword(): boolean {
    if (this.isRequirePassword) {
      if (!this.password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,32}$/g)) {
        this.passwordError = this.$t('account.password_violate_validation_rule')
        return false
      }

      if (this.password !== this.confirmPassword) {
        this.passwordError = this.$t('account.password_and_confirm_password_not_same')
        return false
      }
    }

    this.clearForm()
    return true
  }

  get isRequirePassword(): boolean {
    return this.projectDetail?.requirePassword
  }

  get isAllowUserSelfRegister(): boolean {
    return this.projectDetail?.allowUserSelfRegister
  }

  async onSubmit() {
    if (this.isValidName && this.isValidPassword) {
      this.loading = true
      await register({
        projectId: this.projectDetail?.id,
        firstName: this.firstName,
        lastName: this.lastName,
        ...(this.password && { password: this.password }),
      })
        .then((response) => {
          this.registerSuccessDialog = true
          const successResult: IRegisterReponse = { ...response.data }

          // success message
          this.registerSuccessMessage = this.$t('account.register_success_message').replace(
            '####-####',
            successResult.accessCode.toString().replace(/\B(?=(\d{4})+(?!\d))/g, '-')
          )

          // Fetch user profile
          this.$store.dispatch(FETCH_PROFILE)
        })
        .catch((err) => {
          errorHandler(err)
        })
        .finally(() => {
          this.loading = false
        })
    }
  }

  onFinish(): void {
    this.registerSuccessDialog = false
    this.$router.push({
      name: 'home',
      params: { project: this.projectParam },
    })
  }

  clearForm(): void {
    this.nameError = ''
    this.passwordError = ''
  }

  created() {
    if (!this.isAllowUserSelfRegister) {
      this.$router.push({ name: 'notFound' })
    }
  }
}
