<template>
  <div
    v-if="lite"
    class="barcode-icon"
    :class="{ lite: true, clickable: iconClickable }"
    :style="barcodeButtonStyles(currentBarcodeInfo)"
  >
    <component :is="barcodeIcon" :style="barcodeIconStyles(currentBarcodeInfo)" />
  </div>
  <div
    v-else
    :class="{
      'barcode-label-container': true,
      'no-margin-bottom': !marginBottom,
      'no-margin-top': !marginTop,
    }"
  >
    <div
      v-if="currentBarcodeInfo?.icon || barcodeIcon"
      :class="{ 'barcode-icon': true, clickable: iconClickable }"
      v-on="labelClickable ? { click: () => $emit('icon:click') } : {}"
      :style="barcodeButtonStyles(currentBarcodeInfo)"
    >
      <component :is="barcodeIcon" :style="barcodeIconStyles(currentBarcodeInfo)" />
    </div>

    <div v-if="labelOnly" class="flex flex-col justify-center flex-shrink-0">
      <div
        :class="{
          clickable: labelClickable,
          'small-font-size': true,
        }"
        v-on="labelClickable ? { click: () => $emit('label:click') } : {}"
      >
        {{ `ID: ${label}` }}
      </div>
    </div>
    <div v-else>
      <div
        :class="{ 'barcode-type': true, clickable: subLabelClickable }"
        v-on="subLabelClickable ? { click: () => $emit('subLabel:click') } : {}"
      >
        {{ subLabel ?? barcodeTypeLabel }}
      </div>
      <div :class="{ clickable: labelClickable }" v-on="labelClickable ? { click: () => $emit('label:click') } : {}">
        {{ label }}
      </div>
    </div>
    <div v-if="actionSlotAvailable" class="action-menu flex items-center justify-end flex-shrink-1">
      <slot name="action-menu"></slot>
    </div>
  </div>
</template>

<script lang="ts">
import BarcodeTypesMixin from '@/components/mixins/BarcodeTypesMixin.vue'
import { IBarcodeDefinitionType } from 'smartbarcode-web-core/src/utils/types'
import { mixins, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Options({
  emits: ['icon:click', 'label:click', 'subLabel:click'],
  name: 'BarcodeLabel',
})
export default class BarcodeLabel extends mixins(BarcodeTypesMixin) {
  @Prop({ type: String }) readonly label?: string
  @Prop({ type: String }) readonly subLabel?: string
  @Prop({ type: String }) readonly type!: string
  @Prop({ type: Boolean }) readonly lite!: boolean
  @Prop({ type: Boolean }) readonly labelOnly?: boolean
  @Prop({ default: false }) readonly labelClickable?: boolean
  @Prop({ default: false }) readonly subLabelClickable?: boolean
  @Prop({ default: false }) readonly iconClickable?: boolean
  @Prop({ default: true }) readonly marginBottom?: boolean
  @Prop({ default: true }) readonly marginTop?: boolean
  @Prop({ default: {} }) readonly barcodeTypeDefinition?: IBarcodeDefinitionType

  get actionSlotAvailable() {
    return !!this.$slots['action-menu']
  }

  get barcodeIcon() {
    return (
      this.barcodeIconComponents[this.barcodeTypeDefinition?.icon || ''] ||
      this.barcodeIconComponents[this.currentBarcodeInfo?.icon]
    )
  }

  get barcodeTypeLabel() {
    return this.barcodeTypeInfo?.name
  }

  get project() {
    return this.$store.state.project.details
  }

  get barcodeTypeInfo(): IBarcodeDefinitionType {
    return this.project?.barcodeTypes[this.type] || {}
  }

  get currentBarcodeInfo(): IBarcodeDefinitionType {
    return this.getBarcodeTypeInfoByType(this.type) || this.barcodeTypeDefinition || {}
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/mixins.scss';

.barcode-icon {
  width: 20px;
  height: 20px;
  border-radius: 5px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  text-align: center;
  svg {
    width: 60%;
    height: 60%;
    max-width: 25px;
    max-height: 25px;
  }
}

.small-font-size {
  font-size: 12px;
}

.action-menu {
  width: 100%;
  float: right;
  text-align: right;
}

.clickable {
  cursor: pointer;
}
</style>
