
import { Options, Vue } from 'vue-class-component'

@Options({
  name: 'CalculationPreview',
  emits: ['reviewCalculation'],
  components: {},
})
export default class CalculationPreview extends Vue {
  isShow = false

  clickBtn() {
    this.$emit('reviewCalculation')
  }
}
