<template>
  <el-dialog
    @close="onDialogClosing"
    :width="'100%'"
    :fullscreen="true"
    :destroy-on-close="true"
    :lock-scroll="true"
    :close-on-click-modal="false"
    v-model="syncedModel"
    custom-class="dialog-ocr"
  >
    <template #title>
      <div class="flex justify-center items-center">
        <div class="label-round">{{ $t('take_photo') }}</div>
      </div>
    </template>

    <div class="camera-container">
      <video id="video" ref="cameraVideo" playsinline autoplay></video>
      <img v-if="overlayImage" :src="overlayImage" class="overlay-image" style="object-fit: contain" />
      <canvas ref="cameraCanvas" style="display: none"></canvas>
    </div>

    <div class="step-button relative">
      <el-button :disabled="!isCaptured" circle type="primary" class="submit-button" @click="retakePhoto">
        <IconArrowBack />
      </el-button>
      <el-button v-if="!isCaptured" circle type="primary" class="submit-button" @click="onCaptureClicked">
        <IconCamera class="w-8 h-8" />
      </el-button>
      <el-button :disabled="!imageCaptured" v-else circle type="primary" class="submit-button" @click="confirm">
        {{ $t('OK') }}
      </el-button>
    </div>
  </el-dialog>
</template>

<script lang="ts">
import IconArrowBack from '@/components/svg/IconArrowBack.vue'
import IconCamera from '@/components/svg/IconCamera.vue'
import { mobileCheck } from '@/utils/helpers'
import { Options, Vue } from 'vue-class-component'
import { Prop, PropSync, Watch } from 'vue-property-decorator'

@Options({
  name: 'ActionDialog',
  components: {
    IconCamera,
    IconArrowBack,
  },
  emits: ['update:isShowActionDialog', 'update:captureImage'],
})
export default class CustomTakePhotoCamera extends Vue {
  @PropSync('isShowActionDialog', { type: Boolean }) syncedModel?: boolean
  @Prop({ type: String }) readonly overlayImage?: string
  isCaptured = false
  imageCaptured: null | Blob = null
  stream: undefined | MediaStream = undefined
  video: HTMLVideoElement | null = null

  @Watch('syncedModel')
  onDialogChanged() {
    if (!this.syncedModel) return
    this.$nextTick(() => this.initCamera())
  }

  async initCamera() {
    try {
      if (!this.video) {
        this.video = document.getElementById('video') as HTMLVideoElement
      }
      this.stream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: 'environment', aspectRatio: mobileCheck() ? 16 / 9 : 9 / 16 },
      })

      this.$refs.cameraVideo.srcObject = this.stream
    } catch (error) {
      console.error('Error accessing media devices.', error)
    }
  }

  stopBothVideoAndAudio(stream: MediaStream) {
    stream.getTracks().forEach((track) => {
      if (track.readyState === 'live') {
        track.enabled = false
        track.stop()
      }
    })
  }

  vidOff() {
    if (this.video) {
      if (this.video.srcObject) {
        this.stopBothVideoAndAudio(this.video.srcObject as MediaStream)
      }
    }
  }

  beforeUnmount() {
    this.vidOff()
  }

  retakePhoto() {
    this.imageCaptured = null
    this.isCaptured = false
    this.$refs.cameraVideo.play()
  }

  onCaptureClicked() {
    this.isCaptured = true
    const video = this.$refs.cameraVideo
    const canvas = this.$refs.cameraCanvas

    canvas.width = video.videoWidth
    canvas.height = video.videoHeight

    const context = canvas.getContext('2d')
    context.drawImage(video, 0, 0, canvas.width, canvas.height)

    // Do something with the captured image, e.g. save to server or display on page
    canvas.toBlob((blob: Blob) => (this.imageCaptured = blob), 'image/png')
    this.$refs.cameraVideo.pause()
  }

  confirm() {
    this.$emit('update:captureImage', this.imageCaptured)
    this.syncedModel = false
  }

  onDialogClosing() {
    this.$refs.cameraVideo.srcObject = null
    if (this.stream) this.stream.getTracks()[0].stop()
    this.syncedModel = false
  }
}
</script>
<style scoped>
.step-button {
  display: flex;
  padding-left: 25%;
  padding-right: 25%;
}

.camera-container {
  position: relative;
  width: 100%;
  height: 570px;
  /* padding-bottom: 75%; */
}

.camera-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 570px;
  object-fit: cover;
  z-index: 0;
}
.camera-container img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 1;
}
</style>
