<template>
  <SvgBase iconName="QRcode" width="34" height="34">
    <path d="M14.0104 2H2V14.0104H14.0104V2Z" :stroke="color" stroke-width="3.42" />
    <path d="M14.0104 19.9893H2V31.9997H14.0104V19.9893Z" :stroke="color" stroke-width="3.42" />
    <path d="M32.0004 2H19.99V14.0104H32.0004V2Z" :stroke="color" stroke-width="3.42" />
    <path d="M9.70523 6.2876H6.28833V9.7045H9.70523V6.2876Z" :fill="color" />
    <path d="M9.70523 24.2949H6.28833V27.7118H9.70523V24.2949Z" :fill="color" />
    <path d="M27.7123 6.2876H24.2954V9.7045H27.7123V6.2876Z" :fill="color" />
    <path d="M24.2956 20.8784H20.8787V24.2953H24.2956V20.8784Z" :fill="color" />
    <path d="M27.7126 24.2949H24.2957V27.7118H27.7126V24.2949Z" :fill="color" />
    <path d="M31.1295 20.8779H27.7126V24.2948H31.1295V20.8779Z" :fill="color" />
    <path d="M24.2956 27.7119H20.8787V31.1288H24.2956V27.7119Z" :fill="color" />
    <path d="M31.1295 27.7119H27.7126V31.1288H31.1295V27.7119Z" :fill="color" />
  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconQRcode extends Vue {}
</script>
