<template>
  <SvgBase iconName="Link" width="24" height="24" fill="none">
    <path
      d="M12.53 6.19H9.11L13.03 3.16L10.58 0L0 8.19L10.59 16.39L13.04 13.23L9.12 10.2H12.54C15.42 10.2 17.75 12.54 17.75 15.41C17.75 18.28 15.41 20.62 12.54 20.62H6.28V24.62H12.53C17.61 24.62 21.74 20.49 21.74 15.41C21.74 10.32 17.61 6.19 12.53 6.19Z"
      fill="white"
    />
  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconArrowBack extends Vue {}
</script>
